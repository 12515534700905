import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import DropDown from '../../components/dropDown';
import DataGridComponent from '../../components/datagrid';
import {
  gcsFilesDataGrid,
  retailerFilesDataGrid,
} from '../../utils/fileSystemConfigs';
import NavigationBar from '../../components/navigationBar';
import { filesMenuData } from '../../utils/menuData';
import { LanguageContext } from '../../context/languageContext';

const Files = () => {
  const location = useLocation(); // Get the current location
  const navigate = useNavigate(); // Get the navigate function from the react-router-dom 
  const queryParams = new URLSearchParams(location.search); // Get the query parameters from the URL
  const { translations, language, changeLanguage } = useContext(LanguageContext); // Get the translations and language from the context

  const [selectedCountry, setSelectedCountry] = useState(queryParams.get('country') || 'United States'); // Set the selected country from the query parameter or default to United States
  const [selectedTeam, setSelectedTeam] = useState(queryParams.get('team') || ''); // Set the selected team from the query parameter
  const [selectedFiles, setSelectedFiles] = useState(queryParams.get('files') || ''); // Set the selected files from the query parameter
  const [showFiles, setShowFiles] = useState(false); // State to show the files
  const [isMenuVisible, setIsMenuVisible] = useState(false); // State to show the menu

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const teams = filesMenuData(translations).find((data) => data.country === selectedCountry)?.teams || []; // Get the teams based on the selected country
  const files = teams.find((team) => team.name === selectedTeam)?.files || [];  // Get the files based on the selected team


  // Update the URL query parameters when the selected country, team, or files change   
  useEffect(() => {
    const params = new URLSearchParams(); // Create a new URLSearchParams object
    if (selectedCountry) params.set('country', selectedCountry); // Set the country query parameter
    if (selectedTeam) params.set('team', selectedTeam); // Set the team query parameter
    if (selectedFiles) params.set('files', selectedFiles); // Set the files query parameter
    navigate({ search: params.toString() }); // Update the URL with the new query parameters
  }, [selectedCountry, selectedTeam, selectedFiles, navigate]); // Run the effect when the selected country, team, or files change

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedTeam('');
    setSelectedFiles('');
    setShowFiles(false);
  };

  const handleTeamChange = (event) => {
    setSelectedTeam(event.target.value);
    setSelectedFiles('');
    setShowFiles(false);
  };

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.value);
  };

  const handleShowFilesClick = () => {
    if (selectedFiles) setShowFiles(true);
  };

  const fileSystemConfigs = {
    RetailerFilesDataGrid: retailerFilesDataGrid(translations),
    GCSFilesDataGrid: gcsFilesDataGrid(translations),
  };

  const renderFiles = () => {
    if (!showFiles) return null;

    const fileSystemConfig = files.find((file) => file.componentKey === selectedFiles);

    if (fileSystemConfig?.componentKey && fileSystemConfigs[fileSystemConfig.componentKey]) {
      return (
        <DataGridComponent
          apiEndpoint={fileSystemConfigs[fileSystemConfig.componentKey].apiEndpoint}
          columns={fileSystemConfigs[fileSystemConfig.componentKey].columns}
          uploadButton={true}
        />
      );
    } else {
      return <Typography>{translations.selectFileSystemToBegin || 'Select a File System to begin'}</Typography>;
    }
  };

  return (
    <>
      <NavigationBar />
      <Box sx={{ flexGrow: 1, margin: '20px' }}>
        <Grid container spacing={2}>
          {isMobile && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => setIsMenuVisible(!isMenuVisible)}
                sx={{
                  mb: 2,
                  flex: 1,
                  backgroundColor: isMenuVisible ? '#0a417a' : '#0c51a1',
                  '&:hover': {
                    backgroundColor: isMenuVisible ? '#08305a' : '#0b4690',
                  },
                }}
              >
                {isMenuVisible ? translations.hideFilters || 'Hide Filters' : translations.selectFilters || 'Select Filters'}
              </Button>
            </Grid>
          )}
          {(!isMobile || isMenuVisible) && (
            <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: 2,
                  padding: '20px',
                  height: '100%',
                  width: { xs: 'auto', md: '90vw' },
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <DropDown
                    items={filesMenuData(translations).map((data) => ({
                      value: data.country,
                      label: data.country,
                    }))}
                    selectedValue={selectedCountry}
                    onChange={handleCountryChange}
                    placeholder={translations.selectCountry || 'Select Country'}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <DropDown
                    items={teams.map((team) => ({
                      value: team.name,
                      label: team.name,
                    }))}
                    selectedValue={selectedTeam}
                    onChange={handleTeamChange}
                    placeholder={translations.selectTeam || 'Select Team'}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <DropDown
                    items={files.map((file) => ({
                      value: file.componentKey,
                      label: file.name,
                    }))}
                    selectedValue={selectedFiles}
                    onChange={handleFileChange}
                    placeholder={translations.selectFiles || 'Select Files'}
                  />
                  <Button
                    variant="contained"
                    onClick={handleShowFilesClick}
                    sx={{ mt: 2 }}
                    disabled={!selectedFiles}
                  >
                    {translations.loadFiles || 'Load Files'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          order={{ xs: 2, md: 1 }}
          sx={{ mt: { xs: '10%', md: 'auto' } }}
        >
          {renderFiles()}
        </Grid>
      </Box>
    </>
  );
};

export default Files;