import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';

const PreviewModal = ({ open, handleClose, previewFile }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>File Preview</DialogTitle>
            <DialogContent>
                {previewFile ? (
                    <iframe
                        src={previewFile}
                        style={{ width: '100%', height: '600px', border: 'none' }}
                        title="File Preview"
                    />
                ) : (
                    <p>No file to preview</p>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PreviewModal;
