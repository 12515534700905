import { IconButton, DialogTitle } from "@mui/material";
import { CloseIcon } from "../../assets/icons/icons";

export default function AuthModalHeader({ setIsDialogOpen, title }) {
  return (
    <>
      <IconButton
        onClick={() => {
          setIsDialogOpen(false);
        }}
        sx={{
          position: "absolute",
          right: 6,
          top: 6,
        }}
      >
        <CloseIcon fontSize='large' />
      </IconButton>
      <DialogTitle textAlign={"center"}>{title}</DialogTitle>
    </>
  );
}
