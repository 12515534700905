import React, { useState, useEffect } from 'react';
import { LanguageContext } from '../context/languageContext';

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Default to English
  const [translations, setTranslations] = useState({}); // State to store translations

  // Function to load translations based on the selected language or fallback to English
  const loadTranslations = (lang) => {
    import(`../locales/${lang}.json`) // Dynamically import translation file based on the selected language
      .then((module) => { // Set the language and translations
        setLanguage(lang); // Set the language
        setTranslations(module.default); // Set the translations
      })
      .catch(() => {
        // Fallback to English if translation file for selected language is not found
        import('../locales/en.json') // Import English translation file
          .then((module) => { // Set the language and translations
            setLanguage('en'); // Set the language to English
            setTranslations(module.default); // Set the translations
          });
      });
  };

  // Load translations based on the stored language or browser language on component mount
  useEffect(() => {
    // Get the stored language from local storage or fallback to the browser language
    const storedLang = localStorage.getItem('engage_app_lang');
    // Load translations based on the stored language or browser language on component mount
    if (storedLang) {
      loadTranslations(storedLang); // Load translations based on the stored language
    } else {
      const browserLang = navigator.language.split('-')[0]; // Get the browser language 
      loadTranslations(browserLang); // Load translations based on the browser language
    }
  }, []); // Run only on component mount

  // Function to change the language and persist it in local storage 
  const changeLanguage = (lang) => {
    // Persist the selected language in local storage and load translations
    localStorage.setItem('engage_app_lang', lang);
    loadTranslations(lang); // Load translations based on the selected language
  };

  return (

    // Provide the language context value to the children components
    <LanguageContext.Provider value={{ translations, language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
