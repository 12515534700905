import React from 'react';
import { MenuItem, Typography, RadioGroup, FormControlLabel, Radio, SvgIcon } from '@mui/material';
import { Brightness4Icon, Brightness7Icon, SettingsBrightnessIcon } from '../assets/icons/icons';

const ThemeSetting = ({ themeMode, handleThemeChange }) => (
  <MenuItem>
    <SvgIcon component={Brightness4Icon} sx={{ marginRight: 1, color: '#053469' }} />
    <Typography variant="body1" sx={{ fontWeight: 'medium', marginRight: 2 }}>Theme:</Typography>
    <RadioGroup
      row
      value={themeMode}
      onChange={handleThemeChange}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginLeft: 4.5,
      }}
    >
      <FormControlLabel
        value="light"
        control={<Radio icon={<Brightness7Icon />} checkedIcon={<Brightness7Icon />} />}
        label={<Typography variant="subtitle2">Light</Typography>}
        sx={{ color: 'text.primary' }}
      />
      <FormControlLabel
        value="dark"
        control={<Radio icon={<Brightness4Icon />} checkedIcon={<Brightness4Icon />} />}
        label={<Typography variant="subtitle2">Dark</Typography>}
        sx={{ color: 'text.primary' }}
      />
      <FormControlLabel
        value="system"
        control={<Radio icon={<SettingsBrightnessIcon />} checkedIcon={<SettingsBrightnessIcon />} />}
        label={<Typography variant="subtitle2">System</Typography>}
        sx={{ color: 'text.primary' }}
      />
    </RadioGroup>
  </MenuItem>
);

export default ThemeSetting;
