import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography, Avatar, CircularProgress, Grid, IconButton, Tabs, Tab } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import GeneralInfo from '../../components/generalInfoTab';
import AccessGroups from '../../components/accessInfoTab';
import NavigationBar from '../../components/navigationBar';

const userCache = {}; // Cache object to store user details

const UserProfile = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userCache[userId]) {
        // Use cached data if available
        setUserDetails(userCache[userId]);
        setLoading(false);
      } else {
        try {
          const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/${userId}`, {
            withCredentials: true, // Ensure cookies are sent with the request
          });
          userCache[userId] = response.data; // Update cache
          setUserDetails(response.data);
        } catch (error) {
          console.error('Error fetching user details:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserDetails();
  }, [userId]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabs = [
    { label: 'General Information', component: <GeneralInfo user={userDetails} /> },
    // { label: 'Groups Access', component: <AccessGroups userId={userId} /> },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <NavigationBar />
      <Box sx={{ flex: 1, p: 3, position: 'relative' }}>
        <IconButton
          onClick={() => navigate('/admin/users/management')}
          sx={{ 
            position: 'absolute', 
            top: 88, 
            right: 8, 
            color: 'red' 
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : userDetails ? (
          <>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Avatar src={userDetails.profilePicture} alt={userDetails.firstName} sx={{ width: 150, height: 150 }} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="h4" gutterBottom>{`${userDetails.firstName} ${userDetails.lastName}`}</Typography>
                <Typography variant="body1" gutterBottom>{userDetails.email}</Typography>
                <Typography variant="body1" gutterBottom>{userDetails.jobTitle}</Typography>
                <Typography variant="body1" gutterBottom>{userDetails.department}</Typography>
              </Grid>
            </Grid>
            <Tabs value={activeTab} onChange={handleChange} aria-label="user details tabs" sx={{ mt: 2 }}>
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} />
              ))}
            </Tabs>
            <Box sx={{ mt: 3 }}>
              {tabs[activeTab].component}
            </Box>
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography variant="h6">User not found</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserProfile;
