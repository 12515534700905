import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

// Dynamic sidebar component for the admin section
const AdminSidebar = ({ links = [], toolbarHeight = 111 }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 240,
          boxSizing: "border-box",
          marginTop: `${toolbarHeight}px`, // Adjust margin based on toolbar height
          border: "none",
        },
      }}
    >
      <List>
        {/* Map through the links prop to generate navigation items */}
        {links.map((link, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              color: location.pathname === link.path ? "#0c51a1" : "inherit",
            }}
          >
            <ListItemButton onClick={() => navigate(link.path)}>
              <ListItemText primary={link.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default AdminSidebar;
