import { IconButton } from '@mui/material';
import { LaunchIcon } from '../assets/icons/icons';
import { useNavigate } from 'react-router-dom';

export const getUserColumns = (
  userLoginStatus,
  userLoadingStatus,
  navigate
) => [
  { field: 'firstName', headerName: 'First Name', width: 150 },
  { field: 'lastName', headerName: 'Last Name', width: 150 },
  { field: 'email', headerName: 'Email', width: 250 },
  { field: 'lastLoginDate', headerName: 'Last Login', width: 180 },
  { field: 'userType', headerName: 'Person Type', width: 150 },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => {
      const loginStatus = userLoginStatus[params.row.userId];
      let displayStatus;

      if (loginStatus === 'Logged In') {
        displayStatus = 'Logged In';
      } else if (loginStatus === 'Logged Out') {
        displayStatus = 'Logged Out';
      } else {
        displayStatus = <span style={{ color: 'red' }}>CDS</span>;
      }

      return (
        <span>
          {params.value === 'active' ? (
            <span style={{ color: 'green' }}>
              Active (
              <span
                style={{
                  color:
                    loginStatus === 'Logged In'
                      ? 'inherit'
                      : loginStatus === 'Logged Out'
                      ? 'inherit'
                      : 'red',
                }}
              >
                {displayStatus}
              </span>
              )
            </span>
          ) : (
            <span>
              Inactive (
              <span
                style={{
                  color:
                    loginStatus === 'Logged In'
                      ? 'inherit'
                      : loginStatus === 'Logged Out'
                      ? 'inherit'
                      : 'red',
                }}
              >
                {displayStatus}
              </span>
              )
            </span>
          )}
        </span>
      );
    },
  },
  {
    field: 'Info',
    headerName: 'Info',
    width: 150,
    renderCell: (params) => {
      const isLoading = userLoadingStatus[params.row.userId];
      return (
        <IconButton
          variant='outlined'
          onClick={() => navigate(`/admin/users/${params.row.userId}`)}
          disabled={isLoading}
        >
          <LaunchIcon sx={{ color: isLoading ? 'gray' : '#053469' }} />
        </IconButton>
      );
    },
  },
];

export const getUserInvitesColumns = () => [
  { field: 'firstName', headerName: 'First Name', width: 150 },
  { field: 'lastName', headerName: 'Last Name', width: 150 },
  { field: 'email', headerName: 'Email', width: 250 },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => {
      const inviteStatus = params.value;
      let displayStatus;

      if (inviteStatus === 'disabled') {
        displayStatus = 'Disabled';
      } else if (inviteStatus === 'claimed') {
        displayStatus = 'Claimed';
      } else if (inviteStatus === 'unclaimed') {
        displayStatus = 'Unclaimed';
      } else {
        displayStatus = <span style={{ color: 'red' }}>CDS</span>;
      }

      return (
        <span>
          {params.value === 'unclaimed' ? (
            <span style={{ color: '#ffa600' }}>{displayStatus}</span>
          ) : params.value === 'claimed' ? (
            <span style={{ color: 'green' }}>{displayStatus}</span>
          ) : (
            <span style={{ color: 'red' }}>{displayStatus}</span>
          )}
        </span>
      );
    },
  },
  {
    field: 'inviteDate',
    headerName: 'Date Invited',
    width: 180,
    type: 'dateTime',
    valueGetter: (value) => value.value && new Date(value.value),
  },
  {
    field: 'usedDate',
    headerName: 'Date Accepted',
    width: 180,
    type: 'dateTime',
    valueGetter: (value) => value.value && new Date(value.value),
  },
];

export const userInvitesEndpoint = `${process.env.REACT_APP_ENDPOINT_USERS}/all-invites`;
export const getInviteEndpoint = `${process.env.REACT_APP_ENDPOINT_USERS}/get-invite`;
export const disableInviteEndpoint = `${process.env.REACT_APP_ENDPOINT_USERS}/disable-invite`;
export const enableInviteEndpoint = `${process.env.REACT_APP_ENDPOINT_USERS}/enable-invite`;
export const resendInviteEndpoint = `${process.env.REACT_APP_ENDPOINT_USERS}/resend-invite-email`;
export const createInviteEndpoint = `${process.env.REACT_APP_ENDPOINT_USERS}/create-invite`;
export const usersApiEndpoint = `${process.env.REACT_APP_ENDPOINT_USERS}/all-users`;
export const userStateApiEndpoint = `${process.env.REACT_APP_ENDPOINT_USERS}/user-state`;

export const Jurisdictions = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];
