import React, {useState, useContext} from "react";
import NavigationBar from "../../components/navigationBar";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Link,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {SearchIcon} from "../../assets/icons/icons";
import { adminCategories } from "../../constants/constants";
import { LanguageContext } from '../../context/languageContext';

export default function AdminDashboard() {
    const { translations, language, changeLanguage } = useContext(LanguageContext);

  // State Variables
  const [searchTerm, setSearchTerm] = useState("");

  // Handler for search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Filtered categories based on search term
  const filteredCategories = adminCategories.map((category) => ({
    ...category,
    subcategories: category.subcategories.filter((subcat) =>
      subcat.text.toLowerCase().includes(searchTerm)
    ),
  }));

  return (
    <>
      <NavigationBar />
      <Box
        sx={{
          backgroundColor: '#4a7ec3',
          height: '35px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
        }}
      >
        <Typography sx={{ color: '#FFFFFF', fontWeight: 'bold', ml: 2 }}>
          {translations.adminPanel || 'Admin Panel'}</Typography>
      </Box>
        <TextField
          size="small"
          label="Search"
          variant="outlined"
          sx={{
            backgroundColor: "white",
            borderRadius: "5px",
            width: "93.5%",
            mb: "20px",
            mt: "20px",
            ml: "50px",
          }}
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Grid container spacing={3}>
          {filteredCategories.map((category) => (
            <Grid item xs={12} sm={6} md={4} key={category.title}>
              <Card variant="outlined" sx={{ minHeight: 100, maxWidth: "80%", border: "none" }}>
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: "bold", borderBottom: "3px solid grey" }}>
                    {category.title}
                  </Typography>
                  {category.subcategories.length > 0 ? (
                    category.subcategories.map((subcat) => (
                      <p key={subcat.text}>
                        <Link
                          href={subcat.link}
                          variant="body2"
                          sx={{ textDecoration: "none", fontSize:"15px" }}
                        >
                          {subcat.text}
                        </Link>
                      </p>
                    ))
                  ) : (
                    <Typography variant="body2">
                      No matching subcategories
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
    </>
  );
}
// Categories and subcategories data
