import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './views/auth/page';
import Dashboard from './views/dashboard/page';
import AccessDenied from './views/access/page';
import Files from './views/files/page';
import Reports from './views/reports/page';
import Forms from './views/forms/page';
import Admin from './views/admin/page';
import UserManagement from './views/admin/usersManagement';
import UserProfile from './views/admin/userProfile';
// import PlatformAPI from './views/admin/platformAPIs';
import ContactCenterPage from './views/contactCenter/page';
import DepotPage from './views/depot/page';
import { AuthProvider } from './context/authContext';
import { ProtectedRoute } from './context/protectedRoute';
import { LicenseInfo } from '@mui/x-license';
import RSSTracking from './views/rss/page';
import NotFound from './views/notFound/page';
import LanguageProvider from './hooks/languageProvider';
import Groups from './views/admin/groupsManagement';
import UserInvitesManagement from './views/admin/userInvitesManagement';

const muiXLicenseKey = process.env.REACT_APP_MUI_X_LICENSE_KEY;
LicenseInfo.setLicenseKey(muiXLicenseKey);

function App() {
  return (
    <AuthProvider>
      <LanguageProvider>
        <Router>
          <Routes>
            {/* Standard Routes */}
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/denied' element={<AccessDenied />} />

            {/* Authenticated Routes */}
            {/* Admin Routes */}
            <Route
              path='/admin'
              element={<ProtectedRoute element={<Admin />} path='/admin' />}
            />
            <Route
              path='/admin/users/management'
              element={
                <ProtectedRoute
                  element={<UserManagement />}
                  path='/admin/users/management'
                />
              }
            />
            <Route
              path='/admin/users/invites'
              element={
                <ProtectedRoute
                  element={<UserInvitesManagement />}
                  path={'/admin/users/invites'}
                />
              }
            />
            <Route
              path='/admin/users/:userId'
              element={
                <ProtectedRoute
                  element={<UserProfile />}
                  path='/admin/users/:userId'
                />
              }
            />
            {/* <Route
              path='/admin/platform-apis'
              element={
                <ProtectedRoute
                  element={<PlatformAPI />}
                  path='/admin/platform-apis'
                />
              }
            /> */}
            <Route
              path='/admin/groups'
              element={
                <ProtectedRoute element={<Groups />} path='/admin/groups' />
              }
            />

            {/* Dashboard Route */}
            <Route
              path='/home'
              element={<ProtectedRoute element={<Dashboard />} path='/home' />}
            />

            {/* Reports Route */}
            <Route
              path='/reports'
              element={<ProtectedRoute element={<Reports />} path='/reports' />}
            />

            {/* RSS Route */}
            <Route
              path='/rss/links'
              element={
                <ProtectedRoute element={<RSSTracking />} path='/rss/links' />
              }
            />

            {/* Forms Route */}
            <Route
              path='/forms'
              element={<ProtectedRoute element={<Forms />} path='/forms' />}
            />

            {/* Files Routes */}
            <Route
              path='/files'
              element={<ProtectedRoute element={<Files />} path='/files' />}
            />
            <Route
              path='/files/:country/:organization/:team'
              element={
                <ProtectedRoute
                  element={<Files />}
                  path='/files/:country/:organization/:team'
                />
              }
            />

            {/* Contact Center Route */}
            <Route
              path='/contact-center'
              element={
                <ProtectedRoute
                  element={<ContactCenterPage />}
                  path='/contact-center'
                />
              }
            />

            {/* Depot Route */}
            <Route
              path='/depot'
              element={<ProtectedRoute element={<DepotPage />} path='/depot' />}
            />

            {/* Not Found Route */}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Router>
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;
