import React from 'react';
import { Dialog, Typography, TextField, Paper, Grid, Button, DialogActions } from '@mui/material';
import moment from 'moment/moment';
import { LanguageContext } from '../context/languageContext';

// EditableModal component for the DataGrid component with props for the modal
const EditableModal = ({ open, handleClose, rowId, selectedRowData, columns, handleEdit, setSelectedRowData, formStyles }) => {
  const translations = React.useContext(LanguageContext);

  return (
    <Dialog open={open} onClose={handleClose} sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}>
      <Typography variant='body1' mt={1} ml={3} sx={{ color: '#9B9B9B', fontSize: '14px' }}>
        ID: {rowId} | {selectedRowData?.fstNumber} | {selectedRowData?.emailAddress} | {selectedRowData?.displayName}
      </Typography>
      <Paper style={formStyles.paper}>
        <form onSubmit={handleEdit}>
          <Grid container spacing={2}>
            {columns.filter(column => column.editable).map(column => (
              <Grid item xs={12} key={column.field}>
                <Typography variant='body1' style={formStyles.label}>{column.headerName}</Typography>
                <TextField
                  fullWidth
                  value={selectedRowData?.[column.field] || ''}
                  onChange={e => setSelectedRowData(prev => ({ ...prev, [column.field]: e.target.value }))}
                  style={formStyles.input}
                />
              </Grid>
            ))}
          </Grid>
          {/* Modify Information */}
          <Typography variant='caption'>
            {translations.createdBy || 'Created By'}: {selectedRowData?.displayName}
          </Typography>
          <br />
          <Typography variant='caption'>
            {translations.lastModified || 'Last Modified'}:{' '}
            {selectedRowData?.modifiedDate
              ? moment(selectedRowData.modifiedDate).format('YYYY-MM-DD HH:mm:ss A')
              : 'N/A'}
          </Typography>
          <br />
          <Typography variant='caption'>
            {translations.modifiedDate || 'Modified Date'}: {selectedRowData?.modifiedBy ? selectedRowData.modifiedBy : 'N/A'}
          </Typography>
          <DialogActions>
            <Button variant='contained' onClick={handleClose}>{translations.cancel || 'Cancel'}</Button>
            <Button variant='contained' color='primary' type='submit'>{translations.update || 'Update'}</Button>
          </DialogActions>
        </form>
      </Paper>
    </Dialog>
  );
};

export default EditableModal;
