import Map from '../components/weatherMap';
import CommTabs from '../components/comm/commTabs';
import { LanguageContext } from '../context/languageContext';


export const utilities = (translations) => [
  {
    tab: translations.commDevices || 'Comm Devices',
    items: [
      {
        title: translations.device || 'Devices',
        description: translations.device || 'Devices',
        component: <CommTabs/>,
      },
      // {
      //   title: 'Hughes',
      //   description: 'Hughes Devices',
      //   component: <VentusDevice />,
      // },
    ],
  },
  {
    tab: translations.weatherMap || 'Weather Map',
    items: [
      {
        title: translations.weatherMap || 'Weather Map',
        description: translations.weatherMap || 'Weather Map',
        component: <Map />,
      },
    ],
  },
];
