//RRF Form Configs

export const billAcceptorNumberItems = [
  '',
  '15-2514-01',
  '15-3979-01E (PROJECT)',
  '17-0960-01E',
  '17-1222-01E',
  '24-1909-08E',
  '50-0270-02',
  '50-0295-01',
  '50-0296-01',
  '50-0296-01E',
  '50-0296-02E',
  '50-0305-01',
  '50-0305-01E',
  '50-0305-02E',
  '50-0305-04E',
  '50-0305-05E',
  '50-0313-01E',
  '50-0313-02E',
  '50-0340-01E',
  '50-0354-01E (PROJECT)',
  '50-0361-01E',
  '50-122-0062-01',
  '50-122-0062-02',
  '50-122-0068-01',
  '50-122-0070-01',
  '50-122-0070-02',
  '50-122-0071-02',
  '50-122-0092-30',
];

export const gamingFailureModes = [
  'Adjust color',
  'Bad Backlight',
  'Bad Components',
  'Bad Cut',
  'Bad Image',
  'Bad Motor',
  'Bad PCB',
  'Bad Video',
  'Battery',
  'Broken Chassis',
  'Broken Glass',
  'Clear Throat',
  'Cold Solder ',
  'Conversion',
  'Cycling',
  'Damaged parts',
  'Disk error',
  'Failed Touchscreen',
  'Incorrect Firmware',
  'Jamming',
  'Missing Components',
  'Missing Parts',
  'Motor Runs Constantly',
  'No Display',
  'No Power',
  'No Video',
  'Noise Motor',
  'Not Accepting Playslips',
  'Not inatializing',
  'Offline',
  'Reader Error',
  'Reject Bills',
  'Rejecting Ticket',
  'Scratched',
  'Shorted components',
  'Shorted PCB',
  'Stacker Damage',
  'Unloading Ticket',
  'Water damage',
  'Wont Boot',
  'Wont Calibrate',
  'Wont Dispense Ticket',
  'Wont Load Ticket',
  'Wont Show in Diagnostic',
  'Wont Take Bills',
];

export const lotteryFailureModes = [
  '',
  'Adjust color',
  'Bad Backlight',
  'Bad Cable',
  'Bad Components',
  'Bad Cut',
  'Bad Image',
  'Bad Key',
  'Bad LEDs',
  'Bad Motor',
  'Bad PCB',
  'Bad Print',
  'Bad Video',
  'Battery',
  'Bin Failure',
  'Broken Chassis',
  'Broken Glass',
  'Clear Throat',
  'Cold Solder',
  'Comm Port Failure',
  'Conversion',
  'Cycling',
  'Damaged parts',
  'Disk error',
  'Failed Touchscreen',
  'Incorrect Firmware',
  'Jamming',
  'Loose Components',
  'Missing Components',
  'Missing Parts',
  'Motor Runs Constantly',
  'No Burster',
  'No DIAGS',
  'No Display',
  'No Key Response',
  'No Power',
  'No Response',
  'No Security ID',
  'No Standalone',
  'No video',
  'Noise Motor',
  'Not Accepting Playslips',
  'Not inatializing',
  'Offline',
  'Printer Port Failure',
  'Reader Error',
  'Reject Bills',
  'Rejecting Ticket',
  'Resetting',
  'Scratched',
  'Shorted components',
  'Shorted PCB',
  'Stacker Damage',
  'TPI Failure',
  'Unloading Ticket',
  'Water damage',
  'Wont Boot',
  'Wont Calibrate',
  'Wont Dispense Ticket',
  'Wont Load Ticket',
  'Wont Scan',
  'Wont Show in Diagnostic',
  'Wont Take Bills',
];

export const gamingLocations = [
  '',
  'Customer',
  'D005',
  'GPSH',
  'GSMS',
  'I002',
  'I041',
  'I042',
  'I057',
  'I058',
  'L001',
  'L002',
  'L003',
  'L004',
  'L005',
  'L006',
  'L007',
  'L009',
  'L010',
  'L011',
  'L012',
  'L013',
  'L014',
  'L015',
  'L016',
  'L017',
  'L018',
  'L022',
  'P002',
  'P006',
  'P007',
  'S001',
  'S002',
  'S003',
  'S004',
  'S005',
  'S006',
  'S008',
  'S010',
  'S011',
  'S012',
  'S013',
  'S015',
  'S016',
  'S017',
  'S018',
  'S019',
  'S021',
  'S022',
  'S023',
  'S026',
  'S027',
  'S032',
  'S033',
  'S036',
  'S038',
  'S039',
  'S040',
  'S042',
  'S043',
  'S046',
  'S049',
  'S050',
  'S060',
  'S063',
  'S070',
  'S081',
  'T003',
  'T029',
  'T075',
  'T076',
  'T078',
  'T080',
  'T084',
  'T088',
  'T089',
  'T090',
  'V001',
  'V003',
  'V004',
  'V005',
  'V007',
  'V009',
  'V010',
  'V011',
  'V012',
  'V013',
  'V016',
  'V017',
  'V018',
  'V019',
  'V020',
  'V021',
  'V022',
  'V023',
  'V025',
  'V026',
  'V027',
  'V028',
  'V029',
  'V030',
  'V033',
  'V034',
  'V035',
  'V036',
  'V038',
  'V040',
  'V041',
  'V042',
  'V047',
  'V048',
  'V050',
  'V051',
  'V054',
  'V055',
  'V056',
  'V058',
  'V059',
  'V060',
  'V061',
  'V062',
  'V063',
  'V064',
  'V065',
  'V066',
  'V073',
  'V075',
  'V076',
  'V077',
  'V078',
  'V079',
  'V080',
  'V083',
  'V085',
  'V088',
  'V089',
  'V090',
  'V091',
  'V092',
  'V094',
  'V095',
  'V099',
  'V103',
  'V104',
  'V105',
  'V107',
  'V108',
  'V109',
  'V110',
  'V111',
  'V112',
  'V114',
  'V115',
  'V116',
  'V118',
  'V119',
  'V120',
  'V121',
  'V122',
  'V123',
  'V124',
  'V125',
  'V127',
  'V128',
  'V129',
  'V131',
  'V133',
  'V135',
  'V137',
  'V138',
  'V139',
  'V140',
  'V143',
  'V144',
  'V145',
  'V147',
  'V149',
  'V150',
  'V155',
  'V156',
  'V157',
  'V158',
  'V159',
  'V160',
  'V162',
  'V163',
  'V166',
  'V167',
  'V168',
  'V171',
  'V172',
  'V173',
  'V174',
  'V175',
  'V176',
  'V177',
  'V179',
  'V181',
  'V186',
  'V187',
  'V191',
  'V192',
  'V194',
  'V195',
  'V196',
  'V198',
  'V199',
  'V200',
  'V201',
  'V203',
  'V206',
  'V207',
  'V208',
  'V210',
  'V212',
  'V213',
  'V218',
  'V219',
  'V221',
  'V222',
  'V224',
  'V242',
  'V243',
  'V244',
  'V247',
  'V248',
  'V250',
  'V256',
  'V257',
  'V258',
  'V260',
  'V264',
  'V271',
  'V279',
  'V280',
  'V281',
  'V282',
];

export const gamingPartNumbers = [
  '',
  '19685200',
  '29101990',
  '37910700',
  '50069000',
  '50070100',
  '50079000',
  '60834900',
  '60835100',
  '62531890',
  '62547590',
  '62552600',
  '62554890',
  '62563090',
  '62577190',
  '62577290',
  '62581708',
  '62585502',
  '62587602',
  '62595490',
  '62951300',
  '62962800',
  '65519501',
  '66005002',
  '66011500',
  '66012004',
  '66050000',
  '66050101',
  '66900801',
  '69900801',
  '69950101',
  '69950102',
  '69950701',
  '69950904',
  '69972801',
  '69973915',
  '69974715',
  '69974905',
  '69975601',
  '69977100',
  '69977400',
  '70308006',
  '75120400',
  '75185100',
  '75185200',
  '75188300',
  '75190701',
  '75191000',
  '75192500',
  '75192501',
  '75192600',
  '75192700',
  '75193300',
  '75194300',
  '75194400',
  '75510502',
  '75609900',
  '75610100',
  '75692690',
  '76102100',
  '86700500',
  '91161200',
  '91427804',
  '91430229',
  '91431202',
  '91432102',
  '91701205',
  '91847103',
  '91847202',
  '91847600',
  '91847801',
  '91895706',
  '93140202',
  '93140229',
  '93140233',
  '93431202',
  '96420500',
  '96455000',
  '96459900',
  '608050100',
  '699473915',
  '699509500',
  '699950102',
  ' 2402252-02R',
  ' 50079000R',
  ' 69972407WR',
  ' 75438800WR',
  ' 75438803WR',
  ' LF15-7349-01R',
  ' LF15-7349-02R',
  '014082205WR',
  '030015801R',
  '030015803R',
  '030015804R',
  '030015804WNN',
  '030015804WR',
  '030015806NN',
  '030015806R',
  '030015806RNN',
  '030015806WR',
  '0900011600R',
  '090011200WR',
  '090011600R',
  '090011600WR',
  '090011607WR',
  '09001200WR',
  '090012200WR',
  '090012201WR',
  '090021000WN',
  '090021000WR',
  '09002100WR',
  '090037010R',
  '14930200R',
  '14930203R',
  '14940200WR',
  '14940201WNN',
  '14940201WR',
  '14940202WR',
  '1501123-02R',
  '1501995-02NN',
  '1501995-02R',
  '1502009-01NN',
  '1502009-01R',
  '15020091R',
  '1502009-1R',
  '1502097-01',
  '1502141-02R',
  '1502240-03R',
  '1502293-01R',
  '19685200R',
  '19694700R',
  '19934001NN',
  '19934001R',
  '20123100WR',
  '20124103WR',
  '20124303WR',
  '20124600WR',
  '20125100WR',
  '20162490WR',
  '2402252-02NN',
  '2402252-02R',
  '240-2252-02R',
  '2402253-02NN',
  '2402253-02R',
  '24022552-02R',
  '2402289-01R',
  '2402377-04NN',
  '2402377-04R',
  '2402403-01R',
  '2402414-05',
  '2402414-05R',
  '2402462-01NN',
  '2402462-02NN',
  '2402640-01NN',
  '2402640-01R',
  '2402640-03NN',
  '2402640-03R',
  '2402641-03NN',
  '2402641-03R',
  '2402692-01NN',
  '2402692-01R',
  '2402692-02NN',
  '2402692-02R',
  '2402692-03',
  '2402692-03NN',
  '2402692-03R',
  '2402697-01NN',
  '2402697-01R',
  '2402702-02R',
  '2402734-02NN',
  '2402734-02R',
  '2402755-02R',
  '2402877-01NN',
  '2402877-01R',
  '24029692-02R',
  '24029692-03R',
  '2403010-01R',
  '2403013-01',
  '2403013-01NN',
  '2403013-01R',
  '2403080-01',
  '25302100R',
  '25329500R',
  '25329600R',
  '26577190R',
  '26707090WR',
  '2717525-02R',
  '2718025-03',
  '2718025-03NN',
  '2718025-03R',
  '2718756-01R',
  '2723038-05',
  '2723038-05NN',
  '2723038-05R',
  '2723038-06NN',
  '2723038-06R',
  '2723380-01R',
  '2723420-03',
  '29101990R',
  '29101990WR',
  '29132590WR',
  '29133090WR',
  '30300100R',
  '30919600NN',
  '30919600R',
  '30920000ex',
  '30920000WEX',
  '30920000WR',
  '30921001WR',
  '30921002NN',
  '30921002R',
  '30921002WNN',
  '30921002WR',
  '30923000NN',
  '30923000w',
  '30923000W ',
  '30923000WNN',
  '30923000WR',
  '30925100NN',
  '30925100R',
  '30925100RNN',
  '30925101R',
  '309823000WR',
  '35101390R',
  '35105900R',
  '35106200R',
  '37910600WR',
  '37910700WNN',
  '37910700WR',
  '37920000NN',
  '37920000R',
  '38804100R',
  '38804700NN',
  '38804700R',
  '38804701R',
  '39025100R',
  '40004001WR',
  '40009003W',
  '40009003WR',
  '40010010WR',
  '40011101WR',
  '40012000WR',
  '40012003WR',
  '40012100WNN',
  '40012100WR',
  '40013000WR',
  '40014000WR',
  '40101190WR',
  '40200990W',
  '40200990WR',
  '40800500R',
  '462589002R',
  '50025501R',
  '50-0313-01E',
  '50040000R',
  '50043690R',
  '50050700R',
  '50050701NN',
  '50050701R',
  '50050900R',
  '50059200R',
  '50059201W',
  '50059401R',
  '50059402R',
  '50059601NN',
  '50059601R',
  '50061400WR',
  '50062001NN',
  '50062100NN',
  '50062100WNN',
  '50062100WR',
  '50062500NN',
  '50062500WNN',
  '50062500WR',
  '50062501NN',
  '50062602R',
  '50066000NN',
  '50066000WNN',
  '50066000WR',
  '50066001WNN',
  '50066001WR',
  '50069000NN',
  '50069000R',
  '5006900R',
  '500692100WR',
  '50069400NN',
  '50069400R',
  '50069500R',
  '50070100NN',
  '50070100R',
  '50070200NN',
  '50070200R',
  '50070500NN',
  '50070500R',
  '50070500WR',
  '50079000NN',
  '50079000R',
  '50079000WR',
  '50088400R',
  '50088401R',
  '51600003NN',
  '51600003R',
  '51600004NN',
  '51801000NN',
  '51801000R',
  '5181000R',
  '51841900NN',
  '51841900R',
  '51841903R',
  '58101000R',
  '60062500WR',
  '60257602WR',
  '608050100NN',
  '608050100R',
  '608050100WR',
  '60834600R',
  '60834800R',
  '60834900R',
  '60835100R',
  '60835199R',
  '608394900R',
  '60921002WR',
  '6251100WEX',
  '62531890NN',
  '62531890R',
  '62539290EX',
  '62539290NN',
  '62539290R',
  '62542509R',
  '62542590R',
  '62542690NN',
  '62542690R',
  '62546090R',
  '62546301NN',
  '62546301R',
  '62546401NN',
  '62546401R',
  '62546701NN',
  '62546701R',
  '62547390NN',
  '62547390WNN',
  '62547390WR',
  '62547590NN',
  '62547590R',
  '62548790NN',
  '62548890R',
  '62549490NN',
  '62549490R',
  '62552600NN',
  '62552600R',
  '62554890NN',
  '62554890R',
  '62557101WNN',
  '62557101WR',
  '62557102W',
  '62557102WNN',
  '62557102WR',
  '62557200W',
  '62557200WNN',
  '62557200WR',
  '62557890R',
  '62559090NN',
  '62559090R',
  '62559290NN',
  '62559290R',
  '62561100EX',
  '62561100NN',
  '62561100R',
  '62561100W',
  '62561100WEX',
  '62561100WR',
  '62562890R',
  '62563090NN',
  '62563090R',
  '62563190R',
  '62563390NN',
  '62563390R',
  '62567602R',
  '62569800R',
  '62572900WR',
  '62574590NN',
  '62575590R',
  '62577109R',
  '62577190NN',
  '62577190R',
  '62577290NN',
  '62577290R',
  '625817018NN',
  '62581701R',
  '62581708EXR',
  '62581708NN',
  '62581708R',
  '62581708WR',
  '62585500W',
  '62585502R',
  '62586700NN',
  '62586700R',
  '62587602NN',
  '62587602R',
  '62587602W',
  '62587602WEXR',
  '62587602WNN',
  '62587602WR',
  '62587602WRR',
  '6258760NN',
  '62589005R',
  '62589005WR',
  '62595490NN',
  '62595490R',
  '62595590R',
  '62596490R',
  '62596590R',
  '62598190R',
  '62599990NN',
  '62599990R',
  '62674601NN',
  '62674601R',
  '62947590R',
  '63196093R',
  '65042376R',
  '65045020NN',
  '65045020R',
  '65527802R',
  '65590202R',
  '66002902R',
  '66005000R',
  '66009202R',
  '66009302R',
  '66010200R',
  '66011005R',
  '66011500NN',
  '66011500R',
  '66012004NN',
  '66012004R',
  '66040101R',
  '66040110R',
  '66050000NN',
  '66050000R',
  '66050001R',
  '66050101NN',
  '66050101R',
  '66070001R',
  '66072100R',
  '66072102R',
  '66072104R',
  '66072105R',
  '66952905WR',
  '66970110WNN',
  '66970110WR',
  '66970145WNN',
  '66970145WR',
  '66970225WR',
  '66972407WR',
  '66972801WR',
  '66O50000R',
  '68035100R',
  '68584200R',
  '68730900R',
  '6974705W',
  '6977100R',
  '69900702NN',
  '69900702R',
  '69900703NN',
  '69900703R',
  '69900801NN',
  '69900801R',
  '69900801RNN',
  '69900801WR',
  '69905900R',
  '69906900R',
  '6994400R',
  '69947590NN',
  '69950003NN',
  '69950003R',
  '6995003R',
  '69950100',
  '69950100NN',
  '69950100R',
  '69950101',
  '69950101EX',
  '69950101NN',
  '69950101R',
  '69950101WNN',
  '69950101WR',
  '69950102EX',
  '69950102NN',
  '69950102R',
  '69950102WR',
  '69950105R',
  '69950109NN',
  '69950109R',
  '6995010NN',
  '69950110NN',
  '69950111NN',
  '69950111R',
  '69950111WNN',
  '69950111WR',
  '69950120NN',
  '69950120R',
  '69950200NN',
  '69950200R',
  '69950201NN',
  '69950201R',
  '69950201WR',
  '69950202NN',
  '69950202R',
  '69950203R',
  '69950204NN',
  '69950204R',
  '69950204WR',
  '69950205NN',
  '69950205R',
  '69950255WR',
  '69950305NN',
  '69950701R',
  '69950702NN',
  '69950702R',
  '69950705WNN',
  '69950705WR',
  '69950803EX',
  '69950803WEX',
  '69950804WEX',
  '69950805NN',
  '69950805R',
  '69950805WNN',
  '69950805WR',
  '69950903WR',
  '69950904W',
  '69950904WEX',
  '69950904WR',
  '69950905NN',
  '69950905R',
  '69950905WR',
  '69951007W',
  '69951007WNN',
  '69951007WR',
  '69951014NN',
  '69951014W',
  '69951014WNN',
  '69951014WR',
  '69951401NN',
  '69951401R',
  '69951401WNN',
  '69951401WR',
  '69951402NN',
  '69951402R',
  '69951402WNN',
  '69951402WR',
  '69952002W',
  '69952002WNN',
  '69952002WR',
  '69952095R',
  '69952101WR',
  '69952102NN',
  '69952102WR',
  '69952204WEX',
  '69952205NN',
  '69952205W',
  '69952205WNN',
  '69952205WR',
  '69952301NN',
  '69952301WEX',
  '69952301WNN',
  '69952301WR',
  '69952401NN',
  '69952401W',
  '69952401WEX',
  '69952401WNN',
  '69952401WR',
  '69952405WEX',
  '69952905NN',
  '69952905R',
  '69952905W',
  '69952905WNN',
  '69952905WR',
  '69954201WEX',
  '69970109WNN',
  '69970109WR',
  '69970110EX',
  '69970110WEX',
  '69970110WNN',
  '69970110WR',
  '69970121WR',
  '69970145NN',
  '69970145W',
  '69970145WN',
  '69970145WNN',
  '69970145WR',
  '69970145WRNN',
  '69970205WNN',
  '69970205WR',
  '69970225NN',
  '69970225W',
  '69970225WNN',
  '69970225WR',
  '69970230WNN',
  '69970235W',
  '69970235WNN',
  '69970235WR',
  '69970255WNN',
  '69970255WR',
  '69970325WR',
  '69970335WNN',
  '69970335WR',
  '69970400WR',
  '69970401NN',
  '69970401WEXR',
  '69970401WNN',
  '69970401WR',
  '69970405NN',
  '69970405W',
  '69970405WEX',
  '69970405WNN',
  '69970405WR',
  '69970407WEX',
  '69970407WNN',
  '69970501WNN',
  '69970504WR',
  '69970505WNN',
  '69970505WR',
  '69970515WNN',
  '69970515WR',
  '69970605WNN',
  '69970605WR',
  '69970705NN',
  '69970705R',
  '69970705RW',
  '69970705W',
  '69970705WN',
  '69970705WNN',
  '69970705WNN ',
  '69970705WR',
  '69970710W',
  '69970710WNN',
  '69970710WR',
  '69970715WN',
  '69971045WR',
  '699712015WR',
  '69971215NN',
  '69971215WNN',
  '69971215WR',
  '69971301W',
  '69971301WNN',
  '69971301WR',
  '69971305W',
  '69971305WNN',
  '69971305WR',
  '69971310WR',
  '69971311NN',
  '69971311W',
  '69971311WNN',
  '69971311WR',
  '69971315NN',
  '69971315W',
  '69971315wNN',
  '69971315WR',
  '69971515WR',
  '69971705WR',
  '69972015R',
  '69972015W',
  '69972015WNN',
  '69972015WR',
  '69972025WNN',
  '69972025WR',
  '69972031R',
  '69972105W',
  '69972105WNN',
  '69972105WR',
  '69972225NN',
  '69972225WNN',
  '69972225WR',
  '69972235NN',
  '69972235R',
  '69972403WR',
  '69972404NN',
  '69972405W',
  '69972405WNN',
  '69972405WR',
  '69972407EX',
  '69972407N',
  '69972407NN',
  '69972407W',
  '69972407WEX',
  '69972407WNN',
  '69972407WR',
  '699727801WR',
  '69972801NN',
  '69972801R',
  '69972801W',
  '69972801WEX',
  '69972801WEXR',
  '69972801WNN',
  '69972801WR',
  '699728801WNN',
  '69972901NN',
  '69972901WR',
  '69972905R',
  '69973005NN',
  '69973005WR',
  '69973205WNN',
  '69973205WR',
  '69973305NN',
  '69973305WNN',
  '69973305WR',
  '69973701NN',
  '69973701WNN',
  '69973701WR',
  '69973905NN',
  '69973905R',
  '69973905WNN',
  '69973905WR',
  '69973911R',
  '69973915NN',
  '69973915R',
  '69973915WR',
  '69974501NN',
  '69974501WR',
  '69974601R',
  '69974602NN',
  '69974602R',
  '69974603R',
  '69974705NN',
  '69974705R',
  '69974705WNN',
  '69974705WR',
  '69974715NN',
  '69974715R',
  '69974715WNN',
  '69974715WR',
  '69974751R',
  '69974805NN',
  '69974805R',
  '69974815NN',
  '69974815R',
  '69974905NN',
  '69974905R',
  '69974905WR',
  '69975005NN',
  '69975005R',
  '69975305R',
  '69975315NN',
  '69975401WR',
  '69975405NN',
  '69975405R',
  '69975601R',
  '69975601W',
  '69976501NN',
  '69976501R',
  '69976505NN',
  '69976505R',
  '69976605NN',
  '69976605R',
  '69976911R',
  '69976912NN',
  '69976912R',
  '69976912WR',
  '69977000R',
  '699771000R',
  '69977100ANN',
  '69977100NN',
  '69977100R',
  '69977400NN',
  '69977400R',
  '69977415R',
  '69978801NN',
  '69978801R',
  '69978901NN',
  '69978901R',
  '69978901WR',
  '69979201NN',
  '69979201R',
  '699802000R',
  '69980200R',
  '69980301NN',
  '69980301R',
  '69980501NN',
  '69980501R',
  '69980900R',
  '699950102R',
  '699952301WR',
  '71929002R',
  '71929005R',
  '71930611NN',
  '71930611R',
  '71931107R',
  '73601602NN',
  '73601602WR',
  '75115804R',
  '75115804WR',
  '75117904R',
  '75120400NN',
  '75120400R',
  '75161004R',
  '75161004WR',
  '75164001W',
  '75164001WNN',
  '75164001WR',
  '7516400WR',
  '75170200WR',
  '75170700WR',
  '75172901WR',
  '75184001WR',
  '75185100R',
  '75185200R',
  '75188300R',
  '75188400R',
  '75189300R',
  '75189400R',
  '75189401R',
  '75189800R',
  '75189900R',
  '75190300R',
  '75190301R',
  '75190700R',
  '75190701R',
  '75191000R',
  '75192500R',
  '75192501R',
  '75192600R',
  '75192700R',
  '75193300R',
  '75194300R',
  '75194400R',
  '75402002WR',
  '75402300WR',
  '75402601WR',
  '75404600WR',
  '75405240WNN',
  '75405240WR',
  '75405500R',
  '75406200R',
  '75438800WNN',
  '75438800WR',
  '75438803W',
  '75438803WNN',
  '75438803WR',
  '75438808WR',
  '75439601R',
  '75439601WNN',
  '75439601WR',
  '75439602W',
  '75439602WNN',
  '75439602WR',
  '75440400WNN',
  '75440400WR',
  '75441702W',
  '75441702WR',
  '75448001R',
  '75468803WR',
  '75508400R',
  '75510600R',
  '75513200W',
  '75513200WR',
  '75525000R',
  '75525000WR',
  '75530300W',
  '75530300WNN',
  '75530300WR',
  '75530301WR',
  '75530400WNN',
  '75530400WR',
  '75530500W',
  '75530500WNN',
  '75530500WR',
  '75540000WR',
  '75601200W',
  '75606590R',
  '75608090WR',
  '75608690WR',
  '75609900R',
  '75610100R',
  '75610801R',
  '75639601WR',
  '75639602WR',
  '75691790NN',
  '75691790R',
  '75692690NN',
  '75692690R',
  '75695500R',
  '75695501R',
  '75805100WR',
  '75805101WR',
  '75832302W',
  '75832302WNN',
  '75832302WR',
  '75832703WR',
  '75908800WR',
  '76100000WR',
  '76102100R',
  '763010602WR',
  '76301600WNN',
  '76301600WR',
  '76301601NN',
  '76301601WR',
  '76301602NN',
  '76301602WNN',
  '76301602WR',
  '76301605WR',
  '76302400WR',
  '76310602WR',
  '76606590R',
  '76801390R',
  '76801390W',
  '76805901R',
  '76805901WR',
  '76829990WR',
  '76915400R',
  '76915500R',
  '76917100R',
  '76929900R',
  '76929900WR',
  '76941401WR',
  '76941701WR',
  '76943500WR',
  '76943501WR',
  '76944003WR',
  '775164001WR',
  '81501000R',
  '85700500R',
  '86700400NN',
  '86700500',
  '86700500NN',
  '86700500R',
  '91100202R',
  '91100501R',
  '91100502R',
  '91149200R',
  '91161000R',
  '91161001NN',
  '91161001R',
  '91161002NN',
  '91161002R',
  '91161003NN',
  '91161003R',
  '91161200NN',
  '91161200R',
  '91161201R',
  '91161202NN',
  '91161202R',
  '91161301NN',
  '91161301R',
  '91161900NN',
  '91161900R',
  '91161901R',
  '91162401R',
  '91164800R',
  '911847201R',
  '91340229R',
  '91422600R',
  '91427804R',
  '91431202NN',
  '91431202R',
  '91444300NN',
  '91444300R',
  '91445710NN',
  '91445710R',
  '91445713NN',
  '91445713R',
  '91485500NN',
  '91485500R',
  '91496812WR',
  '91498604NN',
  '91498604R',
  '91498608NN',
  '91498608R',
  '91498612NN',
  '91498612R',
  '91498612RNN',
  '91498612W',
  '91498612WNN',
  '91498612WR',
  '91498613NN',
  '91498613R',
  '91499500W_U',
  '91499500W-U',
  '914998612R',
  '91847102NN',
  '91847102R',
  '91847103NN',
  '91847103R',
  '91847201NN',
  '91847201R',
  '91847202NN',
  '91847202R',
  '91847203R',
  '918472202R',
  '91847600NN',
  '91847600R',
  '91847801NN',
  '91847801R',
  '91890002NN',
  '91890002R',
  '91890003R',
  '91890005NN',
  '91890005R',
  '91895704NN',
  '91895704R',
  '91895704WNN',
  '91895704WR',
  '91895705R',
  '91895706NN',
  '91895706R',
  '91897506R',
  '91898612R',
  '92897506NN',
  '93140223NN',
  '93140223R',
  '93140229NN',
  '93140229NNN',
  '93140229R',
  '93140233NN',
  '93140233R',
  '93140299R',
  '93140573NN',
  '93140573R',
  '93140575NN',
  '93140575R',
  '93149816WR',
  '93166093NN',
  '93172286NN',
  '93172286R',
  '93173166NN',
  '93173166R',
  '931950202R',
  '93195020R',
  '93195021R',
  '93195022R',
  '93195030R',
  '93195048R',
  '93196093NN',
  '93196093R',
  '94198612R',
  '96459900R',
  '96459900W_U',
  '96652301WR',
  '96670705NN',
  'LF15-2156-00',
  'LF15-2156-00R',
  'LF15-2156-01',
  'LF15-5330-00',
  'LF15-5775-00R',
  'LF20-1008-00',
  'LF20-1008-01',
  'LF20-2156-00',
  'LF20-2156-00R',
  'LF45-2691-00',
  'LF45-2691-00R',
  'LF45-2691-01R',
  'LF45-2691-90',
  'LF45-5327-00R',
  'LF45-5327-01R',
  'LF45-5327-02R',
  'LF46-5352-93R',
  'LT20020004',
  'LT20020004NN',
  'LT20020004R',
  'MISC',
  'MISCNN',
  'MISCR',
];

export const jids = [
  '',
  'Barbados',
  'California',
  'Colorado',
  'Connecticut',
  'Florida (Cape Coral)',
  'Florida (Miami)',
  'Florida (Orlando)',
  'Florida (Pensacola)',
  'Florida (Tallahassee)',
  'Florida (Tampa)',
  'Georgia',
  'Indiana',
  'Kentucky',
  'Massachusetts',
  'Michigan (Wixom)',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Nebraska',
  'New Jersey',
  'New York (Bronx)',
  'New York (Islandia)',
  'New York (Latham)',
  'New York (Rochester)',
  'New York (Saugerties)',
  'North Carolina',
  'Oregon',
  'Rhode Island',
  'RRF ITVM',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas (Abilene)',
  'Texas (Austin)',
  'Texas (Lubbock)',
  'Texas (El Paso)',
  'Texas (Houston)',
  'Texas (Irving)',
  'Texas (Mcallen)',
  'Texas (San Antonio)',
  'Texas (Tyler)',
  'Usvi',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Test',
];

export const jidsConsumableSupply = [
  'California',
  'Colorado',
  'Connecticut',
  'Florida',
  'Georgia',
  'Indiana',
  'Kentucky',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'New Jersey',
  'New York',
  'North Carolina',
  'South Carolina',
  'Tennessee',
  'Washington',
  'Wisconsin',
];

export const jidsNRCForms = [
  'Arizona',
  'California',
  'Colorado',
  'Connecticut',
  'Florida',
  'Georgia',
  'Illinois',
  'Indiana',
  'Kansas',
  'Kentucky',
  'Leeward Islands',
  'Maryland',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Nebraska',
  'New Jersey',
  'New York',
  'North Carolina',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Washington',
  'West Virginia',
  'Wisconsin',
];

export const jidsNRCForms_NRCDowntime = [
  'California',
  'Colorado',
  'Connecticut',
  'Florida',
  'Georgia',
  'Indiana',
  'Kentucky',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Nebraska',
  'New Jersey',
  'New York',
  'North Carolina',
  'Oregon',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
];

export const jidsRetailerContactInfo = [
  'Georgia',
  'Kentucky',
  'Michigan',
  'Minnesota',
  'Missouri',
  'New Jersey',
  'New York',
  'North Carolina',
  'Rhode Island',
  'South Dakota',
  'Washington',
];

export const jidToNumberMap = {
  Alabama: '124',
  Argentina: '140',
  Arizona: '8',
  Arkansas: '132',
  Australia: '136',
  Bahamas: '128',
  California: '7',
  Canada: '127',
  Chile: '141',
  Colombia: '142',
  Colorado: '11',
  Connecticut: '91',
  CostaRica: '103',
  Delaware: '130',
  Florida: '79',
  Georgia: '33',
  Idaho: '10',
  Illinois: '16',
  Indiana: '72',
  Iowa: '77',
  Jamaica: '117',
  Kansas: '9',
  Kentucky: '17',
  LeewardIslands: '78',
  Louisiana: '19',
  Maine: '76',
  Maryland: '73',
  Massachusetts: '116',
  Mexico: '143',
  Michigan: '13',
  Minnesota: '27',
  Mississippi: '123',
  Missouri: '14',
  Montana: '131',
  Nebraska: '12',
  Nevada: '120',
  NewHampshire: '75',
  NewJersey: '20',
  NewMexico: '81',
  NewYork: '18',
  NorthCarolina: '83',
  NorthDakota: '122',
  Ohio: '15',
  Oklahoma: '125',
  OregonGTECH: '87',
  Panama: '146',
  Pennsylvania: '129',
  Peru: '144',
  RhodeIsland: '5',
  Selae: '147',
  SouthAfrica: '137',
  SouthCarolina: '118',
  SouthDakota: '90',
  Tennessee: '69',
  Texas: '23',
  Trinidad: '93',
  UnitedKingdom: '135',
  Virginia: '26',
  Washington: '30',
  WestVirginia: '88',
  Wisconsin: '6',
  Wyoming: '126',
};

export const pcbItemNumbers = [
  '',
  '030-599-0032-05 (PROJECT)',
  '1502074-01 (PROJECT)',
  '15-3321-01',
  '15-3535-01E',
  '15-3717-21E',
  '15-3723-01E',
  '15-3945-01E',
  '15-4123-01E (PROJECT)',
  '15-4501-02E',
  '20-0294-01',
  '20-0332-01',
  '20-1286-01E',
  '20-1287-01E',
  '2-12367',
  '24-120-0011-01',
  '24-120-0091-53',
  '24-1607-01',
  '24-1607-02',
  '24-1644-01E',
  '24-1644-02E',
  '24-1788-01E',
  '24-1909-08E (PROJECT)',
  '24-1919-05E',
  '24-1925-01E',
  '24-1939-05E',
  '2-43832',
  '2-43940',
  '2-43940 (PROJECT)',
  '2-44033 (PROJECT)',
  '2-44034 (PROJECT)',
  '2-44065',
  '32-171-0703-01E',
  '444-1000',
  '444-1000 DELL CFD',
  '444-1001',
  '45-0078-02',
  '45-0136-01',
  '45-0162-01',
  '45-0162-01E',
  '45-0162-02E',
  '45-0176-01E',
  '45-0189-02E (PROJECT)',
  '45-0209-01E (PROJECT)',
  '45-0210-01E (PROJECT)',
  '46-1296-01E (PROJECT)',
  '50-0203-01',
  '50-0232-01',
  '50-0245-01',
  '50-0245-02E',
  '50-0245-03',
  '50-0340-01E (PROJECT)',
  '50-0360-01E',
  '50-0379-01E (PROJECT)',
  '50-172-0011-01',
  '50-172-0011-01E',
  '50-172-0022-01',
  '50-172-0022-01E',
  '51-0681-01',
  '51-0756-01',
  '51-0759-01',
  '51-0761-01',
  '51-0764-01',
  '51-0764-02E',
  '51-0784-01',
  '51-0829-01',
  '51-0928-01',
  '51-0944-01',
  '51-0944-01E',
  '51-0989-01',
  '51-0989-01E',
  '51-0989-03E',
  '51-0997-01',
  '51-1000-01',
  '51-1000-01E',
  '51-1002-02',
  '51-1002-03',
  '51-1002-05',
  '51-1002-06',
  '51-1028-01',
  '51-1028-01E',
  '51-1037-01E (PROJECT)',
  '51-1045-01',
  '51-1049-01E',
  '51-1581-01',
  '51-1623-01',
  '51-1623-01E',
  '51-1623-02E',
  '51-1623-02E (PROJECT)',
  '51-1623-04E',
  '51-1625-01',
  '51-1625-01E',
  '51-1625-04E',
  '51-1626-01E',
  '51-1630-02',
  '51-1632-01',
  '51-1632-01E',
  '51-1653-01',
  '51-1653-01E',
  '51-1654-01',
  '51-1661-01E',
  '51-1664-01',
  '51-1664-01E',
  '51-1665-02E',
  '51-1666-01',
  '51-1666-01E',
  '51-1666-02',
  '51-1666-05',
  '51-1676-01E',
  '51-1676-02E',
  '51-1676-03E',
  '51-1676-04E',
  '51-1676-05E',
  '51-1676-06E',
  '51-1685-01E',
  '51-1686-01E',
  '51-1688-01',
  '51-1688-01E',
  '51-1689-01E',
  '51-1691-01E',
  '51-1693-01E',
  '51-1693-02E',
  '51-1693-04E',
  '51-1693-05E',
  '51-1698-02E',
  '51-1702-01',
  '51-1764-01E',
  '51-1764-02E',
  '51-1764-06E',
  '51-1780-01E',
  '51-1781-01E',
  '51-1782-01E',
  '51-1782-02E',
  '51-1783-01E',
  '51-1784-01E',
  '51-1784-03E',
  '51-1785-01E',
  '51-1786-01E',
  '51-1787-01E',
  '51-1789-01E',
  '51-1790-01E',
  '51-1794-01E',
  '51-1795-01E',
  '51-1800-01E',
  '51-1800-02E',
  '51-180-0060-45',
  '51-180-0066-01',
  '51-180-0066-02',
  '51-180-0069-12',
  '51-180-0073-12',
  '51-180-0073-22',
  '51-180-0121-01',
  '51-180-0121-01E',
  '51-1806-01E',
  '51-1807-01E',
  '51-1816-01E',
  '51-1818-01E (PROJECT)',
  '51-1821-01E (PROJECT)',
  '51-1821-02E (PROJECT)',
  '51-1821-03E (PROJECT)',
  '51-1850-01E (PROJECT)',
  '51-1865-01E',
  '51-1866-01E',
  '51-1867-01E',
  '51-1868-01E',
  '51-1869-01E',
  '51-1871-01E',
  '51-1876-01E',
  '51-1877-01E',
  '51-1883-01E',
  '51-1891-01E',
  '51-1891-03E',
  '51-1899-01E',
  '51-1899-01E (PROJECT)',
  '51-1899-06E',
  '51-1899-06E (PROJECT)',
  '51-1909-01E',
  '51-1909-01E (PROJECT)',
  '51-1911-03E (PROJECT)',
  '51-1911-04E',
  '51-1923-01E',
  '51-1924-03E (PROJECT)',
  '51-1930-01E',
  '51-1934-03E (PROJECT)',
  '51-1940-01E (PROJECT)',
  '51-1941-01E',
  '51-1942-01E',
  '51-1958-01E',
  '51-1958-02E',
  '51-1984-01E (PROJECT)',
  '51-1991-01E',
  '51-9060-14',
  '51-9060-65',
  '51-9062-01',
  '51-9064-10',
  '51-9106-01',
  '51-9110-01',
  '51-9113-01',
  '51-9113-02',
  '51-9113-03',
  '51-9113-04',
  '51-9113-04E',
  '51-9113-20',
  '51-9120-01',
  '52-0660-01E',
  '52-0662-01E',
  '52-125-0001-01E',
  '53-0131-01',
  '53-0131-01E',
  '53-0131-02E',
  '53-0697-01E (PROJECT)',
  '53-123-0002-01',
  '53-123-0003-01E',
  '53-124-0015-01',
  '53-124-0015-51',
  '53-124-0020-01',
  '53-124-0020-01E',
  '53-1824-02E',
  '53-1824-06E',
  '599-0032-05',
  '599-0032-05 (PROJECT)',
  '599-0032-06',
  '599-0032-06 (PROJECT)',
  '599-0042-02',
  '599-0042-02 (PROJECT)',
  '599-0091-00',
  '61-0784-05E',
  '61-0901-34E',
  '61-2000-16E (PROJECT)',
  '71-1434-01E',
  '740-0000-AA (PROJECT)',
  '74-1374-01E',
  '764-0000-AA1 (PROJECT)',
  '765-0000-AA1 (PROJECT)',
  'DELL 1708FPB',
  'DELL 1708FPF',
  'DELL 1708FPT',
  'DELL 196FPB',
  'DELL P1913SF',
  'DELL E176FPF',
  'DELL E178FPC',
  'DELL E1910F (PROJECT)',
  'DELL E197FPF',
  'DELL E2210C',
  'DELL E2210F (PROJECT)',
  'DELL E2214HB (PROJECT)',
  'DELL P170SB',
  'DELL P170SF',
  'DELL P170ST',
  'DELL P1914SF',
  'DS2278 (PROJECT)',
  'DS6878 (PROJECT)',
  'E00136',
  'DELL P2217H (PROJECT)',
];

export const powerSupplyItemNumbers = [
  '',
  '50-0170-01',
  '50-0243-02',
  '50-0247-02',
  '50-0247-02E',
  '50-0290-01E',
  '50-0290-04E',
  '50-0359-01E',
  '50-190-0003-01',
  '50-190-0003-01E',
];

export const gamingProducts = [
  'BrainBox/LB/SC',
  'Monitor',
  'PCB',
  'Player Tracking',
  'Printers/BA',
];

export const lotteryProducts = [
  'Readers',
  'Power Supplies',
  'Printer Circuit Boards',
  'Bill Acceptors',
  'MINI/ Quad Bursters',
];

export const quadBursterNumberItems = [
  '',
  '15-121-0005-10',
  '15-3300-01E',
  '15-3300-02E',
  '15-3300-03E',
  '15-3600-01E',
  '15-3600-02E',
  '15-9006-01',
  '15-9006-04E',
];

export const readerItemNumbers = [
  '',
  '15-2488-03E',
  '24-1617-01',
  '24-1707-02E',
  '24-1725-01E',
  '24-1725-02E',
  '24-1725-03E',
  '24-1726-01E',
  '24-1726-03E',
  '24-1726-04E',
  '24-1925-01E',
  '24-1943-01E',
  '24-1943-03E',
  '53-0126-01E',
  '53-0127-02',
  '53-0127-04',
  '53-0132-01',
  '53-0132-01E',
  '53-0132-02',
  '53-0211-01E',
  '53-0211-02',
  '53-0211-02E',
  '53-0211-03',
  '53-0211-04',
  '53-0225-05E',
  '53-0225-06E',
  '53-0225-07E',
  '61-0376-01',
  '61-1962-03E',
];

export const solutionsGaming = [
  '',
  'Unrepairable',
  'Replace components',
  'Replace parts',
  'Clean/Lube/Calibrat',
  'Clean/Test',
  'Update Firmware',
  'Straighten Chassis',
  'Load fw/Erase fw',
  'Calibrate',
  'Update Firmware',
  'Trace Open',
];

export const solutions = [
  '',
  'Calibrate',
  'Clean/Lube/Calibrate',
  'Clean/Lube/Firmware',
  'Clean/Test',
  'ECO',
  'Load FW/Erase FW',
  'Reflow',
  'Replace Components',
  'Replace Parts',
  'Straighten Chassis',
  'Tightening Loose Components',
  'Trace Open',
  'Unrepairable',
  'Update Firmware',
];

export const secondTimeReturn = ['Yes', 'No'];

export const status = ['PASS', 'FAILED', 'IN PROGRESS', 'NPF', 'SCRAP'];

// FST Form Configs
export const commDevice = ['Ventus', 'Hughes'];

export const displayMonitor = ['53-0671-01E', 'ESMM', 'TV'];

export const peripherals = [
  '15-4294-02E',
  '24-1595-03E',
  '50-0245-01',
  '50-0245-02E',
  '50-0335-01E',
  '52-0660-01E',
  '53-0669-01E',
  '53-0673-01E',
  '53-0673-02E',
  '53-0686-01E',
  '53-0692-01E',
  '53-0694-01E',
  '53-123-0003-01E',
  '61-0376-01',
  '61-0766-01E',
  '61-0766-04E',
  '61-0766-06',
  '61-0766-06E',
  '61-0766-08E',
  '61-0766-09E',
  '61-0766-14E',
  '61-0766-18E',
  '61-0766-21E',
  '61-0766-22E',
  '61-0766-24E',
  '61-0901-04E',
  '61-0901-09E',
  '61-0901-11',
  '61-0901-18E',
  '61-0901-19E',
  '61-0901-23E',
  '61-0901-27E',
  '61-0901-28E',
  '61-0901-29E',
  '61-0901-30E',
  '61-0901-31E',
  '61-0901-33E',
  '61-0901-37E',
  '61-0901-38E',
  '61-0901-39E',
  '61-0901-40E',
  '61-0901-41',
  '61-0901-43',
  '61-0901-45',
  '61-2000-02E',
  '61-2000-03E',
  '61-2000-04E',
  '61-2000-06E',
  '61-2000-07E',
  '61-2000-10E',
  '61-2000-12E',
  '61-2000-14E',
  '61-2000-17E',
  '61-2000-22',
  '69-0571-02',
  'NC-444-2000',
  'NC-444-2004',
  'NC-444-2005',
  'NY-444-2005',
];

export const printers = [
  '53-0680-11E',
  '53-0680-01E',
  '53-0207-01',
  '53-0215-01',
  '53-0216-01E',
  '53-0216-03E',
  '53-1824-06E',
  '53-1824-02E',
  '53-1824-03E',
  '53-1824-01E',
  '53-0133-22E',
  '53-0133-03E',
  '53-0680-01E',
];

export const fstLotteryProducts = [
  'Bill Acceptors',
  'Comm Device',
  'Display/Monitors',
  'MINI/Quad Bursters',
  'Peripherals',
  'Power Supplies',
  'Printer Circuit Boards',
  'Printers',
  'Readers',
  'Terminal',
];

export const terminals = [
  '61-0376-01',
  '61-0413-01',
  '61-0500-01',
  '61-0612-01',
  '61-0670-27E',
  '61-0670-28E',
  '61-0670-31E',
  '61-0670-33E',
  '61-0670-38E',
  '61-0670-39E',
  '61-0670-41E',
  '61-0670-43E',
  '61-0670-45',
  '61-0700-01E',
  '61-0705-01E',
  '61-0712-01E',
  '61-0716-01E',
  '61-0739-06E',
  '61-0739-09E',
  '61-0739-11E',
  '61-0739-14E',
  '61-0749-14E',
  '61-0751-01E',
  '61-0752-01E',
  '61-0757-01E',
  '61-0758-01E',
  '61-0762-06E',
  '61-0780-05E',
  '61-0780-07E',
  '61-0780-08E',
  '61-0780-10E',
  '61-0783-03E',
  '61-0783-18E',
  '61-0784-04E',
  '61-0784-09E',
  '61-0784-12E',
  '61-0784-13E',
  '61-0784-15E',
  '61-0784-17E',
  '61-0784-23',
  '61-0784-24',
  '61-0784-27',
  '61-0784-30',
  '61-0785-06E',
  '61-0883-04E',
  '61-0883-10',
  '61-0885-06E',
  '61-0885-11',
  '61-0901-40E',
  '61-1961-04E',
  '61-1961-05E',
  '61-1981-02',
  '61-1962-03E',
  '61-1977-06E',
  '61-2000-17E',
];

export const incorrectStatus = [
  'Case created incorrect / Serial number not captured',
  'Case was not completed and Serial number not recorded',
  'Hostname and site name incorrect',
  'Hostname correction',
  'OTA complete but requires FST visit',
  'OTA incomplete',
  'Pending OTA',
  'Removal',
  'Ventus not in Genesis',
  '2-Port Ventus (maybe MI specific)',
  'Ventus not in Cadence',
];

export const fstLocation = ['Depot', 'Lock Up', 'Van', 'Warehouse', 'Other'];

export const actionTaken = [
  'Case created for FST revisit',
  'Corrected in Genisis',
  'Sent to FS Supervisor/Manager',
  `Can't OTA`,
];

export const reasons = [
  'Administrative',
  'Depot Coverage',
  'Depot Repair',
  'Down Call',
  'Drive Home',
  'High Jackpots',
  'Holiday Coverage',
  'Urgent Removal Request - Ad-hoc',
  'Lottery Special Events',
  'NOC Repoints /ASI Calls',
  'On-Site / Off-site Meeting',
  'Other',
  'Out-of-State Project',
  'Phone Support (On-call assist)',
  'Penalty Call',
  'Project (other)',
  'Site Project',
  'Software Issues',
  'Staffing / Field Coverage',
  'Standby / Service Call Coverage',
  'Training',
  'Travel',
  'Vacation Coverage',
  'Vehicle Issues / Breakdowns',
  'Weather-related',
];

export const fstJids = [
  '',
  'Barbados',
  'California (Los Angeles)',
  'California (Northern)',
  'California (Southern)',
  'Colorado',
  'Connecticut',
  'Florida (Cape Coral)',
  'Florida (Miami)',
  'Florida (Orlando)',
  'Florida (Pensacola)',
  'Florida (Tallahassee)',
  'Florida (Tampa)',
  'Georgia',
  'Indiana',
  'Kentucky',
  'Massachusetts',
  'Michigan (Wixom)',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Nebraska',
  'New Jersey',
  'New York (Bronx)',
  'New York (Islandia)',
  'New York (Latham)',
  'New York (Rochester)',
  'New York (Saugerties)',
  'North Carolina',
  'Oregon',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas (Central)',
  'Texas (North)',
  'Texas (Southeast)',
  'Texas (Southwest)',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
];

export const consumableSupply_pack = [
  '¼ pack left',
  '½ pack left',
  '¾ pack left',
  '1 pack left',
  '1 & ¼ packs left',
  '1 & ½ packs left',
  '1 & ¾ packs left',
  '2 packs left',
  '2 & ¼ packs left',
  '2 & ½ packs or more left',
  'Out of Stock',
];
export const consumableSupply_rolls = [
  '¼ roll left',
  '½ roll left',
  '¾ roll left',
  '1 roll left',
  '1 & ¼ rolls left',
  '1 & ½ rolls left',
  '1 & ¾ rolls left',
  '2 rolls left',
  '2 & ¼ rolls left',
  '2 & ½ rolls or more left',
  'Out of Stock',
];
export const consumableSupply_unit = [
  '¼ unit left',
  '½ unit left',
  '¾ unit left',
  '1 unit left',
  '1 & ¼ units left',
  '1 & ½ units left',
  '1 & ¾ units left',
  '2 units left',
  '2 & ¼ units left',
  '2 & ½ units or more left',
  'Out of Stock',
];

export const yesNoOptions = ['Yes', 'No'];
