import React, { useContext } from 'react';
import NavigationBar from '../../components/navigationBar';
import UserProfileContainer from '../../components/userProfile';
import { Box } from '@mui/material';
import ReleaseNotes from '../../components/releaseNotes';
import { LanguageContext } from '../../context/languageContext'; 

export default function Dashboard() {
  const translations = useContext(LanguageContext);

  return (
    <>
      <NavigationBar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'center', md: 'start' },
          alignItems: { xs: 'center', md: 'flex-start' },
        }}
      >
        <UserProfileContainer />
        <Box
          sx={{
            marginLeft: { xs: 0, md: '30%' },
            width: '100%',
            textAlign: { xs: 'center', md: 'left' },
            mt: { xs: 2, md: 0 },
          }}
        >
          {/* <h1>{translations.hello}</h1> */}
        </Box>
        {/* <ReleaseNotes /> */}
      </Box>
    </>
  );
}
