import React from "react";
import { Container, Typography, Link, } from "@mui/material";
import { HelpOutlineIcon } from "../../assets/icons/icons";

function NotFoundPage() {
  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        mt: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <HelpOutlineIcon sx={{ fontSize: 60, mb: 2, color: "#ffa600" }} />
      <Typography
        component="h1"
        variant="h4"
        gutterBottom
        sx={{ color: "#ffa600" }}
      >
        Oops!
      </Typography>
      <Typography variant="h6" textAlign="center" gutterBottom>
        The page you're looking for doesn't exist. But don't worry, you can find
        plenty of other services on our application.
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 2, textAlign: "center" }}>
        If you need further assistance, please go back and submit a ticket using
        the help feature.
      </Typography>
      <Typography
        variant="caption"
        display="block"
        textAlign="center"
        sx={{ mb: 2, color: "grey" }}
      >
        Error code: 404
      </Typography>
      <Typography variant="body2" sx={{ mb: 1, textAlign: "center" }}>
        Here are some helpful links:
      </Typography>
      <Link
        href="/home"
        variant="body2"
        sx={{ textDecoration: "none", mb: 1 }}
        color="primary"
      >
        Home
      </Link>
      <Link
        href="/forms"
        variant="body2"
        sx={{ textDecoration: "none", mb: 1 }}
        color="primary"
      >
        GNIECloud Forms
      </Link>
      <Link
        href="/reporting"
        variant="body2"
        sx={{ textDecoration: "none" }}
        color="primary"
      >
        GNIECloud Reporting
      </Link>
    </Container>
  );
}

export default NotFoundPage;
