import { useFormik } from "formik";
import { useState } from "react";

/**
 * Custom form hook that adds a fieldProps function to the form hook. This allows you to easily spread
 * props to handle field state and validation.
 * @param {FormikConfig<FormikValues>} config
 * @returns
 */
export default function useEngageForm(config) {
  const form = useFormik(config);
  const [alert, setAlert] = useState(null);

  const setAlertSuccess = (msg) => {
    setAlert({ type: "success", msg: msg });
  };

  const setAlertError = (msg) => {
    setAlert({ type: "error", msg: msg });
  };

  const setAlertNone = () => {
    setAlert(null);
  };

  const fieldProps = (fieldName) => {
    return {
      value: form.values[fieldName],
      onChange: form.handleChange,
      onBlur: form.handleBlur,
      error: form.errors[fieldName] && form.touched[fieldName],
      helperText:
        form.errors[fieldName] && form.touched[fieldName]
          ? form.errors[fieldName]
          : "",
    };
  };

  return {
    ...form,
    fieldProps: fieldProps,
    alert,
    setAlertSuccess,
    setAlertError,
    setAlertNone,
  };
}
