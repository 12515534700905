import {
  HomeIcon,
  DynamicFormIcon,
  AssessmentIcon,
  ConfirmationNumberIcon,
  LogoutIcon,
  HelpIcon,
  SettingsIcon,
  HeadsetMicIcon,
  WidgetsIcon,
  EngineeringIcon,
  FileOpenIcon,
} from "../assets/icons/icons";

export const getNavigationItems = (
  translations,
  navigateTo,
  handleSettingsMenuOpen,
  handleServicesMenuOpen
) => [
  {
    name: translations["home"] || "Home",
    icon: <HomeIcon />,
    onClick: () => navigateTo("/home"),
    path: "/home",
  },
  {
    name: translations["contactCenter"] || "Contact Center",
    icon: <HeadsetMicIcon />,
    onClick: () => navigateTo("/contact-center"),
    path: "/contact-center",
  },
  {
    name: translations["fieldService"] || "Field Service",
    icon: <EngineeringIcon />,
    onClick: () => navigateTo("/field-service"),
    path: "/field-service",
  },
  {
    name: translations["services"] || "Services",
    icon: <WidgetsIcon />,
    onClick: handleServicesMenuOpen,
    subItems: [
      {
        name: translations["files"] || "Files",
        icon: <FileOpenIcon />,
        onClick: () => navigateTo("/files"),
      },
      {
        name: translations["forms"] || "Forms",
        icon: <DynamicFormIcon />,
        onClick: () => navigateTo("/forms"),
        path: "/forms",
      },
      {
        name: translations["reports"] || "Reports",
        icon: <AssessmentIcon />,
        onClick: () => navigateTo("/reports"),
        path: "/reports",
      },
    ],
  },
  {
    name: translations["settings"] || "Settings",
    icon: <SettingsIcon />,
    onClick: handleSettingsMenuOpen,
    path: "/settings",
  },
];

export const adminCategories = [
  // User and Access Management
  {
    title: "User and Access Management",
    subcategories: [
      { text: "Groups", link: "/admin/groups/" },
      { text: "User Accounts", link: "/admin/users/management" },
      { text: "Local User Invites", link: "/admin/users/invites" },
      // { text: "Roles and Permissions", link: "/admin/users/roles-permissions" },
      // { text: "Access Logs", link: "/admin/access-logs" },
    ],
  },
  // Data Management
  // {
  //   title: "Data Management",
  //   subcategories: [
  // 	{ text: "Data Import/Export", link: "/admin/data-import-export" },
  // 	{
  // 	  text: "Database Backup and Restore",
  // 	  link: "/admin/database-backup-restore",
  // 	},
  // 	{ text: "Data Cleaning Tools", link: "/admin/data-cleaning" },
  //   ],
  // },
  // Customization and Configuration
  // {
  //   title: "Customization and Configuration",
  //   subcategories: [
  // 	{ text: "Custom Fields", link: "/admin/custom-fields" },
  // 	{ text: "Module Management", link: "/admin/module-management" },
  // 	{ text: "Layout Customization", link: "/admin/layout-customization" },
  //   ],
  // },
  // Integration Settings
  // {
  //   title: "Integration Settings",
  //   subcategories: [
  // 	{ text: "API Access", link: "/admin/api-access" },
  // 	{text: "Platform APIs", link: "/admin/platform-apis"},
  // 	{
  // 		text: "Third-Party Integrations",
  // 		link: "/admin/third-party-integrations",
  // 	},
  // 	{ text: "Webhooks", link: "/admin/webhooks" },
  //   ],
  // },
  // Security Settings
  // {
  //   title: "Security Settings",
  //   subcategories: [
  // 	{ text: "Password Policies", link: "/admin/password-policies" },
  // 	{
  // 	  text: "Two-Factor Authentication",
  // 	  link: "/admin/two-factor-authentication",
  // 	},
  // 	{
  // 	  text: "IP Whitelisting/Blacklisting",
  // 	  link: "/admin/ip-whitelisting-blacklisting",
  // 	},
  //   ],
  // },
  // Notification and Communication Settings
  // {
  //   title: "Notification and Communication Settings",
  //   subcategories: [
  // 	{ text: "Email Templates", link: "/admin/email-templates" },
  // 	{ text: "SMS Settings", link: "/admin/sms-settings" },
  // 	{
  // 	  text: "Notification Preferences",
  // 	  link: "/admin/notification-preferences",
  // 	},
  //   ],
  // },
  // Reporting and Analytics
  // {
  //   title: "Reporting and Analytics",
  //   subcategories: [
  // 	{ text: "Custom Reports", link: "/admin/custom-reports" },
  // 	{
  // 	  text: "Dashboard Configuration",
  // 	  link: "/admin/dashboard-configuration",
  // 	},
  // 	{ text: "Audit Trails", link: "/admin/audit-trails" },
  //   ],
  // },
  // Customer Support and Feedback
  // {
  //   title: "Customer Support and Feedback",
  //   subcategories: [
  // 	{
  // 	  text: "Support Ticket Management",
  // 	  link: "/admin/support-ticket-management",
  // 	},
  // 	{ text: "Feedback Collection", link: "/admin/feedback-collection" },
  // 	{
  // 	  text: "Knowledge Base Management",
  // 	  link: "/admin/knowledge-base-management",
  // 	},
  //   ],
  // },
  // Performance and System Health
  // {
  //   title: "Performance and System Health",
  //   subcategories: [
  // 	{ text: "System Monitoring", link: "/admin/system-monitoring" },
  // 	{ text: "Error Engine", link: "/admin/error-engine" },
  // 	{
  // 	  text: "Resource Usage Statistics",
  // 	  link: "/admin/resource-usage-statistics",
  // 	},
  //   ],
  // },
  // System Maintenance and Updates
  // {
  //   title: "System Maintenance and Updates",
  //   subcategories: [
  // 	{ text: "Update Management", link: "/admin/update-management" },
  // 	{ text: "Maintenance Mode", link: "/admin/maintenance-mode" },
  // 	{ text: "Service Health Dashboard", link: "/admin/service-health" },
  // 	{ text: "Change Logs", link: "/admin/change-logs" },
  //   ],
  // },
];
