// components/AccessDenied.js
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NavigationBar from "../../components/navigationBar";
import { WarningIcon } from "../../assets/icons/icons";

const AccessDenied = () => {
    return (
      <>
        <NavigationBar />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
        >
          <WarningIcon color="error" fontSize="large" />
          <Typography variant="h6" color="error" mt={2}>
            Access Denied
          </Typography>
          <Typography variant="body1" color="textSecondary" mt={2}>
            You don't have permission to access this page. Please contact your administrator to update your permissions or use the help widget for more information.
          </Typography>
        </Box>
      </>
    );
};

export default AccessDenied;
