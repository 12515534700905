import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Typography,
  Stack,
  DialogContentText,
} from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';
import useEngageForm from '../hooks/useEngageForm';
import AuthModalFooter from './AuthModal/AuthModalFooter';
import AuthModalHeader from './AuthModal/AuthModalHeader';
import AuthModalForm from './AuthModal/AuthModalForm';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';

const ResetSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
  resetCode: Yup.string()
    // .matches(/^[A-Z]{3}[0-9]{3}$/, "Invalid code")
    .matches(/^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/, 'Invalid code')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .matches(/[0-9+]/, 'Must container at least 1 number')
    .matches(/[a-z]+/, 'Must contain at least 1 lower case letter')
    .matches(/[A-Z]+/, 'Must contain at least 1 upper case letter')
    .required('Required'),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Password does not match')
    .required('Required'),
});

const ForgotSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
});

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string().required('Required'),
});

const ActivateSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
  inviteCode: Yup.string()
    // .matches(/^[A-Z]{3}[0-9]{3}$/, "Invalid code")
    .matches(/^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/, 'Invalid code')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .matches(/[0-9]+/, 'Must contain at least 1 number')
    .matches(/[a-z]+/, 'Must contain at least 1 lowercase letter')
    .matches(/[A-Z]+/, 'Must contain at least 1 uppercase letter')
    .required('Required'),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Password does not match')
    .required('Required'),
});

export default function AuthModal({
  isDialogOpen,
  setIsDialogOpen,
  email,
  inviteCode,
  resetCode,
}) {
  const [activeTab, setActiveTab] = useState(
    email && inviteCode ? 1 : email && resetCode ? 3 : 0
  );
  const navigate = useNavigate(); // Use navigate from react-router-dom
  const { login } = useAuth(); // Use login method from AuthContext

  const loginForm = useEngageForm({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      await handleLogin(values);
    },
  });

  const activateForm = useEngageForm({
    initialValues: {
      email: email ?? '',
      password: '',
      verifyPassword: '',
      inviteCode: inviteCode ?? '',
    },
    validationSchema: ActivateSchema,
    onSubmit: async (values) => {
      await handleActivate(values);
    },
  });

  const forgotForm = useEngageForm({
    initialValues: {
      email: '',
    },
    validationSchema: ForgotSchema,
    onSubmit: async (values) => {
      await handleForgotPassword(values);
    },
  });

  const resetForm = useEngageForm({
    initialValues: {
      email: email ?? '',
      resetCode: resetCode ?? '',
      password: '',
      verifyPassword: '',
    },
    validationSchema: ResetSchema,
    onSubmit: async (values) => {
      await handleResetPassword(values);
    },
  });

  const handleLogin = async (loginData) => {
    try {
      loginForm.setAlertNone();
      await new Promise((res) => setTimeout(res, 1000));

      // Make the API request to log the user in
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/login`,
        loginData
      );

      const userDetails = response.data;

      if (userDetails) {
        // Update the context with user details
        login(userDetails);

        // Navigate to the home page after successful login
        navigate('/home');
      }
    } catch (error) {
      console.error('Error during login:', error);
      loginForm.setAlertError(
        error.response?.data?.error || 'Login failed. Please try again.'
      );
    }
  };

  const handleActivate = async (activateData) => {
    try {
      activateForm.setAlertNone();
      await new Promise((res) => setTimeout(res, 1000));

      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/activate`,
        activateData
      );

      // User activated, so navigate to login tab and reset it
      loginForm.setAlertSuccess('Account activated. Please login.');
      loginForm.resetForm();
      setActiveTab(0);
    } catch (error) {
      console.error('Error during activation:', error);
      activateForm.setAlertError(
        error.response?.data?.error || 'Activation failed. Please try again.'
      );
    }
  };

  const handleForgotPassword = async (forgotData) => {
    try {
      forgotForm.setAlertNone();
      await new Promise((res) => setTimeout(res, 1000));

      // Call the API to send password reset email if user exsist
      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/request-password-reset`,
        forgotData
      );

      forgotForm.setAlertSuccess(
        'If you have a valid account, an email has been sent to reset your password.'
      );
    } catch (error) {
      forgotForm.setAlertError(
        error.response?.data?.error || 'Reset request failed. Please try again.'
      );
    }
  };

  const handleResetPassword = async (resetData) => {
    try {
      resetForm.setAlertNone();
      await new Promise((res) => setTimeout(res, 1000));
      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/reset-password`,
        resetData
      );

      // Password reset, so navigate to login tab and reset it
      loginForm.setAlertSuccess('Password reset. Please login.');
      loginForm.resetForm();
      setActiveTab(0);
    } catch (error) {
      resetForm.setAlertError(
        error.response?.data?.error ||
          'Could not reset password. Please try again.'
      );
    }
  };

  return (
    <Dialog open={isDialogOpen}>
      {activeTab === 0 && (
        <AuthModalForm onSubmit={loginForm.handleSubmit}>
          <AuthModalHeader
            setIsDialogOpen={setIsDialogOpen}
            title={'Local Login'}
          />
          <DialogContent>
            <Stack paddingTop={1} gap={'1rem'}>
              <TextField
                id='email'
                name='email'
                label='Email Address'
                size='small'
                {...loginForm.fieldProps('email')}
              />
              <TextField
                id='password'
                name='password'
                label='Password'
                type='password'
                size='small'
                autoComplete='current-password'
                {...loginForm.fieldProps('password')}
              />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Button onClick={() => setActiveTab(1)}>Activate Account</Button>
              <Button onClick={() => setActiveTab(2)}>Forgot Password</Button>
            </Stack>
          </DialogContent>
          <AuthModalFooter
            buttonText={'Login'}
            alert={loginForm.alert}
            isValid={loginForm.isValid}
            dirty={loginForm.dirty}
            isSubmitting={loginForm.isSubmitting}
          />
        </AuthModalForm>
      )}

      {activeTab === 1 && (
        <AuthModalForm onSubmit={activateForm.handleSubmit}>
          <AuthModalHeader
            setIsDialogOpen={setIsDialogOpen}
            title={'Activate Account'}
          />

          <DialogContent>
            <Stack paddingTop={1} gap={'1rem'}>
              <TextField
                id='email'
                name='email'
                label='Email Address'
                size='small'
                {...activateForm.fieldProps('email')}
              />
              <TextField
                id='inviteCode'
                name='inviteCode'
                label='Invite Code'
                size='small'
                {...activateForm.fieldProps('inviteCode')}
              />
              <TextField
                id='password'
                name='password'
                label='Password'
                type='password'
                size='small'
                autoComplete='current-password'
                {...activateForm.fieldProps('password')}
              />
              <TextField
                id='verifyPassword'
                name='verifyPassword'
                label='Re-enter Password'
                type='password'
                size='small'
                autoComplete='current-password'
                {...activateForm.fieldProps('verifyPassword')}
              />
              <Stack direction={'row'} justifyContent={'space-between'}>
                <DialogContentText>
                  Already have an account?
                  <Button onClick={() => setActiveTab(0)}>Login</Button>
                </DialogContentText>
              </Stack>
            </Stack>
          </DialogContent>
          <AuthModalFooter
            buttonText={'Activate'}
            alert={activateForm.alert}
            isValid={activateForm.isValid}
            dirty={activateForm.dirty}
            isSubmitting={activateForm.isSubmitting}
          />
        </AuthModalForm>
      )}
      {activeTab === 2 && (
        <AuthModalForm onSubmit={forgotForm.handleSubmit}>
          <AuthModalHeader
            setIsDialogOpen={setIsDialogOpen}
            title={'Forgot Password'}
          />
          <DialogContent>
            <Stack paddingTop={1} gap={'1rem'}>
              <Typography variant='body1' align='center'>
                If your email is associated with an active account, an email
                will be sent to reset your password.
              </Typography>
              <TextField
                id='email'
                name='email'
                label='Email Address'
                size='small'
                {...forgotForm.fieldProps('email')}
              />
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Button
                  onClick={() => setActiveTab(0)}
                  sx={{ paddingInline: 0 }}
                  variant={'text'}
                >
                  Login
                </Button>
                <DialogContentText>
                  Have a reset code?
                  <Button onClick={() => setActiveTab(3)}>Reset</Button>
                </DialogContentText>
              </Stack>
            </Stack>
          </DialogContent>
          <AuthModalFooter
            buttonText={'Send'}
            alert={forgotForm.alert}
            isValid={forgotForm.isValid}
            dirty={forgotForm.dirty}
            isSubmitting={forgotForm.isSubmitting}
          />
        </AuthModalForm>
      )}
      {activeTab === 3 && (
        <AuthModalForm onSubmit={resetForm.handleSubmit}>
          <AuthModalHeader
            setIsDialogOpen={setIsDialogOpen}
            title={'Reset Password'}
          />

          <DialogContent>
            <Stack paddingTop={1} gap={'1rem'}>
              <TextField
                id='email'
                name='email'
                label='Email Address'
                size='small'
                {...resetForm.fieldProps('email')}
              />
              <TextField
                id='resetCode'
                name='resetCode'
                label='Reset Code'
                size='small'
                {...resetForm.fieldProps('resetCode')}
              />
              <TextField
                id='password'
                name='password'
                label='New Password'
                type='password'
                size='small'
                autoComplete='new-password'
                {...resetForm.fieldProps('password')}
              />
              <TextField
                id='verifyPassword'
                name='verifyPassword'
                label='Re-enter Password'
                type='password'
                size='small'
                autoComplete='new-password'
                {...resetForm.fieldProps('verifyPassword')}
              />
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Button
                  onClick={() => setActiveTab(0)}
                  sx={{ paddingInline: 0 }}
                  variant={'text'}
                >
                  Login
                </Button>
                <Button onClick={() => setActiveTab(2)}>Forgot Password</Button>
              </Stack>
            </Stack>
          </DialogContent>
          <AuthModalFooter
            buttonText={'Reset'}
            alert={resetForm.alert}
            isValid={resetForm.isValid}
            dirty={resetForm.dirty}
            isSubmitting={resetForm.isSubmitting}
          />
        </AuthModalForm>
      )}
    </Dialog>
  );
}
