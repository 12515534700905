import { useLocation } from "react-router-dom";

// Custom hook to check if a page is active based on the current location
const useActivePage = () => {
  const location = useLocation(); // Get the current location
  const isActive = (pathname) => location.pathname.startsWith(pathname); // Check if the page is active

  return { isActive }; // Return the isActive function
};


export default useActivePage;
