import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import {Jurisdictions} from "../utils/adminPanelDataConfigs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function AddNewUser({ isDialogOpen, setIsDialogOpen }) {
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab state with 0 to show the first tab by default
  const [userType, setUserType] = useState(""); // Initialize userType state with an empty string
  const [jurisdictions, setJurisdictions] = useState(""); // Initialize jurisdictions state with an empty string
  // Initialize standardUserDetails state with an object containing the standard user details
  const [standardUserDetails, setStandardUserDetails] = useState({ 
    firstName: "",
    lastName: "",
    username: "",
    emailAddress: "",
    password: "", 
  });
  // Initialize fstDetails state with an object containing the FST user details
  const [fstDetails, setFstDetails] = useState({
    fstNumber: "",
    cellNumber: "",
    startingAddress: "",
    depotName: "",
    caseThreshold: "",
  });
  const [selectedFile, setSelectedFile] = useState(null); // Initialize selectedFile state with null
  const [isLoading, setIsLoading] = useState(false); // Initialize isLoading state with false
  const [error, setError] = useState(""); // Initialize error state with an empty string
  const usersEndpoint = process.env.REACT_APP_ENDPOINT_USERS; // Define the usersEndpoint using the REACT_APP_ENDPOINT_USERS environment variable
  
// Define event handlers to handle changes in the form fields and tabs
const handleJurisdictionsChange = (event) => {
  setJurisdictions(event.target.value);
  console.log("Jurisdictions:", event.target.value);
};

// Define event handlers to handle changes in the form fields and tabs 
const handleTabChange = (event, newValue) => {
  setActiveTab(newValue);
  console.log("Active Tab:", newValue);
};

// Define event handlers to handle changes in the form fields and tabs
const handleUserTypeChange = (event) => {
  setUserType(event.target.value);
  console.log("User Type:", event.target.value);
};

// Define event handlers to handle changes in the form fields and tabs
const handleStandardUserDetailChange = (prop) => (event) => {
  setStandardUserDetails({
    ...standardUserDetails, // Spread the existing standardUserDetails object
    [prop]: event.target.value,  // Update the property with the new value
  });
  console.log(`${prop}:`, event.target.value);
};

// Define event handlers to handle changes in the form fields and tabs
const handleFstDetailChange = (prop) => (event) => {
  setFstDetails({ ...fstDetails, [prop]: event.target.value }); // Spread the existing fstDetails object and update the property with the new value
  console.log(`${prop}:`, event.target.value); // Log the property and value to the console
};

// Define event handlers to handle changes in the form fields and tabs
const handleFileChange = (event) => {
  setSelectedFile(event.target.files[0]); // Set the selectedFile state with the selected file
  console.log("Selected File:", event.target.files[0]);
};

// Define event handlers to handle changes in the form fields and tabs
const handleImport = () => {
  if (selectedFile) {
    const formData = new FormData();
    formData.append("file", selectedFile);
    console.log("FormData:", formData);

    setIsLoading(true);
    setError("");
    // Make a POST request to the /users/import-users endpoint to import users using the selected file
    axios
      .post(`${usersEndpoint}/import-users`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setIsDialogOpen(false);
        alert("Users imported successfully");
      })
      .catch((error) => {
        setError("Error importing users: " + error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
};

// Define event handlers to handle changes in the form fields and tabs
const handleSaveUser = () => {
  setIsLoading(true);
  setError("");

  // Define userData for standard users
  let userData = {
    firstName: standardUserDetails.firstName,
    lastName: standardUserDetails.lastName,
    username: standardUserDetails.username,
    email: standardUserDetails.emailAddress,
    jurisdictions: jurisdictions,
    userType: userType,
    password: standardUserDetails.password,
  };
  console.log("User Data:", userData);

  if (userType === "FST") {
    // Define userData for FST users with additional fields
    userData = {
      ...userData,
      fstNumber: fstDetails.fstNumber,
      cellNumber: fstDetails.cellNumber,
      startingAddress: fstDetails.startingAddress,
      depotName: fstDetails.depotName,
      caseThreshold: fstDetails.caseThreshold,
    };
  }

  axios
    .post(`${usersEndpoint}/create`, userData)
    .then((response) => {
      setIsDialogOpen(false);
      alert("User created successfully");
    })
    .catch((error) => {
      setError("Error creating user: " + error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

  
  const handleDownloadTemplate = () => {
    const csvHeaders = [
      "userName",
      "firstName",
      "lastName",
      "email",
      "password",
      "ssoEnabled",
      "userType",
    ];

    const csvData = [csvHeaders.join(",")]; // Create a CSV template with the headers
    const csvTemplate = csvData.join("\n"); // Join the CSV data with a newline character
    const blob = new Blob([csvTemplate], { type: "text/csv" }); // Create a new Blob with the CSV template data
    const a = document.createElement("a"); // Create a new anchor element
    a.href = window.URL.createObjectURL(blob); // Set the href attribute of the anchor element with the URL of the Blob
    a.download = "user_template.csv"; // Set the download attribute of the anchor element with the filename
    a.style.display = "none"; // Hide the anchor element
    document.body.appendChild(a); // Append the anchor element to the document body
    a.click(); // Simulate a click event on the anchor element
    document.body.removeChild(a); // Remove the anchor element from the document body
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      fullWidth
    >
      <DialogTitle>Add New User</DialogTitle>
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="Link" />
        <Tab label="Person" />
        <Tab label="Import" />
      </Tabs>
      <DialogContent>
        <TabPanel value={activeTab} index={0}>
          <Typography variant="body1">Copy the invite link here:</Typography>
          <TextField
            fullWidth
            margin="normal"
            value="https://example.com/invite-link"
            InputProps={{ readOnly: true }}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                fullWidth
                value={standardUserDetails.firstName}
                onChange={handleStandardUserDetailChange("firstName")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                fullWidth
                value={standardUserDetails.lastName}
                onChange={handleStandardUserDetailChange("lastName")}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                label="Username"
                fullWidth
                value={standardUserDetails.userName}
                onChange={handleStandardUserDetailChange("username")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                fullWidth
                value={standardUserDetails.email}
                onChange={handleStandardUserDetailChange("emailAddress")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Password"
                fullWidth
                value={standardUserDetails.password}
                onChange={handleStandardUserDetailChange("password")}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="jurisdictions">Jurisdictions</InputLabel>
                <Select
                  id="jurisdictions"
                  value={standardUserDetails.jurisdictions}
                  onChange={handleJurisdictionsChange}
                >
                  <MenuItem value="">Select Jurisdictions</MenuItem>
                  {Jurisdictions.map((jurisdictions, index) => (
                    <MenuItem key={index} value={jurisdictions}>
                      {jurisdictions}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>User Type</InputLabel>
                <Select value={userType} onChange={handleUserTypeChange}>
                  <MenuItem value="Standard User">Standard User</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="FST">FST</MenuItem>
                  <MenuItem value="Associate">Associate</MenuItem>
                  <MenuItem value="Third Party">Third Party</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {userType === "FST" && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="FST Number"
                      fullWidth
                      value={fstDetails.fstNumber}
                      onChange={handleFstDetailChange("fstNumber")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Cell Number"
                      fullWidth
                      value={fstDetails.cellNumber}
                      onChange={handleFstDetailChange("cellNumber")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Starting Address"
                      fullWidth
                      value={fstDetails.startingAddress}
                      onChange={handleFstDetailChange("startingAddress")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Depot Name"
                      fullWidth
                      value={fstDetails.depotName}
                      onChange={handleFstDetailChange("depotName")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Case Threshold"
                      fullWidth
                      value={fstDetails.caseThreshold}
                      onChange={handleFstDetailChange("caseThreshold")}
                    />
                  </Grid>
                </>
              </Grid>
            )}
          </Grid>
          <Button
            onClick={handleSaveUser}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Save"
            )}
          </Button>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Typography variant="body1">
            Choose a CSV file to import users:
          </Typography>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="import-file-input"
          />
          <label htmlFor="import-file-input">
            <Button
              component="span"
              variant="outlined"
              color="primary"
              sx={{ mt: 2 }}
            >
              Choose File
            </Button>
            {selectedFile && (
              <span style={{ marginLeft: "8px" }}>{selectedFile.name}</span>
            )}
          </label>
          <Button
            onClick={handleImport}
            variant="contained"
            color="primary"
            sx={{ mt: 2, ml: 2 }}
            disabled={isLoading || !selectedFile}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Import"
            )}
          </Button>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Download CSV Template:
            <Button
              onClick={handleDownloadTemplate}
              color="primary"
              sx={{ ml: 2 }}
            >
              Download
            </Button>
          </Typography>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsDialogOpen(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
