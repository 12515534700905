import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Typography,
  Box,
  Tabs,
  Tab,
  Paper,
  TextField,
  IconButton,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from '@mui/material';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import DataGridComponent from './datagrid';
import NavigationBar from './navigationBar';
import BreadcrumbToolbar from './breadcrumbToolbar';

const adminEndpoint = process.env.REACT_APP_ENDPOINT_ADMIN;

const conditions = [
  'Path Starts With',
  'Path Ends With',
  'Path Is Exactly',
  'Path Matches Regex',
];

const GroupDetails = ({ group }) => {
  const [tabValue, setTabValue] = useState(0);
  const [groupDetails, setGroupDetails] = useState(group);
  const [rules, setRules] = useState([]); // Rules for paths
  const [loadingPaths, setLoadingPaths] = useState(true);
  const [users, setUsers] = useState([]); // Users in the group
  const [selectedUsers, setSelectedUsers] = useState([]); // Selected users for deletion
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false); // Dialog for adding users
  const [searchResults, setSearchResults] = useState([]); // Store search results for user search
  const [searchQuery, setSearchQuery] = useState(''); // Store search query input
  const [isSearching, setIsSearching] = useState(false); // Loading state for search

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Fetch paths and users assigned to the group
  useEffect(() => {
    const fetchGroupData = async () => {
      if (group && group.groupId) {
        try {
          const groupPathsRes = await fetch(
            `${adminEndpoint}/groupPaths?groupId=${group.groupId}`
          );
          const groupPathsData = await groupPathsRes.json();
          const mappedPaths = groupPathsData.map((path) => ({
            condition: 'Path Starts With',
            path: path.path,
          }));
          setRules(mappedPaths);
        } catch (error) {
          console.error('Error fetching paths:', error);
        } finally {
          setLoadingPaths(false);
        }
      }
    };

    const fetchUsers = async () => {
      if (group && group.groupId) {
        try {
          const usersRes = await fetch(
            `${adminEndpoint}/groupUsers?groupId=${group.groupId}`
          );
          const usersData = await usersRes.json();
          setUsers(usersData);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      }
    };

    fetchGroupData();
    fetchUsers();
  }, [group]);

  // Debounced search function for users
  const debouncedSearchUsers = useCallback(
    debounce(async (query) => {
      setIsSearching(true);
      try {
        const res = await fetch(
          `${adminEndpoint}/searchUsers?query=${encodeURIComponent(query)}`
        );
        const data = await res.json();
        setSearchResults(data); // Set search results
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setIsSearching(false);
      }
    }, 500),
    []
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.trim() === '') {
      setSearchResults([]);
      return;
    }
    debouncedSearchUsers(e.target.value); // Trigger debounced search
  };

  // Add a new rule
  const addRule = () => {
    setRules([...rules, { condition: 'Path Starts With', path: '' }]);
  };

  // Remove a rule
  const removeRule = (index) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    setRules(newRules);
  };

  // Handle condition change
  const handleConditionChange = (index, newCondition) => {
    const newRules = [...rules];
    newRules[index].condition = newCondition;
    setRules(newRules);
  };

  // Handle path change
  const handlePathChange = (index, newPath) => {
    const newRules = [...rules];
    newRules[index].path = newPath;
    setRules(newRules);
  };

  // Save updated paths
  const savePaths = async () => {
    try {
      await fetch(`${adminEndpoint}/groupPaths`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          groupId: group.groupId,
          paths: rules.map((r) => r.path),
        }),
      });
    } catch (error) {
      console.error('Error saving paths:', error);
    }
  };

  // Open dialog for adding user
  const handleOpenAddUserDialog = () => {
    setOpenAddUserDialog(true);
  };

  // Close dialog for adding user
  const handleCloseAddUserDialog = () => {
    setOpenAddUserDialog(false);
    setSearchResults([]);
    setSearchQuery('');
  };

  // Handle adding a new user
  const handleAddUser = async (userId) => {
    try {
      await fetch(`${adminEndpoint}/groupUsers`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, groupId: group.groupId }),
      });
      const usersRes = await fetch(
        `${adminEndpoint}/groupUsers?groupId=${group.groupId}`
      );
      const usersData = await usersRes.json();
      setUsers(usersData);
      handleCloseAddUserDialog();
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  // Handle deleting selected users
  const handleDeleteUsers = async () => {
    try {
      for (const userId of selectedUsers) {
        await fetch(`${adminEndpoint}/groupUsers`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId, groupId: group.groupId }),
        });
      }
      const usersRes = await fetch(
        `${adminEndpoint}/groupUsers?groupId=${group.groupId}`
      );
      const usersData = await usersRes.json();
      setUsers(usersData);
      setSelectedUsers([]);
    } catch (error) {
      console.error('Error deleting users:', error);
    }
  };

  const handleRowSelection = (selectedIds) => {
    setSelectedUsers(selectedIds);
  };

  const userColumns = [
    { field: 'userId', headerName: 'User ID', flex: 1 },
    { field: 'displayName', headerName: 'Display Name', flex: 1 },
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 },
  ];

  console.log(users);
  return (
    <>
      <NavigationBar />
      <BreadcrumbToolbar />
      <Box padding={3}>
        <Typography variant='h4' gutterBottom>
          Manage Group: {group.groupName}
        </Typography>

        <Paper square elevation={3} sx={{ marginBottom: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor='primary'
            textColor='primary'
          >
            <Tab label='Settings' />
            <Tab label='Page Rules' />
            <Tab label='Users' />
          </Tabs>
        </Paper>

        <Box padding={3}>
          {/* Settings Tab */}
          {tabValue === 0 && (
            <Box mb={3}>
              <Typography variant='h6' gutterBottom>
                Group Settings
              </Typography>
              <TextField
                label='Group Name'
                name='groupName'
                value={groupDetails.groupName}
                onChange={(e) =>
                  setGroupDetails({
                    ...groupDetails,
                    groupName: e.target.value,
                  })
                }
                fullWidth
                margin='normal'
              />
              <TextField
                label='Description'
                name='description'
                value={groupDetails.description}
                onChange={(e) =>
                  setGroupDetails({
                    ...groupDetails,
                    description: e.target.value,
                  })
                }
                fullWidth
                margin='normal'
              />
              <Button
                variant='contained'
                color='primary'
                onClick={savePaths}
                sx={{ marginTop: 2 }}
              >
                Save Changes
              </Button>
            </Box>
          )}

          {/* Page Rules Tab */}
          {tabValue === 1 && (
            <>
              <Typography variant='h6' gutterBottom>
                Page Rules for {group.groupName}
              </Typography>

              {loadingPaths ? (
                <Box display='flex' justifyContent='center' padding={2}>
                  <CircularProgress />
                </Box>
              ) : (
                rules.map((rule, index) => (
                  <Paper
                    key={index}
                    elevation={2}
                    sx={{ padding: 2, marginBottom: 2 }}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Box mx={2} flexGrow={1}>
                        <TextField
                          select
                          label='Condition'
                          value={rule.condition}
                          onChange={(e) =>
                            handleConditionChange(index, e.target.value)
                          }
                          fullWidth
                        >
                          {conditions.map((condition) => (
                            <MenuItem key={condition} value={condition}>
                              {condition}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>

                      <Box flexGrow={1} mx={2}>
                        <TextField
                          label='Path'
                          value={rule.path}
                          onChange={(e) =>
                            handlePathChange(index, e.target.value)
                          }
                          fullWidth
                        />
                      </Box>

                      <IconButton
                        onClick={() => removeRule(index)}
                        color='error'
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Paper>
                ))
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'column',
                  float: 'right',
                }}
              >
                <Button
                  variant='outlined'
                  startIcon={<AddIcon />}
                  onClick={addRule}
                  sx={{ marginTop: 2 }}
                >
                  Add Rule
                </Button>

                <Button
                  variant='contained'
                  startIcon={<SaveIcon />}
                  color='primary'
                  onClick={savePaths}
                  sx={{ marginTop: 2 }}
                >
                  Save Rules
                </Button>
              </Box>
            </>
          )}

          {/* Users Tab */}
          {tabValue === 2 && (
            <>
              <Typography variant='h6' gutterBottom>
                Users in {group.groupName}
              </Typography>

              <Box mb={2} display='flex' justifyContent='space-between'>
                <Button
                  variant='outlined'
                  startIcon={<AddIcon />}
                  onClick={handleOpenAddUserDialog}
                >
                  Add User
                </Button>

                <Button
                  variant='contained'
                  color='error'
                  startIcon={<DeleteIcon />}
                  onClick={handleDeleteUsers}
                  disabled={selectedUsers.length === 0}
                >
                  Delete Selected
                </Button>
              </Box>
              <DataGridComponent
                apiEndpoint={`${adminEndpoint}/groupUsers?groupId=${group.groupId}`}
                columns={userColumns}
                toggleIcons={false}
                uploadButton={false}
                onRowSelection={handleRowSelection}
                enableCheckboxSelection={true}
                getRowId={(row) => row.userId} // This tells MUI to use 'userId' as the row's unique ID
              />
            </>
          )}
        </Box>
      </Box>

      {/* Add User Dialog */}
      <Dialog
        open={openAddUserDialog}
        onClose={handleCloseAddUserDialog}
        aria-labelledby='add-user-dialog-title'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle id='add-user-dialog-title'>Add User</DialogTitle>
        <DialogContent>
          <TextField
            label='Search Users'
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
            helperText='Search by userId, first name, last name, or email'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ marginBottom: 2 }}
          />

          {isSearching ? (
            <Box display='flex' justifyContent='center' mt={2}>
              <CircularProgress />
            </Box>
          ) : searchResults.length === 0 ? (
            <Typography variant='body2' color='textSecondary' align='center'>
              No users found.
            </Typography>
          ) : (
            <Box sx={{ maxHeight: 250, overflowY: 'auto' }}>
              <List>
                {searchResults.map((user) => (
                  <ListItem
                    button
                    key={user.userId}
                    onClick={() => handleAddUser(user.userId)}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgba(0, 123, 255, 0.1)',
                      },
                    }}
                  >
                    <ListItemText
                      primary={`${user.firstName} ${user.lastName}`}
                      secondary={user.email}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddUserDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GroupDetails;
