import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import axios from 'axios';

const AccessGroups = ({ userId }) => {
  const [groups, setGroups] = useState([]); // Initialize state with an empty array of groups
  const [loading, setLoading] = useState(true); // Initialize state with loading set to true to show a loading spinner while fetching data

  // Fetch access groups for the user when the component mounts using the useEffect hook 
  useEffect(() => {
    // Function to fetch access groups for the user using the userId prop
    const fetchAccessGroups = async () => {
      // Make a GET request to the /users/:userId/groups endpoint to fetch access groups for the user
      try {
        const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/${userId}/groups`, {
          withCredentials: true, // Ensure cookies are sent with the request
        });
        setGroups(response.data); // Set the groups state with the data received from the API response
        setLoading(false); // Set loading to false to hide the loading spinner
      } catch (error) {
        console.error('Error fetching access groups:', error);
        setLoading(false);
      }
    };
    // Call the fetchAccessGroups function when the component mounts
    fetchAccessGroups();
  }, [userId]);
  // The useEffect hook will run the fetchAccessGroups function when the component mounts and whenever the userId prop changes
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (groups.length === 0) {
    return <Typography variant="h6">No access groups found</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>Access Groups</Typography>
      <List>
        {groups.map((group, index) => (
          <ListItem key={index}>
            <ListItemText primary={group} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AccessGroups;
