import React, { useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import Logo from "../../assets/GNIECloud-Logo-500.png";
import SSO from "../../assets/ms-symbollockup_signin_light.svg";
import { signIn } from "../../services/sso/authPopup";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../../context/authContext";
import AuthModal from "../../components/authModal";
import { useSearchParams } from "react-router-dom";

const Login = () => {
  const { login } = useAuth();
  const [urlParams, setUrlParams] = useSearchParams();
  const navigate = useNavigate();
  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(
    urlParams.has("email") &&
      (urlParams.has("inviteCode") || urlParams.has("resetCode"))
  );
  const [error, setError] = useState("");

  const handleSSOClick = async () => {
    try {
      const userDetails = await signIn(); // Authenticate and fetch user details
      if (userDetails) {
        login(userDetails, "SSO"); // Update context with user details, including group names
        navigate("/home"); // Navigate to home on success
      }
    } catch (error) {
      console.error("Error during SSO login:", error);
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundImage:
          "linear-gradient(-45deg, rgba(12, 81, 161, 0.8), rgba(255, 103, 31, 0.8), rgba(12, 81, 161, 0.8), rgba(255, 103, 31, 0.8))",
        backgroundSize: "400% 400%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <style>{`
        @keyframes gradient {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
      `}</style>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={Logo}
          alt='GNIECloud Logo'
          style={{ height: "100px", marginRight: "10px" }}
        />
        <Typography
          variant='h2'
          sx={{
            fontWeight: "bold",
            color: "#0c51a1",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: "bold", color: "#0C51A1" }}>ENGAGE</span>{" "}
          {/* <span style={{ fontWeight: "bold", color: "#ff571f" }}>Cloud</span> */}
        </Typography>
      </Box>

      <Button
        onClick={handleSSOClick}
        sx={{ textTransform: "none", padding: 0, mt: 2 }}
      >
        <img
          src={SSO}
          alt='Sign In With Microsoft'
          style={{ height: "30px" }}
        />
      </Button>
      {/* <Button
        onClick={() => setIsAuthDialogOpen(true)}
        variant='contained'
        sx={{ mt: 2 }}
      >
        Local Login
      </Button> */}
      <Typography
        sx={{ fontSize: "0.75rem", color: "#c0c2c4", marginTop: "15px" }}
      >
        Copyright ©​{new Date().getFullYear()} IGT. All Rights Reserved.
      </Typography>
      <AuthModal
        isDialogOpen={isAuthDialogOpen}
        setIsDialogOpen={setIsAuthDialogOpen}
        email={urlParams.get("email")}
        inviteCode={urlParams.get("inviteCode")}
        resetCode={urlParams.get("resetCode")}
      />
    </div>
  );
};

export default Login;
