import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

// Define group to accessible paths mapping
const groupPathAccess = {
  engage_administrators: [
    '/forms',
    '/home',
    '/login',
    '/reports',
    '/contact-center',
    '/rss/links',
    '/admin',
    '/admin/users/management',
    '/admin/users/invites',
    '/admin/users/:userId',
    '/admin/groups',
    '/files',
  ],
  engage_us_cc: [
    '/forms',
    '/home',
    '/login',
    '/contact-center',
    '/rss/links',
    '/files',
  ],
  engage_us_cc_tn: ['/forms', '/home', '/login', '/files'],
  engage_us_fst: ['/forms', '/home', '/login', '/files'],
  engage_us_report: ['/home', '/login', '/reports', '/files'],
  engage_us_inv: ['/home', '/login', '/reports', '/forms', '/files'],
  engage_us_rlt: ['/home', '/login', '/forms', '/files'],
  engage_us_rrf: ['/home', '/login', '/forms', '/files', '/reports'],
};

// Create authentication context
const AuthContext = createContext();

// Custom hook to use authentication context
export const useAuth = () => useContext(AuthContext);

// Authentication provider component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = Cookies.get('token') || Cookies.get('localToken');
    return !!token;
  });
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [accessiblePaths, setAccessiblePaths] = useState([]);

  // Function to get the access token from cookies (check both SSO and local tokens)
  const getToken = () => Cookies.get('token') || Cookies.get('localToken');

  // Function to log in the user and set the authentication state
  const login = (userDetails, authType = 'localAuth') => {
    // Check if the login is for SSO or Local based on the authType parameter
    if (authType === 'SSO') {
      Cookies.set('token', userDetails.token); // Set SSO token
    } else if (authType === 'localAuth') {
      Cookies.set('localToken', userDetails.token); // Set local auth token
    }

    setUserId(userDetails.userId);
    setIsAuthenticated(true);

    // Normalize group names and filter
    const filteredGroups = userDetails.groups.filter(group =>
      group.toLowerCase().startsWith('engage_')
    );

    const normalizedGroupPathAccess = Object.keys(groupPathAccess).reduce(
      (acc, key) => {
        acc[key.toLowerCase()] = groupPathAccess[key];
        return acc;
      },
      {}
    );

    // Get accessible paths for the user based on their groups
    const userPaths = filteredGroups
      .flatMap(group => normalizedGroupPathAccess[group.toLowerCase()] || [])
      .filter((value, index, self) => self.indexOf(value) === index);

    setUserGroups(filteredGroups);
    setAccessiblePaths(userPaths);

    // Persist user groups in local storage
    localStorage.setItem('userGroups', JSON.stringify(filteredGroups));
  };

  // Function to log out the user and clear the authentication state and cookies
  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('localToken');
    setUserId('');
    setIsAuthenticated(false);
    setUserGroups([]);
    setAccessiblePaths([]);
    localStorage.removeItem('userGroups');
  };

  // Check if the user is authenticated on component mount
  useEffect(() => {
    const storedGroups = JSON.parse(localStorage.getItem('userGroups') || '[]');

    if (storedGroups.length > 0) {
      setUserGroups(storedGroups);
      const normalizedGroupPathAccess = Object.keys(groupPathAccess).reduce(
        (acc, key) => {
          acc[key.toLowerCase()] = groupPathAccess[key];
          return acc;
        },
        {}
      );

      // Get accessible paths for the user based on their stored groups
      const paths = storedGroups
        .flatMap(group => normalizedGroupPathAccess[group.toLowerCase()] || [])
        .filter((value, index, self) => self.indexOf(value) === index);

      setAccessiblePaths(paths);
    } else {
      setAccessiblePaths([]);
    }

    // Check if the user is authenticated based on the presence of either token
    setIsAuthenticated(!!Cookies.get('token') || !!Cookies.get('localToken'));
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userId,
        getToken,
        login,
        logout,
        userGroups,
        accessiblePaths,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
