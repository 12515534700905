import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import {
  Tooltip,
  LinearProgress,
  Snackbar,
  Typography,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import Cookies from 'js-cookie';
import {
  ToggleOffIcon,
  ToggleOnIcon,
  FileUploadIcon,
  ContentCopyIcon,
  CloudDownloadIcon,
  DeleteForeverIcon,
  VisibilityIcon,
  InfoIcon,
} from '../assets/icons/icons';
import { formStyles } from '../styles/formStyles';
import EditableModal from './datagridEditableModal';
import { ActionCell } from '../utils/reportConfigs';
import FileInfoModal from './fileInformationModal';
import FilesUploadComponent from './filesModal';
import PreviewModal from './previewModal';
import { LanguageContext } from '../context/languageContext';

const DataGridComponent = ({
  apiEndpoint,
  columns,
  uploadButton,
  toggleIcons,
  enableCheckboxSelection,
}) => {
  const [data, setData] = useState({ rows: [] });
  const [loading, setLoading] = useState(true);
  const [showIcons, setShowIcons] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [articleID, setArticleID] = useState('');
  const [userName, setUserName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  // Initialize state with snackbarConfig set to an object with open, message, and severity properties
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [fileInfoOpen, setFileInfoOpen] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const translations = useContext(LanguageContext); // Get the translations from the LanguageContext
  // useEffect hook to fetch data from the API endpoint when the component mounts
  // useEffect hook to fetch data from the API endpoint when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      // Make a GET request to the API endpoint to fetch data
      // Make a GET request to the API endpoint to fetch data
      try {
        const response = await axios.get(apiEndpoint);
        setData({ rows: response.data || [] }); // Set the data state with the data received from the API response or an empty array if no data is received
        setData({ rows: response.data || [] }); // Set the data state with the data received from the API response or an empty array if no data is received
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData(); // Call the fetchData function when the component mounts
    fetchUserDetails(); // Call the fetchUserDetails function when the component mounts
    fetchData(); // Call the fetchData function when the component mounts
    fetchUserDetails(); // Call the fetchUserDetails function when the component mounts
  }, [apiEndpoint]);

  const fetchUserDetails = async () => {
    const usersEndpoint = process.env.REACT_APP_ENDPOINT_USERS;
    const token = Cookies.get('token');
    if (!token) {
      console.error('Access token is not available.');
      return;
    }

    try {
      // Set the Authorization header with the access token to authenticate the request
      // Set the Authorization header with the access token to authenticate the request
      const response = await axios.get(`${usersEndpoint}/user-details`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        setUserDetails(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
      setUserDetails({});
    }
  };

  const handleShow = (id) => {
    setShow(true);
    setRowId(id);
    const selectedData = data.rows.find((row) => row.id === id);
    setSelectedRowData(selectedData);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedRowData(null);
  };

  const editApiEndpoint = process.env.REACT_APP_ENDPOINT_REPORTING;

  const handleEdit = async (event) => {
    event.preventDefault();
    const modifiedBy = userDetails?.displayName || 'Unknown User';
    const modified = new Date().toISOString().slice(0, 19).replace('T', ' ');
    const dataToUpdate = {
      ...selectedRowData,
      modifiedBy,
      modified,
    };

    try {
      setLoading(true);
      await axios.put(
        `${editApiEndpoint}/fst/schedule/edit/${rowId}`,
        dataToUpdate
      );
      setLoading(false);
      // Show a success snackbar message and refresh the page automatically after 3 seconds
      // Show a success snackbar message and refresh the page automatically after 3 seconds
      setSnackbarConfig({
        open: true,
        message: 'Update successful. Page will refresh automatically.',
        severity: 'success',
      });
      setIsSubmitting(false);
      setTimeout(() => window.location.reload(), 3000);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  // Function to handle the close event for the snackbar
  // Function to handle the close event for the snackbar
  const handleCloseSnackbar = () => {
    setSnackbarConfig((prev) => ({ ...prev, open: false }));
  };

  // Function to toggle the visibility of the icons in the data grid
  // Function to toggle the visibility of the icons in the data grid
  const handleUploadClick = () => {
    setOpen(true);
  };

  // Add a helper function to check if a valid token exists
  const isAuthenticated = () => {
    const token = Cookies.get('token');
    return !!token; // Return true if token exists, otherwise false
  };

  // Modify the file-related functions to check for the token
  const copyFileLink = (row) => {
    if (!isAuthenticated()) {
      setSnackbarConfig({
        open: true,
        message: 'You must be authenticated to copy the file link.',
        severity: 'error',
      });
      return;
    }

    const fileLink = row.link; // Get the file link from the row data
    const textArea = document.createElement('textarea'); // Create a textarea element
    textArea.style.position = 'fixed'; // Set the position to fixed
    textArea.style.opacity = 0; // Set the opacity to 0
    textArea.value = fileLink; // Set the value of the textarea to the file link
    document.body.appendChild(textArea); // Append the textarea to the document body
    textArea.select(); // Select the text in the textarea
    document.execCommand('copy'); // Copy the selected text to the clipboard
    document.body.removeChild(textArea); // Remove the textarea from the document body

    setSnackbarConfig({
      open: true,
      message: 'File link copied to clipboard.',
      severity: 'success',
    });
  };

  const handleDownload = (row) => {
    if (!isAuthenticated()) {
      setSnackbarConfig({
        open: true,
        message: 'You must be authenticated to download the file.',
        severity: 'error',
      });
      return;
    }

    const fileLink = row.link;
    window.open(fileLink, '_blank'); // Open the file link in a new tab
    setSnackbarConfig({
      open: true,
      message: 'File downloaded successfully.',
      severity: 'success',
    });
  };

  const handleDelete = async (row) => {
    if (!isAuthenticated()) {
      setSnackbarConfig({
        open: true,
        message: 'You must be authenticated to delete the file.',
        severity: 'error',
      });
      return;
    }

    const apiUrl = `${apiEndpoint}/${row.fileName}`;
    try {
      await axios.delete(apiUrl);
      const updatedData = data.rows.filter((f) => f.id !== row.id);
      setData({ rows: updatedData });
      setSnackbarConfig({
        open: true,
        message: 'File deleted successfully.',
        severity: 'success',
      });
    } catch (error) {
      setSnackbarConfig({
        open: true,
        message: 'Error deleting file. Please try again.',
        severity: 'error',
      });
    }
  };

  //   const handlePreview = row => {
  //     setPreviewFile(row.link);
  //     setPreviewOpen(true);
  //   };

  // Function to handle the file upload event when the form is submitted with the selected file

  // Function to handle the file upload event when the form is submitted with the selected file
  const handleSubmit = async () => {
    if (selectedFile && articleID && userName) {
      const formData = new FormData();
      formData.append('file', selectedFile); // Append the selected file to the form data
      formData.append('articleID', articleID); // Append the article ID to the form data
      formData.append('userName', userName); // Append the user name to the form data
      const apiUrl = `${apiEndpoint}/upload`; // Construct the API endpoint URL for file upload
      // Make a POST request to the API endpoint to upload the file
      try {
        await axios.post(apiUrl, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setOpen(false);
        setSnackbarConfig({
          open: true,
          message: 'File uploaded successfully.',
          severity: 'success',
        });
      } catch (error) {
        setSnackbarConfig({
          open: true,
          message: 'Error uploading file. Please try again.',
          severity: 'error',
        });
      }
    } else {
      setSnackbarConfig({
        open: true,
        message: 'Please fill in all fields, including your name.',
        severity: 'error',
      });
    }
  };

  // Function to handle the file information event when the info icon is clicked
  // Function to handle the file information event when the info icon is clicked
  const handleFileInfo = (row) => {
    setFileInfo(row);
    setFileInfoOpen(true);
  };

  const CustomToolbar = ({ translations }) => (
    <GridToolbarContainer>
      {uploadButton && (
        <Button onClick={handleUploadClick}>
          <Typography variant='body2'>{translations.upload}</Typography>
          <FileUploadIcon sx={{ color: '#0c51a1', fontWeight: 'bold' }} />
        </Button>
      )}
      <GridToolbarExport sx={{ color: '#0c51a1', fontWeight: 'bold' }} />
      <GridToolbarColumnsButton sx={{ color: '#ffa600', fontWeight: 'bold' }} />
      <GridToolbarDensitySelector
        sx={{ color: '#1b9ddb', fontWeight: 'bold' }}
      />
    </GridToolbarContainer>
  );

  // Map through the columns array to add event handlers to specific columns based on the field value
  // Map through the columns array to add event handlers to specific columns based on the field value
  const columnsWithHandlers = columns.map((col) =>
    // Check if the field value of the column is 'functions' and add event handlers for copy, download, delete, preview, and file information
    // Check if the field value of the column is 'functions' and add event handlers for copy, download, delete, preview, and file information
    col.field === 'functions'
      ? {
          ...col,
          renderCell: (params) => (
            <Box display='flex' alignItems='center'>
              {/* Copy File Link */}
              <Tooltip title={translations.copyFileLink || 'Copy File Link'}>
                <IconButton
                  onClick={() => copyFileLink(params.row)}
                  size='small'
                >
                  <ContentCopyIcon
                    sx={{ color: '#ff671f', fontSize: '30px' }}
                  />
                </IconButton>
              </Tooltip>

              {/* Download File */}
              <Tooltip title={translations.downloadFile || 'Download File'}>
                <IconButton
                  onClick={() => handleDownload(params.row)}
                  size='small'
                >
                  <CloudDownloadIcon
                    sx={{ color: '#0c51a1', fontSize: '30px' }}
                  />
                </IconButton>
              </Tooltip>

              {/* Delete File */}
              <Tooltip title={translations.deleteFile || 'Delete File'}>
                <IconButton
                  onClick={() => handleDelete(params.row)}
                  size='small'
                  color='error'
                >
                  <DeleteForeverIcon sx={{ fontSize: '30px' }} />
                </IconButton>
              </Tooltip>

              {/* File Info */}
              <Tooltip
                title={translations.fileInformation || 'File Information'}
              >
                <IconButton
                  onClick={() => handleFileInfo(params.row)}
                  size='small'
                >
                  <InfoIcon sx={{ color: '#DADA00', fontSize: '30px' }} />
                </IconButton>
              </Tooltip>
            </Box>
          ),
        }
      : // Check if the field value of the column is 'Action' and add event handlers for the ActionCell component
      // Check if the field value of the column is 'Action' and add event handlers for the ActionCell component
      col.field === 'Action'
      ? {
          ...col,
          // Render the ActionCell component with event handlers for the Action column
          // Render the ActionCell component with event handlers for the Action column
          renderCell: (params) => (
            <ActionCell {...params} handleShow={handleShow} />
          ),
        }
      : // Check if the field value of the column is 'rrfGamAction' and add event handlers for the ActionCell component
      // Check if the field value of the column is 'rrfGamAction' and add event handlers for the ActionCell component
      col.field === 'rrfGamAction'
      ? {
          ...col,
          // Render the ActionCell component with event handlers for the rrfGamAction column
          // Render the ActionCell component with event handlers for the rrfGamAction column
          renderCell: (params) => (
            <ActionCell {...params} handleShow={handleShow} />
          ),
        }
      : // Check if the field value of the column is 'rrfLotAction' and add event handlers for the ActionCell component
      // Check if the field value of the column is 'rrfLotAction' and add event handlers for the ActionCell component
      col.field === 'rrfLotAction'
      ? {
          ...col,
          // Render the ActionCell component with event handlers for the rrfLotAction column
          // Render the ActionCell component with event handlers for the rrfLotAction column
          renderCell: (params) => (
            <ActionCell {...params} handleShow={handleShow} />
          ),
        }
      : col
  );

  return (
    <div style={{ height: 800, width: '100%' }}>
      {loading && <LinearProgress />}
      <DataGridPro
        rows={data.rows}
        columns={columnsWithHandlers}
        pageSize={5}
        pagination
        checkboxSelection={enableCheckboxSelection}
        slots={{ toolbar: CustomToolbar }}
        experimentalFeatures={{ newEditingApi: true }}
        getRowSpacing={(params) => ({
          top: params.isFirstVisible ? 0 : 2,
          bottom: params.isLastVisible ? 0 : 2,
        })}
        slotProps={{
          toolbar: { translations },
        }}
      />
      <Snackbar
        open={snackbarConfig.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarConfig.message}
        ContentProps={{
          style: {
            backgroundColor:
              snackbarConfig.severity === 'success' ? '#4CAF50' : '#f44336',
          },
        }}
        action={
          <Button color='inherit' size='small'>
            {translations.close || 'Close'}
          </Button>
        }
      />
      <EditableModal
        open={show}
        handleClose={handleClose}
        rowId={rowId}
        selectedRowData={selectedRowData}
        columns={columns}
        handleEdit={handleEdit}
        setSelectedRowData={setSelectedRowData}
        formStyles={formStyles}
      />
      <FilesUploadComponent
        open={open}
        onClose={() => setOpen(false)}
        articleID={articleID}
        setArticleID={setArticleID}
        userName={userName}
        setUserName={setUserName}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        handleSubmit={handleSubmit}
      />
      <PreviewModal
        open={previewOpen}
        handleClose={() => setPreviewOpen(false)}
        previewFile={previewFile}
      />
      <FileInfoModal
        open={fileInfoOpen}
        onClose={() => setFileInfoOpen(false)}
        file={fileInfo}
      />
    </div>
  );
};

export default DataGridComponent;
