import VentusSearch from '../components/comm/ventusSearch';
import HughesSearch from '../components/comm/hughesSearch';
import { LanguageContext } from '../context/languageContext';

export const utilities = (translations) =>[
  {
    tab: 'Ventus',
    items: [
      {
        title: 'Ventus',
        description: 'Ventus Devices',
        component: <VentusSearch />,
      },
    ],
  },
  {
    tab: 'Hughes',
    items: [
      {
        title: 'Hughes',
        description: 'Hughes',
        component: <HughesSearch />,
      },
    ],
  },
];
