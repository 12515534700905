import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
} from '@mui/material';
import GroupDetails from '../../components/groupDetails';
import { format } from 'date-fns';
import NavigationBar from '../../components/navigationBar';
import { EditIcon } from '../../assets/icons/icons';
import AdminSidebar from '../../components/administratorSidebar';
import { LanguageContext } from '../../context/languageContext';
import BreadcrumbToolbar from '../../components/breadcrumbToolbar';
const GroupsManagement = () => {
  // State for managing selected group and groups list
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const { translations, language, changeLanguage } =
    useContext(LanguageContext);

  const adminEndpoint = process.env.REACT_APP_ENDPOINT_ADMIN;

  // Fetch groups from the backend
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${adminEndpoint}/groups`); // Fetch groups from backend
        if (!response.ok) {
          throw new Error('Failed to fetch groups');
        }
        const data = await response.json();
        setGroups(data); // Set fetched groups to state
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchGroups();
  }, []);

  // Handle group row click
  const handleGroupClick = (group) => {
    setSelectedGroup(group);
  };

  // Display table if no group is selected
  if (selectedGroup) {
    return (
      <GroupDetails
        group={selectedGroup}
        onBack={() => setSelectedGroup(null)}
      />
    );
  }

  return (
    <>
      <NavigationBar />
      <BreadcrumbToolbar />
      <TableContainer component={Paper}>
        <Typography variant='h4' gutterBottom style={{ padding: '16px' }}>
          Groups Management
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Group Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <TableRow
                key={group.id}
                onClick={() => handleGroupClick(group)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{group.id}</TableCell>
                <TableCell>{group.groupName}</TableCell>
                <TableCell>{group.description}</TableCell>
                <TableCell>
                  {format(new Date(group.createdAt), 'yyyy-MM-dd')}
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleGroupClick(group)}>
                    <EditIcon
                      sx={{
                        color: 'primary',
                        fontSize: 20,
                      }}
                    />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GroupsManagement;
