import React, { useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
  Box
} from '@mui/material';
import { LanguageContext } from '../context/languageContext';

const FileInfoModal = ({ open, onClose, file }) => {
  const translations = useContext(LanguageContext);

  if (!file) return null;

  const formatDateTime = (dateTime) => {
    return dateTime ? new Date(dateTime).toLocaleString() : 'N/A';
  };

  const formatMetadata = (metadata) => {
    if (!metadata || Object.keys(metadata).length === 0) return 'None';
    return (
      <Box ml={2}>
        {Object.entries(metadata).map(([key, value]) => (
          <ListItem key={key} disableGutters>
            <ListItemText
              primary={key}
              secondary={value}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
        ))}
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{translations.fileInformation || 'File Information'}</DialogTitle>
      <DialogContent dividers>
        <List>
          <ListItem>
            <ListItemText
              primary={translations.fileName || 'File Name'}
              secondary={file.fileName}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.fileType || 'File Type'}
              secondary={file.fileType}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.createdBy || 'Created By'}
              secondary={file.createdBy || 'Unknown'}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.lastModified || 'Last Modified'}
              secondary={formatDateTime(file.lastModified)}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.creationTime || 'Creation Time'}
              secondary={formatDateTime(file.creationTime)}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.size || 'Size'}
              secondary={file.size || 'Unknown'}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.contentType || 'Content Type'}
              secondary={file.contentType || 'Unknown'}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.link || 'Link'}
              secondary={
                <Link href={file.link} target="_blank" rel="noopener noreferrer">
                  {file.link}
                </Link>
              }
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.etag || 'ETag'}
              secondary={file.etag || 'N/A'}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.serverEncrypted || 'Server Encrypted'}
              secondary={file.serverEncrypted ? 'Yes' : 'No'}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.metadata || 'Metadata'}
              secondary={formatMetadata(file.metadata)}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {translations.close || 'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileInfoModal;
