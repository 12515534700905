import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

// DropDown component with props for the items, selectedValue, onChange, and placeholder
const DropDown = ({ items, selectedValue, onChange, placeholder }) => (
  <FormControl fullWidth>
    <Select value={selectedValue} onChange={onChange} displayEmpty>
      <MenuItem value="" disabled>
        {placeholder}
      </MenuItem>
      {/* Map through the items array to generate dropdown items */}
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default DropDown;
