import {
  gamingFailureModes,
  lotteryFailureModes,
  gamingLocations,
  gamingPartNumbers,
  jids,
  pcbItemNumbers,
  powerSupplyItemNumbers,
  gamingProducts,
  lotteryProducts,
  quadBursterNumberItems,
  readerItemNumbers,
  solutionsGaming,
  solutions,
  status,
  billAcceptorNumberItems,
  peripherals,
  terminals,
  printers,
  commDevice,
  fstLotteryProducts,
  displayMonitor,
  incorrectStatus,
  actionTaken,
  secondTimeReturn,
  reasons,
  fstJids,
  jidsNRCForms,
  jidsNRCForms_NRCDowntime,
  jidsRetailerContactInfo,
  jidsConsumableSupply,
  consumableSupply_pack,
  consumableSupply_rolls,
  consumableSupply_unit,
  yesNoOptions,
} from './formDataConfigs';
import { formsNRCTime } from './timeConfigs';
import { LanguageContext } from '../context/languageContext';

// RRF Form Configs

export const lotteryRepairFormConfig = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/rrf/lot/repair/worklog/submit`,
  method: 'POST',
  fields: [
    {
      name: 'startDate',
      label: translations.startDate || 'Start Date',
      type: 'date',
      required: true,
    },
    {
      name: 'completionDate',
      label: translations.completionDate || 'Completion Date',
      type: 'date',
      required: true,
    },
    {
      name: 'emailAddress',
      label: translations.emailAddress || 'Email Address',
      type: 'text',
      required: true,
    },
    {
      name: 'displayName',
      label: translations.displayName || 'Display Name',
      type: 'text',
    },
    {
      name: 'rmaNumber',
      label: translations.rmaNumber || 'RMA Number',
      type: 'text',
    },
    {
      name: 'assetTag',
      label: translations.assetTag || 'Asset Tag',
      type: 'text',
    },
    {
      name: 'serialNumber',
      label: translations.serialNumber || 'Serial Number',
      type: 'text',
    },
    {
      name: 'failureMode',
      label: translations.failureMode || 'Failure Mode',
      type: 'select',
      options: lotteryFailureModes,
    },
    {
      name: 'solution',
      label: translations.solution1 || 'Solution #1',
      type: 'select',
      options: solutions,
    },
    {
      name: 'secondSolution',
      label: translations.solution2 || 'Solution #2',
      type: 'select',
      options: solutions,
    },
    {
      name: 'partsUsed',
      label: translations.partsUsed || 'Parts Used',
      type: 'text',
    },
    {
      name: 'status',
      label: translations.status || 'Status',
      type: 'select',
      options: status,
    },
    {
      name: 'workTime',
      label: translations.totalWorkTime || 'Total Work Time (minutes)',
      type: 'text',
    },
    {
      name: 'isSecondTimeReturn',
      label: translations.isSecondTimeReturn || 'Is Second Time Return',
      type: 'select',
      options: secondTimeReturn,
    },
    {
      name: 'returnDate',
      label: translations.returnDate || 'Return Date',
      type: 'date',
    },
    {
      name: 'jurisdiction',
      label: translations.jurisdiction || 'Jurisdiction',
      type: 'select',
      options: jids,
    },
    {
      name: 'product',
      label: translations.product || 'Product',
      type: 'select',
      options: lotteryProducts,
    },
    {
      name: 'reader',
      label: translations.readerItemNumber || 'Reader Item Number',
      type: 'select',
      options: readerItemNumbers,
    },
    {
      name: 'powerSupply',
      label: translations.powerSupplyItemNumber || 'Power Supply Item Number',
      type: 'select',
      options: powerSupplyItemNumbers,
    },
    {
      name: 'pcb',
      label: translations.pcbItemNumber || 'PCB Item Number',
      type: 'select',
      options: pcbItemNumbers,
    },
    {
      name: 'billAcceptor',
      label: translations.billAcceptorItemNumber || 'Bill Acceptor Item Number',
      type: 'select',
      options: billAcceptorNumberItems,
    },
    {
      name: 'burster',
      label: translations.bursterItemNumber || 'Mini/Quad Burster Item Number',
      type: 'select',
      options: quadBursterNumberItems,
    },
    {
      name: 'comments',
      label: translations.comments || 'Comments',
      type: 'text',
      multiline: true,
      rows: 4,
    },
  ],
});

export const gamingRepairFormConfig = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/rrf/gam/repair/worklog/submit`,
  method: 'POST',
  fields: [
    {
      name: 'startDate',
      label: translations.startDate || 'Start Date',
      type: 'date',
      required: true,
    },
    {
      name: 'completionDate',
      label: translations.completionDate || 'Completion Date',
      type: 'date',
      required: true,
    },
    {
      name: 'emailAddress',
      label: translations.emailAddress || 'Email Address',
      type: 'text',
      required: true,
    },
    {
      name: 'rmaNumber',
      label: translations.rmaNumber || 'RMA Number',
      type: 'text',
    },
    {
      name: 'assetTag',
      label: translations.assetTag || 'Asset Tag',
      type: 'text',
    },
    {
      name: 'serialNumber',
      label: translations.serialNumber || 'Serial Number',
      type: 'text',
    },
    {
      name: 'failureMode',
      label: translations.failureMode || 'Failure Mode',
      type: 'select',
      options: gamingFailureModes,
    },
    {
      name: 'solution',
      label: translations.solution1 || 'Solution',
      type: 'select',
      options: solutionsGaming,
    },
    {
      name: 'secondSolution',
      label: translations.solution2 || 'Solution #2',
      type: 'select',
      options: solutionsGaming,
    },
    {
      name: 'partsUsed',
      label: translations.partsUsed || 'Parts Used',
      type: 'text',
    },
    {
      name: 'status',
      label: translations.status || 'Status',
      type: 'select',
      options: status,
    },
    {
      name: 'workTime',
      label: translations.totalWorkTime || 'Total Work Time',
      type: 'text',
      helperText: translations.inMinutes || 'In minutes',
    },
    {
      name: 'gamingLocation',
      label: translations.location || 'Location',
      type: 'select',
      options: gamingLocations,
    },
    {
      name: 'stoNumber',
      label: translations.stoNumber || 'STO Number',
      type: 'text',
    },
    {
      name: 'quantityRepaired',
      label: translations.quantityRepaired || 'Quantity Repaired',
      type: 'text',
    },
    {
      name: 'itemNumber',
      label: translations.itemNumber || 'Item Number',
      type: 'select',
      options: gamingPartNumbers,
    },
    {
      name: 'product',
      label: translations.products || 'Products',
      type: 'select',
      options: gamingProducts,
    },
    {
      name: 'comments',
      label: translations.comments || 'Comments',
      type: 'text',
      multiline: true,
      rows: 4,
    },
  ],
});

// FST Form Configs

export const incorrectCaseFormConfig = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_INCORRECT_CASE_FORM}`,

  fields: [
    {
      name: 'jurisdiction',
      label: translations.jurisdiction || 'Jurisdiction',
      type: 'select',
      options: jids,
      required: true,
    },
    {
      name: 'caseNumber',
      label: translations.caseNumber || 'Case Number',
      type: 'text',
    },
    {
      name: 'incorrectStatus',
      label:
        translations.descriptionOfIncorrectStatus ||
        'Description of Incorrect Status',
      type: 'select',
      options: incorrectStatus,
      required: true,
    },
    {
      name: 'fstNumber',
      label: translations.fstNumber || 'FST Number',
      type: 'text',
    },
    {
      name: 'actionTaken',
      label: translations.actionTaken || 'Action Taken',
      type: 'select',
      options: actionTaken,
    },
  ],
});

export const fstOvertimeFormConfig = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_OT_FORM}`,
  method: 'POST',
  fields: [
    {
      name: 'isTech',
      label: translations.areYouATech || 'Are you a Tech?',
      type: 'radio',
      options: [
        { value: 'yes', label: translations.yes || 'Yes' },
        { value: 'no', label: translations.no || 'No' },
      ],
      required: true,
    },
    {
      name: 'penalty',
      label: translations.caseGoingToPenalty || 'Case Going to Penalty',
      type: 'radio',
      options: [
        { value: 'yes', label: translations.yes || 'Yes' },
        { value: 'no', label: translations.no || 'No' },
      ],
    },
    {
      name: 'openCase',
      label: translations.openCase || 'Open Case',
      type: 'radio',
      options: [
        { value: 'yes', label: translations.yes || 'Yes' },
        { value: 'no', label: translations.no || 'No' },
      ],
      required: true,
    },
    {
      name: 'jurisdiction',
      label: translations.jurisdiction || 'Jurisdiction',
      type: 'select',
      options: fstJids,
      required: true,
    },
    { name: 'userId', label: 'User ID', type: 'text', required: true },
    // { name: 'email', label: 'Email', type: 'text' },
    {
      name: 'caseNumber',
      label: translations.caseNumber || 'Case Number',
      type: 'text',
    },
    {
      name: 'fstNumber',
      label: translations.fstNumber || 'FST Number',
      type: 'text',
      required: true,
    },
    {
      name: 'dateWorked',
      label: translations.dateWorked || 'Date Worked',
      type: 'date',
      required: true,
    },
    {
      name: 'overtimeLength',
      label: translations.overtimeLength || 'Overtime Length (hours)',
      type: 'number',
      required: true,
      inputType: 'number',
    },
    {
      name: 'reason',
      label: translations.reason || 'Reason',
      type: 'select',
      options: reasons,
      required: true,
    },
    {
      name: 'comments',
      label: translations.comments || 'Comments',
      type: 'text',
      multiline: true,
      rows: 4,
    },
  ],
});

export const benchRepairFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/depot/benchrepair/submit`,
  method: 'POST',

  fields: [
    {
      name: 'openCase',
      label: 'Open Case',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      required: true,
    },
    {
      name: 'oem',
      label: 'OEM',
      type: 'select',
      options: yesNoOptions,
      required: true,
    },
    {
      name: 'rrfRepair',
      label: 'RRF Repair',
      type: 'select',
      options: yesNoOptions,
      required: true,
    },
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      type: 'select',
      options: jids,
      required: true,
    },

    {
      name: 'caseNumber',
      label: 'Case Number',
      type: 'text',
    },
    {
      name: 'startDate',
      label: 'Start Date',
      type: 'date',
      required: true,
    },
    {
      name: 'completionDate',
      label: 'Completion Date',
      type: 'date',
      required: true,
    },
    {
      name: 'totalWorkTime',
      label: 'Total Work Time (minutes)',
      type: 'text',
    },

    {
      name: 'product',
      label: 'Product',
      type: 'select',
      options: fstLotteryProducts,
    },
    {
      name: 'billAcceptor',
      label: 'Bill Acceptor Item Number',
      type: 'select',
      options: billAcceptorNumberItems,
    },
    {
      name: 'burster',
      label: 'Mini/Quad Burster Item Number',
      type: 'select',
      options: quadBursterNumberItems,
    },
    {
      name: 'commDevice',
      label: 'Comm Device Item Number',
      type: 'select',
      options: commDevice,
    },
    {
      name: 'displayMonitors',
      label: 'Display/Monitors Item Number',
      type: 'select',
      options: displayMonitor,
    },
    {
      name: 'pcb',
      label: 'PCB Item Number',
      type: 'select',
      options: pcbItemNumbers,
    },
    {
      name: 'peripherals',
      label: 'Peripherals Item Number',
      type: 'select',
      options: peripherals,
    },
    {
      name: 'powerSupply',
      label: 'Power Supply Item Number',
      type: 'select',
      options: powerSupplyItemNumbers,
    },
    {
      name: 'printer',
      label: 'Printer Item Number',
      type: 'select',
      options: printers,
    },
    {
      name: 'reader',
      label: 'Reader Item Number',
      type: 'select',
      options: readerItemNumbers,
    },
    {
      name: 'terminal',
      label: 'Terminal Item Number',
      type: 'select',
      options: terminals,
    },
    {
      name: 'rmaNumber',
      label: 'RMA Number',
      type: 'text',
    },
    {
      name: 'assetTag',
      label: 'Asset Tag',
      type: 'text',
    },
    {
      name: 'serialNumber',
      label: 'Serial Number',
      type: 'text',
    },
    {
      name: 'resolution',
      label: 'Resolution',
      type: 'select',
      options: yesNoOptions,
    },
    {
      name: 'failureMode',
      label: 'Failure Mode',
      type: 'select',
      options: lotteryFailureModes,
    },
    {
      name: 'solutionOne',
      label: 'Solution #1',
      type: 'select',
      options: solutions,
    },
    {
      name: 'solutionTwo',
      label: 'Solution #2',
      type: 'select',
      options: solutions,
    },
    {
      name: 'partsUsed',
      label: 'Parts Used',
      type: 'text',
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: status,
    },
    {
      name: 'isSecondTimeReturn',
      label: 'Is Second Time Return',
      type: 'select',
      options: yesNoOptions,
    },
    {
      name: 'returnDate',
      label: 'Return Date',
      type: 'date',
    },

    {
      name: 'comments',
      label: 'Comments',
      type: 'text',
      multiline: true,
      rows: 4,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

// export const fieldRepairFormConfig = translations => ({
//   apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/fst/fieldrepair/submit`,
//   method: 'POST',

//   fields: [
//     {
//       name: 'openCase',
//       label: translations.openCase || 'Open Case',
//       type: 'radio',
//       options: [
//         { value: 'yes', label: translations.yes || 'Yes' },
//         { value: 'no', label: translations.no || 'No' },
//       ],
//     },
//     {
//       name: 'caseNumber',
//       label: translations.caseNumber || 'Case Number',
//       type: 'text',
//     },
//     {
//       name: 'startDate',
//       label: translations.startDate || 'Start Date',
//       type: 'date',
//       required: true,
//     },
//     {
//       name: 'completionDate',
//       label: translations.completionDate || 'Completion Date',
//       type: 'date',
//       required: true,
//     },
//     {
//       name: 'jurisdiction',
//       label: translations.jurisdiction || 'Jurisdiction',
//       type: 'select',
//       options: jids,
//     },
//     {
//       name: 'rmaNumber',
//       label: translations.rmaNumber || 'RMA Number',
//       type: 'text',
//     },
//     {
//       name: 'assetTag',
//       label: translations.assetTag || 'Asset Tag',
//       type: 'text',
//     },
//     {
//       name: 'serialNumber',
//       label: translations.serialNumber || 'Serial Number',
//       type: 'text',
//     },
//     {
//       name: 'failureMode',
//       label: translations.failureMode || 'Failure Mode',
//       type: 'select',
//       options: lotteryFailureModes,
//     },
//     {
//       name: 'solutionOne',
//       label: translations.solution1 || 'Solution #1',
//       type: 'select',
//       options: solutions,
//     },
//     {
//       name: 'solutionTwo',
//       label: translations.solution2 || 'Solution #2',
//       type: 'select',
//       options: solutions,
//     },
//     {
//       name: 'partsUsed',
//       label: translations.partsUsed || 'Parts Used',
//       type: 'text',
//     },
//     {
//       name: 'commDevice',
//       label: translations.commDevice || 'Comm Device Item Number',
//       type: 'select',
//       options: commDevice,
//     },
//     {
//       name: 'terminal',
//       label: translations.terminal || 'Terminal Item Number',
//       type: 'select',
//       options: terminals,
//     },
//     {
//       name: 'printer',
//       label: translations.printer || 'Printer Item Number',
//       type: 'select',
//       options: printers,
//     },
//     {
//       name: 'peripherals',
//       label: translations.peripherals || 'Peripherals Item Number',
//       type: 'select',
//       options: peripherals,
//     },
//     {
//       name: 'displayMonitors',
//       label: translations.displayMonitors || 'Display/Monitors Item Number',
//       type: 'select',
//       options: displayMonitor,
//     },
//     {
//       name: 'status',
//       label: translations.status || 'Status',
//       type: 'select',
//       options: status,
//     },
//     {
//       name: 'totalWorkTime',
//       label: translations.totalWorkTime || 'Total Work Time (minutes)',
//       type: 'text',
//     },
//     {
//       name: 'isSecondTimeReturn',
//       label: translations.isSecondTimeReturn || 'Is Second Time Return',
//       type: 'select',
//       options: ['Yes', 'No'],
//     },
//     {
//       name: 'returnDate',
//       label: translations.returnDate || 'Return Date',
//       type: 'date',
//     },
//     {
//       name: 'product',
//       label: translations.product || 'Product',
//       type: 'select',
//       options: fstLotteryProducts,
//     },
//     {
//       name: 'reader',
//       label: translations.readerItemNumber || 'Reader Item Number',
//       type: 'select',
//       options: readerItemNumbers,
//     },
//     {
//       name: 'powerSupply',
//       label: translations.powerSupplyItemNumber || 'Power Supply Item Number',
//       type: 'select',
//       options: powerSupplyItemNumbers,
//     },
//     {
//       name: 'pcb',
//       label: translations.pcbItemNumber || 'PCB Item Number',
//       type: 'select',
//       options: pcbItemNumbers,
//     },
//     {
//       name: 'billAcceptor',
//       label: translations.billAcceptorItemNumber || 'Bill Acceptor Item Number',
//       type: 'select',
//       options: billAcceptorNumberItems,
//     },
//     {
//       name: 'burster',
//       label: translations.bursterItemNumber || 'Mini/Quad Burster Item Number',
//       type: 'select',
//       options: quadBursterNumberItems,
//     },
//     {
//       name: 'comments',
//       label: translations.comments || 'Comments',
//       type: 'text',
//       multiline: true,
//       rows: 4,
//     },
//     {
//       name: 'displayName',
//       label: 'Associate Name',
//       type: 'text',
//       required: true,
//     },
//     { name: 'userId', label: 'User ID' },
//   ],
// });

////////////////////////////////////////////////////
//              NRC Form Configs;                //
////////////////////////////////////////////////////

export const californiaPTLFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/californiaPTL/submit`,

  method: 'POST',
  fields: [
    // { name: 'date', label: 'Date', type: 'date', required: true },
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      defaultValue: 'California',
      value: 'California',
      type: 'textDefault',
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Please enter numeric values only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
      required: true,
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
      required: true,
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      required: true,
    },
    {
      name: 'openCase',
      label: 'Case Open',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      required: true,
    },
    {
      name: 'caseNumber',
      label: 'Case Number',
      type: 'text',
    },
    {
      name: 'updateStoreHours',
      label: 'Update Store Hours',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      required: true,
    },
    {
      name: 'sundayOpen',
      label: 'Sunday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },

    {
      name: 'sundayClose',
      label: 'Sunday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'mondayOpen',
      label: 'Monday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },

    {
      name: 'mondayClose',
      label: 'Monday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'tuesdayOpen',
      label: 'Tuesday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'tuesdayClose',
      label: 'Tuesday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'wednesdayOpen',
      label: 'Wednesday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },

    {
      name: 'wednesdayClose',
      label: 'Wednesday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'thursdayOpen',
      label: 'Thursday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },

    {
      name: 'thursdayClose',
      label: 'Thursday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'fridayOpen',
      label: 'Friday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },

    {
      name: 'fridayClose',
      label: 'Friday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'saturdayOpen',
      label: 'Saturday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },

    {
      name: 'saturdayClose',
      label: 'Saturday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'updatePhone',
      label: 'Update Phone',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      required: true,
    },
    {
      name: 'newPhone',
      label: 'New Phone',
      type: 'text',
    },
    {
      name: 'terminalType',
      label: 'Terminal Type',
      type: 'select',
      options: [
        'EDSQ',
        'Flex',
        'Game Touch 20',
        'Game Touch 28',
        'Gemini',
        'GT1200',
      ],
    },
    {
      name: 'keyRequest',
      label: 'Key Request',
      type: 'text',
      helperText: 'Serial Number',
    },
    {
      name: 'messageToSite',
      label: 'Message to Site',
      type: 'text',
      multiline: true,
      rows: 4,
      helperText:
        'Be sure to insert in all case notes and if needed please indicate: Store Add/Name Update, Top 200, Comm/Supervisor Escalation, Relocation, etc.',
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const consumableSupplyFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/consply/submit`,

  method: 'POST',
  fields: [
    // { name: 'date', label: 'Date', type: 'date', required: true },
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      type: 'select',
      options: jidsConsumableSupply,
      required: true,
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Enter Numbers Only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
      required: true,
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
      required: true,
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      required: true,
    },
    {
      name: 'callerName',
      label: 'Caller Name',
      type: 'text',
      required: true,
    },
    // add supplies under here
    {
      name: 'adjustmentForms',
      label: 'Adjustment Forms',
      type: 'select',
      options: consumableSupply_unit,
    },
    {
      name: 'allOrNothing',
      label: 'All or Nothing Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'badger5',
      label: 'Badger 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'cash3',
      label: 'Cash 3 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'cash4',
      label: 'Cash 4 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'cash4Life',
      label: 'Cash 4 Life Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'cash5',
      label: 'Cash 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'cash5EzMatch',
      label: 'Cash 5 EZ Match Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'cashball',
      label: 'Cashball',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'cashPop',
      label: 'Cash Pop Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'cashPopPlay',
      label: 'Cash Pop Play Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'claimForms',
      label: 'Claim Forms',
      type: 'select',
      options: consumableSupply_unit,
    },
    {
      name: 'classicLotto47',
      label: 'Classic Lotto 47 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'clubKenoJack',
      label: 'Club Keno w/ Jack Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'daily3',
      label: 'Daily 3 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'daily4',
      label: 'Daily 4 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'dailyDerby',
      label: 'Daily Derby Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'dailyKeno',
      label: 'Daily Keno Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'envelopes',
      label: 'Envelopes',
      type: 'select',
      options: consumableSupply_unit,
    },
    {
      name: 'fantasy5',
      label: 'Fantasy 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'gaCashPop',
      label: 'GA Cash Pop Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'georgia5',
      label: 'Georgia 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'gopher5',
      label: 'Gopher 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'hit5',
      label: 'Hit 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'hotSpot',
      label: 'Hot Spot Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'instantShowcaseRolls',
      label: 'Instant Showcase Rolls',
      type: 'select',
      options: consumableSupply_rolls,
    },
    {
      name: 'instantTicketTrackingForms',
      label: 'Instant Ticket Tracking Forms',
      type: 'select',
      options: consumableSupply_unit,
    },
    {
      name: 'itvmRolls',
      label: 'ITVM Rolls',
      type: 'select',
      options: consumableSupply_rolls,
    },
    {
      name: 'jerseyCash5',
      label: 'Jersey Cash 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    // {
    //   name: 'jumboBucks',
    //   label: 'Jumbo Bucks Playslip',
    //   type: 'select',
    //   options: consumableSupply_pack,
    // },
    {
      name: 'keno',
      label: 'Keno Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'kenoPencils',
      label: 'Keno Pencils',
      type: 'select',
      options: consumableSupply_unit,
    },
    {
      name: 'kenoToGo',
      label: 'Keno to Go Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'kentucky5',
      label: 'Kentucky 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'lotto',
      label: 'Lotto Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'lottoAmerica',
      label: 'Lotto America Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'lottoExtra',
      label: 'Lotto Extra Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'luckyForLife',
      label: 'Lucky for Life Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'match4',
      label: 'Match 4 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'match5',
      label: 'Match 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'megaMillions',
      label: 'Mega Millions Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'megaBucks',
      label: 'Megabucks Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'ncKenoMultiplier',
      label: 'NC Keno Multiplier Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'newKenoRefresh',
      label: 'New Keno Refresh Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'njCashPop4Board',
      label: 'NJ Cash Pop 4 Board Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'northstar5',
      label: 'Northstar 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'numbers',
      label: 'Numbers Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'palmettoCash5',
      label: 'Palmetto Cash 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'pencils',
      label: 'Pencils',
      type: 'select',
      options: consumableSupply_unit,
    },
    {
      name: 'perforatedTape',
      label: 'Perforated Tape',
      type: 'select',
      options: consumableSupply_pack,
    },
    // {
    //   name: 'perforatedTape',
    //   label: 'Perforated Tape',
    //   type: 'radio',
    //   options: [
    //     { value: 'yes', label: 'Yes' },
    //     { value: 'no', label: 'No' },
    //   ],
    //   required: true,
    // },
    {
      name: 'pick2',
      label: 'Pick 2 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'pick3',
      label: 'Pick 3 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'pick4',
      label: 'Pick 4 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'pick5',
      label: 'Pick 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'pick6',
      label: 'Pick 6 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'pick10',
      label: 'Pick 10 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    // {
    //   name: 'play3',
    //   label: 'Play 3 Playslip',
    //   type: 'select',
    //   options: consumableSupply_pack,
    // },
    // {
    //   name: 'play4',
    //   label: 'Play 4 Playslip',
    //   type: 'select',
    //   options: consumableSupply_pack,
    // },
    {
      name: 'powerball',
      label: 'Powerball Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'quickBucks',
      label: 'Quick Bucks Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'quickDraw',
      label: 'Quick Draw Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'quickDrawQuickDrawExtra',
      label: 'Quick Draw / Quick Draw Extra Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'quickDrawBullseye',
      label: 'Quick Draw with Bullseye Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'returnBags',
      label: 'Return Bags',
      type: 'select',
      options: consumableSupply_unit,
    },
    {
      name: 'showMeCash',
      label: 'Show Me Cash Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'superLottoPlus',
      label: 'Super Lotto Plus Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'superCash',
      label: 'Super Cash Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'take5',
      label: 'Take 5 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'tnCash',
      label: 'Tennessee Cash Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'tnDailyJackpot',
      label: 'Tennessee Daily Jackpot Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'ticketStock',
      label: 'Ticket Stock (Printer Paper)',
      type: 'select',
      options: consumableSupply_rolls,
    },
    {
      name: 'thermalTicketStock',
      label: 'Thermal Ticket Stock (Printer Paper)',
      type: 'select',
      options: consumableSupply_rolls,
    },
    // {
    //   name: 'triplePlay',
    //   label: 'Triple Play Playslip',
    //   type: 'select',
    //   options: consumableSupply_pack,
    // },
    {
      name: 'tripleJackpotPlay',
      label: 'Triple Jackpot Play Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },
    {
      name: 'ultraTicketStock',
      label: 'Ultra Ticket Stock (4 rolls to a carton)',
      type: 'select',
      options: consumableSupply_rolls,
    },
    {
      name: 'win4',
      label: 'Win 4 Playslip',
      type: 'select',
      options: consumableSupply_pack,
    },

    {
      name: 'supplyChangeComments',
      label: 'Supply Change Comments',
      type: 'text',
      multiline: true,
      rows: 4,
      required: true,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const dispatchStatusChangeFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/dispatchStatusChange/submit`,

  method: 'POST',
  fields: [
    // { name: 'date', label: 'Request Date', type: 'date', required: true },
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      type: 'select',
      options: jidsNRCForms,
      required: true,
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Enter Numbers Only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
      required: true,
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
      // multiline: true,
      // rows: 2,
      required: true,
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      required: true,
    },
    {
      name: 'contactName',
      label: 'Contact Name',
      type: 'text',
      required: true,
    },
    {
      name: 'openCase',
      label: 'Case Open',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      required: true,
    },
    {
      name: 'caseNumber',
      label: 'Case Number',
      type: 'text',
    },
    {
      name: 'actionRequest',
      label: 'Action Request',
      type: 'select',
      options: ['Cancel Case', 'Change Case Status', 'Other'],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: ['Up', 'Down', 'Faulty'],
    },
    {
      name: 'comments',
      label: 'Comments',
      type: 'text',
      multiline: true,
      rows: 4,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const forwardToSiteFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/fts/submit`,

  method: 'POST',
  fields: [
    // { name: 'date', label: 'Date', type: 'date', required: true },
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      type: 'select',
      options: jidsNRCForms,
      required: true,
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Please enter numeric values only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
      required: true,
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
      required: true,
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      required: true,
    },
    {
      name: 'terminalType',
      label: 'Terminal Type',
      type: 'select',
      options: [
        'Altura',
        'Altura Compact',
        'CDS',
        'EDSQ 16',
        'EDSQ 24',
        'Flex',
        'Gamepoint',
        'Game Touch 20',
        'Game Touch 28',
        'Game Touch Draw',
        'Gemini Touch',
        'Gemini Ultra',
        'GT1200',
        'GT1200 Compact',
        'Lottery Inside',
        'Retailer Pro',
        'SST',
      ],
    },
    {
      name: 'retailerComplaint',
      label: 'Retailer Complaint',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
    },
    {
      name: 'thresholdExceeded',
      label: 'Threshold Exceeded',
      type: 'select',
      options: ['Yes', 'No'],
    },
    {
      name: 'supRequested',
      label: 'Supervisor Requested?',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      required: true,
    },
    {
      name: 'contactName',
      label: 'Contact Name',
      type: 'text',
    },
    { name: 'callbackNumber', label: 'Callback Phone Number', type: 'text' },

    {
      name: 'communicationsType',
      label: 'Communications Type',
      type: 'radio',
      options: [
        { value: 'hughes/vsat', label: 'Hughes/Vsat' },
        { value: 'ventus/Cell', label: 'Ventus/Cell' },
        { value: 'digi/Cell', label: 'Digi/Cell' },
      ],
    },
    {
      name: 'openCase',
      label: 'Case Open',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      required: true,
    },

    {
      name: 'caseNumber',
      label: 'Case Number',
      type: 'text',
    },
    { name: 'fstAssigned', label: 'FST Assigned to Case', type: 'text' },

    {
      name: 'gnieArticleNumber',
      label: 'GNIE Article Number',
      type: 'text',
      required: true,
    },

    {
      name: 'messageToSite',
      label: 'Issue/Reason for Form',
      type: 'text',
      multiline: true,
      rows: 4,
      helperText:
        'Be sure to indicate in message if a dispatch ticket is open, zone, last FST in site, and all pertinent notes',
    },
    {
      name: 'messageToSup',
      label: 'Message to Supervisor',
      type: 'text',
      multiline: true,
      rows: 4,
      required: true,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const gnieUpdateRequestFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/gnieUpdate/submit`,

  method: 'POST',
  fields: [
    // { name: 'date', label: 'Date', type: 'date', required: true },
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      type: 'select',
      options: jidsNRCForms,
      required: true,
    },
    {
      name: 'gnieArticleNumber',
      label: 'GNIE Article Number',
      type: 'text',
      required: true,
    },
    {
      name: 'description',
      label: 'Description',
      type: 'text',
      multiline: true,
      rows: 4,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const leewardIslandCreditLogFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/licreditlog/submit`,

  method: 'POST',
  fields: [
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      defaultValue: 'Leeward Islands',
      value: 'Leeward Islands',
      type: 'textDefault',
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Enter Numbers Only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
    },
    {
      name: 'callerName',
      label: 'Caller Name',
      type: 'text',
      required: true,
    },
    {
      name: 'logNumber',
      label: 'Log Number',
      type: 'text',
      required: true,
    },

    {
      name: 'dateTicket',
      label: 'Date Ticket',
      type: 'date',
      required: true,
    },
    {
      name: 'originalTicket',
      label: 'Original Ticket',
      type: 'text',
      required: true,
    },
    {
      name: 'reprintSalesNumber',
      label: 'Reprint Sales Number',
      type: 'text',
    },
    {
      name: 'gamePlayed',
      label: 'Game Played',
      type: 'text',
      required: true,
    },
    {
      name: 'amount',
      label: 'Amount',
      type: 'amount',
      required: true,
    },
    {
      name: 'comments',
      label: 'Comments',
      type: 'text',
      multiline: true,
      rows: 4,
      required: true,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const michiganIncidentLogFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/miincidentlog/submit`,

  method: 'POST',
  fields: [
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      defaultValue: 'Michigan',
      value: 'Michigan',
      type: 'textDefault',
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Enter Numbers Only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
    },
    {
      name: 'callerName',
      label: 'Caller Name',
      type: 'text',
      required: true,
    },
    // {
    //   name: 'agentNumber',
    //   label: 'Agent Number',
    //   type: 'text',
    //   required: true,
    // },
    // {
    //   name: 'timeOfCall',
    //   label: 'Time of Call',
    //   type: 'dateTime',
    //   required: true,
    // },
    {
      name: 'retailerServices',
      label: 'Retailer Services',
      type: 'text',
    },
    {
      name: 'transferredToSiteOperations',
      label: 'Transferred to Site Operations',
      type: 'select',
      options: ['Yes', 'No'],
      required: true,
    },
    {
      name: 'alturaAdjustmentForms',
      label: 'Altura Adjustment Forms',
      type: 'text',
    },
    {
      name: 'comments',
      label: 'Comments',
      type: 'text',
      multiline: true,
      rows: 4,
      required: true,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const nyAMLogFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/nyAmLog/submit`,

  method: 'POST',
  fields: [
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      defaultValue: 'New York',
      value: 'New York',
      type: 'textDefault',
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Enter Numbers Only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
    },
    {
      name: 'callerName',
      label: 'Caller Name',
      type: 'text',
      required: true,
    },
    {
      name: 'agentNumber',
      label: 'Agent Number',
      type: 'text',
      required: true,
    },
    // {
    //   name: 'timeOfCall',
    //   label: 'Time of Call',
    //   type: 'dateTime',
    //   required: true,
    // },
    {
      name: 'amServiceRequest',
      label: 'AM Service Request',
      type: 'select',
      options: ['Yes', 'No'],
      required: true,
    },
    {
      name: 'reasonForDispatch',
      label: 'Reason for Dispatch',
      type: 'text',
      multiline: true,
      rows: 4,
      required: true,
    },
    {
      name: 'issue',
      label: 'Issue',
      type: 'text',
      multiline: true,
      rows: 4,
      required: true,
    },
    {
      name: 'notes',
      label: 'Notes',
      type: 'text',
      multiline: true,
      rows: 4,
      required: true,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const nyKeyRequestFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/nyKeyRequest/submit`,

  method: 'POST',
  fields: [
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      defaultValue: 'New York',
      value: 'New York',
      type: 'textDefault',
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Enter Numbers Only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
      required: true,
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
      // multiline: true,
      // rows: 2,
      required: true,
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      required: true,
    },
    {
      name: 'contactName',
      label: 'Contact Name',
      type: 'text',
      required: true,
    },
    {
      name: 'terminalType',
      label: 'Terminal Type',
      type: 'select',
      options: [
        'EDSQ',
        'Game Touch 20',
        'Game Touch 28',
        'Gamepoint',
        'Gemini',
        'Gemini Touch',
        'SST',
      ],
      required: true,
    },
    {
      name: 'serialNumber',
      label: 'Serial Number',
      type: 'text',
    },
    {
      name: 'keyRequested',
      label: 'Key Requested',
      type: 'select',
      options: [
        'Door Cylinder Key',
        'Cash Box Key',
        'T-Bar',
        'Key Core',
        'Main Door Key',
        'Bill Acceptor',
        'Security Box Key',
      ],
    },
    {
      name: 'comments',
      label: 'Comments',
      type: 'text',
      multiline: true,
      rows: 4,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const nrcDowntimeFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/nrcDowntime/submit`,

  method: 'POST',
  fields: [
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      type: 'select',
      options: jidsNRCForms_NRCDowntime,
      required: true,
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Please enter numeric values only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
      required: true,
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      required: true,
    },
    {
      name: 'streetAddress',
      label: 'Street Address',
      type: 'text',
      required: true,
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
      required: true,
    },
    {
      name: 'zipCode',
      label: 'Zip Code',
      type: 'text',
      required: true,
    },
    {
      name: 'dispatchStatus',
      label: 'Dispatch Status',
      type: 'select',
      options: ['Down', 'Faulty', 'Up'],
      defaultValue: 'Down',
    },
    {
      name: 'disposition',
      label: 'Disposition',
      type: 'text',
      defaultValue: 'Dispatch',
    },
    {
      name: 'reason',
      label: 'Reason',
      type: 'text',
      multiline: true,
      rows: 4,
    },
    {
      name: 'issue',
      label: 'Issue',
      type: 'text',
      multiline: true,
      rows: 4,
    },
    {
      name: 'caseNotes',
      label: 'Case Notes',
      type: 'text',
      multiline: true,
      rows: 4,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const riCreditLogFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/riCreditLog/submit`,

  method: 'POST',
  fields: [
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      defaultValue: 'Rhode Island',
      value: 'Rhode Island',
      type: 'textDefault',
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Enter Numbers Only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
    },
    {
      name: 'logNumber',
      label: 'Log Number',
      type: 'text',
      required: true,
    },
    {
      name: 'transactionNumber',
      label: 'Transaction Number',
      type: 'text',
      required: true,
    },
    {
      name: 'reprintSalesNumber',
      label: 'Reprint Sales Number',
      type: 'text',
    },
    {
      name: 'gamePlayed',
      label: 'Game Played',
      type: 'select',
      options: [
        'Bingo',
        'Coupon',
        'Keno',
        'Keno Free Ride',
        'Lucky for Life',
        'Mega Millions',

        'PlaySpot',
        'Powerball',
        'The Numbers',
        'Wild Money',
      ],
      required: true,
    },
    {
      name: 'amount',
      label: 'Amount',
      type: 'amount',
      required: true,
    },
    {
      name: 'reason',
      label: 'Reason',
      type: 'text',
      multiline: true,
      rows: 4,
      required: true,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const retailerInfoChangeFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/retailerInfoChange/submit`,

  method: 'POST',
  fields: [
    // { name: 'date', label: 'Date', type: 'date', required: true },
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      type: 'select',
      options: jidsRetailerContactInfo,
      required: true,
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Please enter numeric values only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
      required: true,
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
      required: true,
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      required: true,
    },
    {
      name: 'terminal',
      label: 'Terminal',
      type: 'text',
      required: false,
    },
    {
      name: 'associatedTerminals',
      label: 'Associated Terminals',
      type: 'text',
      required: false,
    },
    {
      name: 'updatePhone',
      label: 'Update Phone',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      required: true,
    },
    {
      name: 'newPhone',
      label: 'New Phone Number',
      type: 'text',
    },
    {
      name: 'requestedPasscode',
      label: 'Requested Passcode',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      required: false,
    },
    {
      name: 'oldPasscode',
      label: 'Old Passcode',
      type: 'text',
      required: false,
    },
    {
      name: 'newPasscode',
      label: 'New Passcode',
      type: 'text',
      required: false,
    },
    { name: 'requestedBy', label: 'Requested By', type: 'text' },
    {
      name: 'managerPasscode',
      label: 'Manager Passcode',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      required: false,
    },
    {
      name: 'updateStoreHours',
      label: 'Update Store Hours',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      helperText: 'Only enter hours that need to be updated',
      required: true,
    },
    {
      name: 'sundayOpen',
      label: 'Sunday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },

    {
      name: 'sundayClose',
      label: 'Sunday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'mondayOpen',
      label: 'Monday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },

    {
      name: 'mondayClose',
      label: 'Monday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'tuesdayOpen',
      label: 'Tuesday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'tuesdayClose',
      label: 'Tuesday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'wednesdayOpen',
      label: 'Wednesday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },

    {
      name: 'wednesdayClose',
      label: 'Wednesday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'thursdayOpen',
      label: 'Thursday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },

    {
      name: 'thursdayClose',
      label: 'Thursday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'fridayOpen',
      label: 'Friday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },

    {
      name: 'fridayClose',
      label: 'Friday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'saturdayOpen',
      label: 'Saturday (Open)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'saturdayClose',
      label: 'Saturday (Close)',
      type: 'selectTime',
      options: formsNRCTime,
    },
    {
      name: 'comments',
      label: 'Comments',
      type: 'text',
      multiline: true,
      rows: 4,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const stolenAndMissingInstantTicketFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/stolenAndMissingTicket/submit`,

  method: 'POST',
  fields: [
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      type: 'select',
      options: [
        'Colorado',
        'Connecticut',
        'Mississippi',
        'Nebraska',
        'Washington',
        'Wisconsin',
      ],
      required: true,
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
      helperText: 'Please enter numeric values only',
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
      required: true,
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
      required: true,
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      required: true,
    },
    {
      name: 'reportType',
      label: 'Type of Report *',
      type: 'select',
      options: ['Stolen', 'Missing', 'Lost', 'Found Pack'],
      required: true,
    },
    {
      name: 'dateTimeCall',
      label: 'Date and Time the call came in',
      type: 'dateTime',
      required: true,
      helperText: 'Date and Time call came into the NRC for reported theft',
    },
    {
      name: 'callersName',
      label: "Caller's Name",
      type: 'text',
      required: true,
    },
    {
      name: 'poc',
      label: 'Point of Contact',
      type: 'text',
    },
    {
      name: 'jobTitle',
      label: 'Job Title',
      type: 'text',
    },
    {
      name: 'description',
      label: 'Brief Description of how theft happened',
      type: 'text',
      multiline: true,
      rows: 4,
      required: true,
    },
    {
      name: 'calledRetailerBack',
      label: 'Called Retailer back at store *',
      type: 'select',
      options: ['Yes', 'No'],
      helperText:
        "Did you call the Retailer back at store to verify the caller's identity?",
      required: true,
    },
    {
      name: 'encouragedRetailer',
      label: 'Encouraged Retailer to report theft to Police *',
      type: 'select',
      options: ['Yes', 'No'],
      helperText:
        'Did you encourage the Retailer to report the theft to the local police?',
      required: true,
    },
    {
      name: 'caseNum',
      label: 'Case Number (If Applicable)',
      type: 'text',
      helperText:
        'If the local police opened a case please add a case number here',
    },
    {
      name: 'lotterySecurityCallback',
      label: 'Informed Retailer of Lottery Security call back *',
      type: 'select',
      options: ['Yes', 'No'],
      helperText:
        "Did you inform the Retailer a representative of the Jurisdiction's State Lottery Security will contact them on the next business day?",
      required: true,
    },
    {
      name: 'dateTimeTheft',
      label: 'Date and Time of theft',
      type: 'dateTime',
      required: true,
      helperText: 'Date and Time call came into the NRC for reported theft',
    },
    {
      name: 'packsMissing',
      label: 'Number of packs missing',
      type: 'text',
      required: true,
    },
    {
      name: 'packTypes',
      label: 'Pack Type(s) *',
      type: 'select',
      options: [
        'Entire Pack(s)',
        'Partial Pack(s)',
        'Both Partial and Entire Packs',
      ],
      required: true,
    },
    {
      name: 'advisedFee',
      label: 'Advised Retailer of $25/pack fee applied by Lottery *',
      type: 'select',
      options: ['Yes', 'No'],
      helperText:
        'IGT must refer Retailer to the Lottery for questions regarding pack fee.',
      required: true,
    },
    {
      name: 'comments',
      label: 'Additional Comments',
      type: 'text',
      multiline: true,
      rows: 4,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};

export const washingtonRetailerTrainingRequestFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/waRetailerTraining/submit`,

  method: 'POST',
  fields: [
    {
      name: 'jurisdiction',
      label: 'Jurisdiction',
      defaultValue: 'Washington',
      value: 'Washington',
      type: 'textDefault',
    },
    {
      name: 'retailerId',
      label: 'Retailer ID',
      type: 'text',
      required: true,
    },
    {
      name: 'retailerName',
      label: 'Retailer Name',
      type: 'text',
      required: true,
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
      required: true,
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      required: true,
    },
    {
      name: 'callersName',
      label: 'Caller Name',
      type: 'text',
      required: true,
    },
    {
      name: 'trainingType',
      label: 'Training Type',
      type: 'select',
      options: ['Altura GT1200', 'Gemini Touch', 'Instant Showcase'],
    },
    {
      name: 'trainingDate',
      label: 'Desired Training Date',
      type: 'date',
      required: true,
    },
    {
      name: 'comments',
      label: 'Comments',
      type: 'text',
      multiline: true,
      rows: 4,
    },
    {
      name: 'displayName',
      label: 'Associate Name',
      type: 'text',
      required: true,
    },
    { name: 'userId', label: 'User ID' },
  ],
};
