import { useNavigate } from "react-router-dom";

// Custom hook to navigate to a different page
const useNavigateTo = () => {
  const navigate = useNavigate(); // Get the navigate function from the react-router-dom

  // Return a function to navigate to a different page
  return (path) => {
    navigate(path);
  };
};

export default useNavigateTo;
