import React, { useContext } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import ThemeSetting from './themeSetting';
import LanguageSetting from './languageSetting';
import { LanguageContext } from '../context/languageContext';

const SettingsMenu = ({
    settingsMenuAnchor,
    settingsMenuOpen,
    handleSettingsMenuClose,
    themeMode,
    handleThemeChange,
    language,
    handleLanguageChange,
}) => {
    const { translations } = useContext(LanguageContext);

    return (
        <Menu
            id="settings-menu"
            anchorEl={settingsMenuAnchor}
            open={settingsMenuOpen}
            onClose={handleSettingsMenuClose}
            PaperProps={{
                style: {
                    backgroundColor: '#FFFF',
                    width: 250,
                },
            }}
        >
            <MenuItem disabled>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#053469' }}>
                    {translations.settings || 'Settings'}
                </Typography>
            </MenuItem>
            {/* <ThemeSetting
                themeMode={themeMode}
                handleThemeChange={handleThemeChange}
                translations={translations}
            /> */}
            <LanguageSetting
                language={language}
                handleLanguageChange={handleLanguageChange}
                translations={translations}
            />
        </Menu>
    );
};

export default SettingsMenu;
