import AddIcon from '@mui/icons-material/Add';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import BugReportIcon from '@mui/icons-material/BugReport';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import BuildIcon from '@mui/icons-material/Build';
import CelebrationIcon from '@mui/icons-material/Celebration';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ConstructionIcon from '@mui/icons-material/Construction';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandLess';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import LaunchIcon from '@mui/icons-material/Launch';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PendingIcon from '@mui/icons-material/Pending';
import SearchIcon from '@mui/icons-material/Search';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import WidgetsIcon from '@mui/icons-material/Widgets';

export {
  AddIcon,
  AdminPanelSettingsIcon,
  AssessmentIcon,
  Brightness4Icon,
  Brightness7Icon,
  BugReportIcon,
  BuildCircleIcon,
  BuildIcon,
  CelebrationIcon,
  CheckCircleOutlineIcon,
  CheckIcon,
  CloseIcon,
  CloudDownloadIcon,
  CloudUploadIcon,
  ConfirmationNumberIcon,
  ConstructionIcon,
  ContentCopyIcon,
  DeleteForeverIcon,
  DynamicFormIcon,
  EditIcon,
  EmailIcon,
  EngineeringIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  FileOpenIcon,
  FileUploadIcon,
  HeadsetMicIcon,
  HelpIcon,
  HelpOutlineIcon,
  HighlightOffIcon,
  HomeIcon,
  InfoIcon,
  InfoOutlinedIcon,
  LanguageIcon,
  LaunchIcon,
  LocationOnIcon,
  LogoutIcon,
  MenuIcon,
  NewReleasesIcon,
  NoAccountsIcon,
  NotificationsActiveIcon,
  NotificationsIcon,
  PendingIcon,
  SearchIcon,
  SettingsBrightnessIcon,
  SettingsIcon,
  SmartToyIcon,
  SupervisorAccountIcon,
  ToggleOffIcon,
  ToggleOnIcon,
  VisibilityIcon,
  WarningIcon,
  WidgetsIcon,
};
