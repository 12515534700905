
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import { myMSALObj, getTokenPopup } from '../services/sso/authPopup';
import { loginRequest } from '../services/sso/authConfig';

// Fetches the user's profile photo from Azure AD or the standard CRM API
export const fetchProfilePhoto = async () => {
  const storedToken = Cookies.get("token");
  if (!storedToken) {
    console.error("Authentication token is missing.");
    return { error: "Authentication token is missing." };
  }

  try {
    if (myMSALObj.getAccount()) {
      // SSO authentication logic
      const response = await getTokenPopup(loginRequest);
      if (response) {
        const graphResponse = await fetch(
          "https://graph.microsoft.com/v1.0/me/photo/$value",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${response.accessToken}`,
            },
          }
        );

        if (graphResponse.ok) {
          const photoData = await graphResponse.blob();
          const photoUrl = URL.createObjectURL(photoData);
          return { photoUrl, type: 'sso' };
        } else {
          return { error: "No SSO profile photo available.", type: 'sso' };
        }
      }
    } else {
      const decodedToken = jwtDecode(storedToken);
      const userId = decodedToken.userId;
      const response = await fetch(
        `http://localhost:3001/v1/crm/system/profile/photo/${userId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      if (response.ok) {
        const blob = await response.blob();
        const photoUrl = URL.createObjectURL(blob);
        return { photoUrl, type: 'standard' };
      } else {
        return { error: `Failed to fetch profile photo. Status: ${response.status}`, type: 'standard' };
      }
    }
  } catch (error) {
    console.error("Error fetching user photo:", error);
    return { error: "Error fetching user photo.", type: 'general' };
  }
};
