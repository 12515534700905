import React, { useState, useContext } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import NavigationBar from '../../components/navigationBar';
import { utilities as utilitiesFunction } from '../../utils/contactCenterUtilities';
import { LanguageContext } from '../../context/languageContext';

const ContactCenterPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { translations, language, changeLanguage } = useContext(LanguageContext);
  const utilities = utilitiesFunction(translations);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <NavigationBar />
      <Box
        sx={{
          backgroundColor: '#4a7ec3',
          height: '35px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
        }}
      >
        <Typography sx={{ color: '#FFFFFF', fontWeight: 'bold', ml: 2 }}>
          {translations.agentUtilities || 'Agent Utilities'}</Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          minHeight: 'calc(100vh - 35px - 64px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {Array.isArray(utilities) && (
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Contact Center Tabs"
            indicatorColor="primary"
            textColor="primary"
            sx={{ marginBottom: 2 }}
          >
            {utilities.map((utility, index) => (
              <Tab key={index} label={utility.tab} />
            ))}
          </Tabs>
        )}
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', padding: 2, }}>
          {Array.isArray(utilities) && utilities[selectedTab]?.items.map((item, index) => (
            <Box key={index} sx={{ flexGrow: 1, marginBottom: 2 }}>
              {item.component}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ContactCenterPage;
