import React, { useState } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
  Autocomplete,
  CircularProgress,
  Alert,
  Stack,
  IconButton,
  InputAdornment,
} from '@mui/material';
import useCustomForm from '../hooks/useCustomForm';
import * as Yup from 'yup';
import { countries } from '../constants/countries';
import { CloseIcon, SearchIcon } from '../assets/icons/icons';
import useEngageForm from '../hooks/useEngageForm';

const InviteLocalSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  jobTitle: Yup.string().required('Required'),
  department: Yup.string().required('Required'),
  officeLocation: Yup.string().required('Required'),
  country: Yup.object().required('Required'),
  userType: Yup.string().required('Required'),
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// TODO: Error Handling, Input Validation
export default function InviteUserModal({
  isDialogOpen,
  setIsDialogOpen,
  onUserInvited,
}) {
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const localForm = useEngageForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      jobTitle: '',
      department: '',
      officeLocation: '',
      country: { code: 'US', label: 'United States' },
      userType: 'standard',
    },
    validationSchema: InviteLocalSchema,
    onSubmit: async (values) => {
      await handleInviteLocal({
        ...values,
        country: values.country.code,
      });
    },
  });

  const handleInviteSSO = async (loginData) => {
    // const res = await axios.post(
    //   "http://localhost:3004/v1/engage/auth/login",
    //   loginData
    // );
  };
  const handleInviteLocal = async (userData) => {
    try {
      localForm.setAlertNone();
      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_USERS}/create-invite`,
        {
          userData: userData,
        }
      );
      setIsDialogOpen(false);
      onUserInvited();
    } catch (error) {
      console.error(error);
      localForm.setAlertError(
        error.response?.data?.error || 'Invite user failed. Please try again.'
      );
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Dialog open={isDialogOpen}>
      <Box>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label={'Local User'} {...tabProps(0)} />
          <Tab label={'SSO User'} {...tabProps(1)} />
        </Tabs>
      </Box>
      <IconButton
        onClick={() => {
          setIsDialogOpen(false);
        }}
        sx={{ position: 'absolute', right: 6, top: 6 }}
      >
        <CloseIcon fontSize='large' />
      </IconButton>
      <TabPanel value={activeTab} index={0}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            localForm.handleSubmit(e);
          }}
        >
          <DialogContent sx={{ paddingTop: 0 }}>
            <Stack paddingTop={1} gap={'1rem'}>
              <Stack direction={'row'} gap={1}>
                <TextField
                  size='small'
                  id='firstName'
                  name='firstName'
                  label='First Name'
                  {...localForm.fieldProps('firstName')}
                />
                <TextField
                  size='small'
                  id='lastName'
                  name='lastName'
                  label='Last Name'
                  type='text'
                  {...localForm.fieldProps('lastName')}
                />
              </Stack>

              <TextField
                size='small'
                id='email'
                name='email'
                label='Email Address'
                {...localForm.fieldProps('email')}
              />
              <Stack direction={'row'} gap={1}>
                <TextField
                  size='small'
                  id='jobTitle'
                  name='jobTitle'
                  label='Job Title'
                  {...localForm.fieldProps('jobTitle')}
                />
                <TextField
                  size='small'
                  id='department'
                  name='department'
                  label='Department'
                  {...localForm.fieldProps('department')}
                />
              </Stack>
              <TextField
                size='small'
                id='officeLocation'
                name='officeLocation'
                label='Office Location'
                fullWidth
                {...localForm.fieldProps('officeLocation')}
              />
              <Autocomplete
                size='small'
                options={countries}
                disableClearable
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  value && option.code === value.code
                }
                value={localForm.values.country}
                onChange={(event, value) => {
                  localForm.setFieldValue('country', value ?? undefined);
                }}
                onBlur={localForm.handleBlur}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label='Country'
                      autoComplete='off'
                      error={
                        localForm.errors.country && localForm.touched.country
                      }
                      helperText={
                        localForm.errors.country && localForm.touched.country
                          ? localForm.errors.country
                          : ''
                      }
                    />
                  );
                }}
              />
              <Autocomplete
                size='small'
                options={['standard']}
                disableClearable
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) =>
                  value && option === value
                }
                value={localForm.values.userType}
                onChange={(event, value) => {
                  localForm.setFieldValue('userType', value ?? '');
                }}
                onBlur={localForm.handleBlur}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label='User Type'
                      autoComplete='off'
                      error={
                        localForm.errors.userType && localForm.touched.userType
                      }
                      helperText={
                        localForm.errors.userType && localForm.touched.userType
                          ? localForm.errors.userType
                          : ''
                      }
                    />
                  );
                }}
              />
            </Stack>
          </DialogContent>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'stretch',
              marginLeft: 0,
              paddingTop: 0,
              gap: 1,
            }}
          >
            <Button
              type='submit'
              disabled={
                localForm.isSubmitting ||
                !(localForm.isValid && localForm.dirty)
              }
              variant={'contained'}
              fullWidth
              sx={{ gap: 1 }}
            >
              {localForm.isSubmitting && <CircularProgress size={'1rem'} />}
              Invite
            </Button>

            {localForm.alert && localForm.alert.type === 'error' && (
              <Alert severity='error' sx={{ background: '#ffc8c8' }}>
                {localForm.alert.msg}
              </Alert>
            )}
          </DialogContent>
        </form>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <DialogContent>
          <TextField
            size='small'
            label='Search'
            variant='outlined'
            sx={{
              backgroundColor: 'white',
              borderRadius: '5px',
              width: '50%',
            }}
            value={userSearchTerm}
            onChange={(e) => setUserSearchTerm(e.target.value.toLowerCase())}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </DialogContent>
      </TabPanel>
    </Dialog>
  );
}
