import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import BugReportIcon from "@mui/icons-material/BugReport";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; 
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PendingIcon from '@mui/icons-material/Pending';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EngineeringIcon from '@mui/icons-material/Engineering';
import EmailIcon from '@mui/icons-material/Email';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import WidgetsIcon from '@mui/icons-material/Widgets';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandLess';
import CelebrationIcon from '@mui/icons-material/Celebration';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import BuildIcon from '@mui/icons-material/Build';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoIcon from '@mui/icons-material/Info';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import LanguageIcon from '@mui/icons-material/Language';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LaunchIcon from '@mui/icons-material/Launch';

export {
    NotificationsIcon,
    NotificationsActiveIcon,
    CheckCircleOutlineIcon,
    SmartToyIcon,
    ConfirmationNumberIcon,
    BugReportIcon,
    MenuIcon,
    HomeIcon,
    DynamicFormIcon,
    AssessmentIcon,
    LogoutIcon,
    HelpIcon,
    CloseIcon,
    WarningIcon,
    SearchIcon,
    HelpOutlineIcon,
    ToggleOffIcon,
    ToggleOnIcon,
    EditIcon,
    HighlightOffIcon,
    PendingIcon,
    EngineeringIcon,
    LocationOnIcon,
    EmailIcon,
    SupervisorAccountIcon,
    HeadsetMicIcon,
    WidgetsIcon,
    SettingsIcon,
    ExpandLessIcon,
    ExpandMoreIcon,
    CelebrationIcon,
    NewReleasesIcon,
    BuildIcon,
    FileOpenIcon,
    CloudUploadIcon,
    CloudDownloadIcon,
    DeleteForeverIcon,
    ContentCopyIcon,
    FileUploadIcon,
    VisibilityIcon,
    InfoIcon,
    Brightness4Icon,
    LanguageIcon,
    Brightness7Icon,
    SettingsBrightnessIcon,
    AdminPanelSettingsIcon,
    NoAccountsIcon,
    AddIcon,
    CheckIcon,
    InfoOutlinedIcon,
    LaunchIcon
    };