import React from 'react';
import {
  EditIcon,
  CheckCircleOutlineIcon,
  HighlightOffIcon,
  PendingIcon,
} from '../assets/icons/icons';
import { Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { LanguageContext } from '../context/languageContext';
import { method } from 'lodash';

// RRF Report Configs
export const gamingRepairWorklogReport = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/rrf/gaming/repair/worklog/all`,
  columns: [
    {
      field: 'startDate',
      headerName: translations.startDate || 'Start Date',
      width: 200,
    },
    {
      field: 'completionDate',
      headerName: translations.completionDate || 'Completion Date',
      width: 200,
    },
    { field: 'name', headerName: translations.name || 'Name', width: 200 },
    {
      field: 'rmaNumber',
      headerName: translations.rmaNumber || 'RMA Number',
      width: 200,
    },
    {
      field: 'assetTag',
      headerName: translations.assetTag || 'Asset Tag',
      width: 200,
    },
    {
      field: 'serialNumber',
      headerName: translations.serialNumber || 'Serial Number',
      width: 200,
    },
    {
      field: 'failureMode',
      headerName: translations.failureMode || 'Failure Mode',
      width: 200,
    },
    {
      field: 'solution',
      headerName: translations.solution || 'Solution',
      width: 200,
    },
    {
      field: 'secondSolution',
      headerName: translations.secondSolution || 'Second Solution',
      width: 200,
    },
    {
      field: 'partsUsed',
      headerName: translations.partsUsed || 'Parts Used',
      width: 200,
    },
    {
      field: 'status',
      headerName: translations.status || 'Status',
      width: 200,
    },
    {
      field: 'workTime',
      headerName: translations.totalWorkTime || 'Total Work Time (minutes)',
      width: 200,
    },
    {
      field: 'isSecondTimeReturn',
      headerName: translations.secondReturn || 'Second Return',
      width: 200,
    },
    {
      field: 'returnDate',
      headerName: translations.returnDate || 'Return Date',
      width: 200,
    },
    {
      field: 'gamingLocation',
      headerName: translations.location || 'Location',
      width: 200,
    },
    {
      field: 'stoNumber',
      headerName: translations.stoNumber || 'STO Number',
      width: 200,
    },
    {
      field: 'itemNumber',
      headerName: translations.itemNumber || 'Item Number',
      width: 200,
    },
    {
      field: 'products',
      headerName: translations.products || 'Products',
      width: 200,
    },
    {
      field: 'comments',
      headerName: translations.comments || 'Comments',
      width: 400,
    },
  ],
});

export const lotteryRepairWorklogReport = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/rrf/lottery/repair/worklog/all`,
  columns: [
    {
      field: 'Action',
      headerName: translations.action || 'Action',
      width: 100,
      renderCell: params => <ActionCell {...params} />,
    },
    {
      field: 'date',
      headerName: translations.startDate || 'Start Date',
      width: 200,
    },
    {
      field: 'completionDate',
      headerName: translations.completionDate || 'Completion Date',
      width: 200,
    },
    {
      field: 'displayName',
      headerName: translations.name || 'Name',
      width: 200,
    },
    {
      field: 'emailAddress',
      headerName: translations.email || 'Email Address',
      width: 200,
    },
    {
      field: 'rmaNumber',
      headerName: translations.rmaNumber || 'RMA Number',
      width: 200,
      editable: true,
    },
    {
      field: 'assetTag',
      headerName: translations.assetTag || 'Asset Tag',
      width: 200,
      editable: true,
    },
    {
      field: 'serialNumber',
      headerName: translations.serialNumber || 'Serial Number',
      width: 200,
      editable: true,
    },
    {
      field: 'failureMode',
      headerName: translations.failureMode || 'Failure Mode',
      width: 200,
    },
    {
      field: 'solution',
      headerName: translations.solution || 'Solution',
      width: 200,
    },
    {
      field: 'secondSolution',
      headerName: translations.secondSolution || 'Second Solution',
      width: 200,
    },
    {
      field: 'partsUsed',
      headerName: translations.partsUsed || 'Parts Used',
      width: 200,
      editable: true,
    },
    {
      field: 'status',
      headerName: translations.status || 'Status',
      width: 200,
    },
    {
      field: 'workTime',
      headerName: translations.totalWorkTime || 'Total Work Time (minutes)',
      width: 200,
    },
    {
      field: 'isSecondTimeReturn',
      headerName: translations.secondReturn || 'Second Return',
      width: 200,
    },
    {
      field: 'returnDate',
      headerName: translations.returnDate || 'Return Date',
      width: 200,
    },
    {
      field: 'jurisdiction',
      headerName: translations.jurisdiction || 'Jurisdiction',
      width: 200,
    },
    {
      field: 'product',
      headerName: translations.product || 'Product',
      width: 200,
    },
    {
      field: 'reader',
      headerName: translations.readerItemNumber || 'Reader Item Number',
      width: 200,
    },
    {
      field: 'powerSupply',
      headerName:
        translations.powerSupplyItemNumber || 'Power Supply Item Number',
      width: 200,
    },
    {
      field: 'pcb',
      headerName: translations.pcbItemNumber || 'PCB Supply Item Number',
      width: 200,
    },
    {
      field: 'billAcceptor',
      headerName:
        translations.billAcceptorItemNumber || 'Bill Acceptor Item Number',
      width: 200,
    },
    {
      field: 'burster',
      headerName:
        translations.bursterItemNumber || 'Mini/Quad Burster Item Number',
      width: 200,
    },
    {
      field: 'comments',
      headerName: translations.comments || 'Comments',
      width: 400,
      editable: true,
    },
  ],
});

// FST Report Configs
export const fstOvertimeReport = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/fst/schedule/ot/`,
  columns: [
    {
      field: 'Action',
      headerName: translations.action || 'Action',
      width: 100,
      renderCell: params => <ActionCell {...params} />,
    },
    {
      field: 'status',
      headerName: translations.status || 'Status',
      width: 150,
      renderCell: params => {
        const { value } = params;
        const statusStyles = {
          APPROVED: {
            color: 'green',
            icon: (
              <CheckCircleOutlineIcon
                sx={{ marginLeft: 1, fontSize: 16, color: 'green' }}
              />
            ),
            label: translations.approved || 'Approved',
          },
          REJECTED: {
            color: 'red',
            icon: (
              <HighlightOffIcon
                sx={{ marginLeft: 1, fontSize: 16, color: 'red' }}
              />
            ),
            label: translations.rejected || 'Rejected',
          },
          PENDING: {
            color: '#ffc72c',
            icon: (
              <PendingIcon
                sx={{ marginLeft: 1, fontSize: 16, color: '#ffc72c' }}
              />
            ),
            label: translations.pending || 'Pending',
          },
        };
        const currentStatusStyle = statusStyles[value] || {
          color: 'inherit',
          icon: null,
        };
        return (
          <Typography
            variant='body2'
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 2,
              fontSize: 16,
              padding: '4px',
              borderRadius: '4px',
              border: `1px solid ${currentStatusStyle.color}`,
              color: currentStatusStyle.color,
            }}
          >
            {value}
            {currentStatusStyle.icon}
          </Typography>
        );
      },
    },
    {
      field: 'date',
      headerName: translations.date || 'Date',
      width: 200,
      editable: false,
    },
    {
      field: 'jurisdiction',
      headerName: translations.jurisdiction || 'Jurisdiction',
      width: 150,
      editable: false,
    },
    {
      field: 'fstNumber',
      headerName: translations.fstNumber || 'FST Number',
      width: 150,
      editable: false,
    },
    {
      field: 'emailAddress',
      headerName: translations.emailAddress || 'Email Address',
      width: 200,
      editable: false,
    },
    {
      field: 'displayName',
      headerName: translations.displayName || 'Display Name',
      width: 200,
      editable: false,
    },
    {
      field: 'manager',
      headerName: translations.manager || 'Manager',
      width: 150,
      editable: false,
    },
    {
      field: 'dateWorked',
      headerName: translations.dateWorked || 'Date Worked',
      width: 150,
      editable: false,
    },
    {
      field: 'overtimeLength',
      headerName: translations.overtimeLength || 'Overtime Length',
      width: 150,
      editable: true,
    },
    {
      field: 'penalty',
      headerName: translations.penalty || 'Penalty',
      width: 100,
      editable: false,
    },
    {
      field: 'reason',
      headerName: translations.reason || 'Reason',
      width: 150,
      editable: true,
    },
    {
      field: 'caseNumber',
      headerName: translations.caseNumber || 'Case Number',
      width: 150,
      editable: false,
    },
    {
      field: 'retailerId',
      headerName: translations.retailerId || 'Retailer ID',
      width: 150,
      editable: false,
    },
    {
      field: 'storeName',
      headerName: translations.storeName || 'Store Name',
      width: 150,
      editable: false,
    },
    {
      field: 'address',
      headerName: translations.address || 'Address',
      width: 150,
      editable: false,
    },
    {
      field: 'phoneNumber',
      headerName: translations.phoneNumber || 'Phone Number',
      width: 150,
      editable: false,
    },
    {
      field: 'caseReason',
      headerName: translations.caseReason || 'Case Reason',
      width: 150,
      editable: false,
    },
    {
      field: 'caseStatus',
      headerName: translations.caseStatus || 'Case Status',
      width: 150,
      editable: false,
    },
    {
      field: 'approvedBy',
      headerName: translations.approver || 'Approver',
      width: 150,
      editable: false,
    },
    {
      field: 'dateApproved',
      headerName: translations.approvalDate || 'Approval Date',
      width: 150,
      editable: false,
    },
    {
      field: 'comments',
      headerName: translations.comments || 'Comments',
      width: 150,
      editable: true,
    },
  ],
});

export const incorrectCaseReport = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/fst/incorrectcase/all`,
  columns: [
    { field: 'date', headerName: translations.date || 'Date', width: 150 },
    {
      field: 'jurisdiction',
      headerName: translations.jurisdiction || 'Jurisdiction',
      width: 150,
    },
    {
      field: 'fstNumber',
      headerName: translations.fstNumber || 'FST Number',
      width: 150,
    },
    {
      field: 'caseNumber',
      headerName: translations.caseNumber || 'Case Number',
      width: 200,
      editable: false,
    },
    {
      field: 'retailerId',
      headerName: translations.retailerId || 'Retailer ID',
      width: 200,
      editable: false,
    },
    {
      field: 'storeName',
      headerName: translations.storeName || 'Store Name',
      width: 150,
      editable: false,
    },
    {
      field: 'storeAddress',
      headerName: translations.storeAddress || 'Store Address',
      width: 150,
    },
    {
      field: 'closeDate',
      headerName: translations.closeDate || 'Close Date',
      width: 150,
    },
    {
      field: 'incorrectStatus',
      headerName: translations.incorrectStatus || 'Incorrect Status',
      width: 100,
    },
    {
      field: 'actionTaken',
      headerName: translations.actionTaken || 'Action Taken',
      width: 150,
    },
    { field: 'email', headerName: translations.email || 'Email', width: 150 },
    {
      field: 'displayName',
      headerName: translations.displayName || 'Display Name',
      width: 150,
    },
    {
      field: 'supervisor',
      headerName: translations.supervisor || 'Supervisor',
      width: 150,
      editable: false,
    },
    {
      field: 'createdBy',
      headerName: translations.createdBy || 'Created By',
      width: 150,
      editable: false,
    },
  ],
});

export const benchRepairReport = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/depot/benchrepair/all`,
  columns: [
    {
      field: 'repairOrder',
      headerName: 'Repair Order Number',
      width: 200,
    },
    {
      field: 'date',
      headerName: 'Date Submitted',
      width: 200,
    },
    {
      field: 'startDate',
      headerName: translations.startDate || 'Start Date',
      width: 200,
    },
    {
      field: 'completionDate',
      headerName: translations.completionDate || 'Completion Date',
      width: 200,
    },
    {
      field: 'openCase',
      headerName: translations.openCase || 'Open Case',
      width: 200,
    },
    {
      field: 'caseNumber',
      headerName: translations.caseNumber || 'Case Number',
      width: 200,
    },
    {
      field: 'oem',
      headerName: 'OEM',
      width: 200,
    },
    {
      field: 'rrfRepair',
      headerName: 'RRF Repair',
      width: 200,
    },
    {
      field: 'jurisdiction',
      headerName: 'Jurisdiction',
      width: 200,
    },
    {
      field: 'rmaNumber',
      headerName: 'RMA Number',
      width: 200,
    },
    {
      field: 'assetTag',
      headerName: translations.assetTag || 'Asset Tag',
      width: 200,
    },
    {
      field: 'serialNumber',
      headerName: translations.serialNumber || 'Serial Number',
      width: 200,
    },

    {
      field: 'failureMode',
      headerName: translations.failureMode || 'Failure Mode',
      width: 200,
    },
    {
      field: 'solutionOne',
      headerName: translations.solution || 'Solution 1',
      width: 200,
    },
    {
      field: 'solutionTwo',
      headerName: translations.secondSolution || 'Solution 2',
      width: 200,
    },
    {
      field: 'partsUsed',
      headerName: translations.partsUsed || 'Parts Used',
      width: 200,
    },
    {
      field: 'commDevice',
      headerName: translations.commDevices || 'Comm Device',
      width: 200,
    },
    {
      field: 'terminal',
      headerName: translations.terminal || 'Terminal',
      width: 200,
    },
    {
      field: 'printer',
      headerName: translations.printer || 'Printer',
      width: 200,
    },
    {
      field: 'peripherals',
      headerName: translations.peripherals || 'Peripherals',
      width: 200,
    },
    {
      field: 'displayMonitors',
      headerName: translations.displayMonitor || 'Display/Monitor',
      width: 200,
    },
    {
      field: 'status',
      headerName: translations.status || 'Status',
      width: 200,
    },
    {
      field: 'totalWorkTime',
      headerName: translations.totalWorkTime || 'Total Work Time (minutes)',
      width: 200,
    },
    {
      field: 'isSecondTimeReturn',
      headerName: translations.secondReturn || 'Second Return',
      width: 200,
    },
    {
      field: 'returnDate',
      headerName: translations.returnDate || 'Return Date',
      width: 200,
    },
    {
      field: 'product',
      headerName: translations.product || 'Product',
      width: 200,
    },
    {
      field: 'reader',
      headerName: translations.readerItemNumber || 'Reader Item Number',
      width: 200,
    },
    {
      field: 'powerSupply',
      headerName:
        translations.powerSupplyItemNumber || 'Power Supply Item Number',
      width: 200,
    },
    {
      field: 'pcb',
      headerName: translations.pcbItemNumber || 'PCB Supply Item Number',
      width: 200,
    },
    {
      field: 'billAcceptor',
      headerName:
        translations.billAcceptorItemNumber || 'Bill Acceptor Item Number',
      width: 200,
    },
    {
      field: 'burster',
      headerName:
        translations.quadBursterItemNumber || 'Mini/Quad Burster Item Number',
      width: 200,
    },
    {
      field: 'additionalEntriesCount',
      headerName: 'Additional Entries Count',
      width: 200,
    },

    {
      field: 'comments',
      headerName: translations.comments || 'Comments',
      width: 400,
    },
    {
      field: 'displayName',
      headerName: translations.name || 'Name',
      width: 200,
    },
  ],
});

// /////////// NRC Reports

// NRC Comm Audit Logs
export const commAuditLog = {
  // apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/contactcenter/comm/reboot/log`,
  apiEndpoint: `http://localhost:8080/v1/engage/contactcenter/comm/reboot/log`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    { field: 'locationId', headerName: 'Location ID', width: 200 },
    { field: 'hostname', headerName: 'Hostname', width: 200 },
    { field: 'serialNumber', headerName: 'Serial Number', width: 200 },
    { field: 'device', headerName: 'Device', width: 200 },
    { field: 'rebooted', headerName: 'Rebooted', width: 200 },
    { field: 'associateEmail', headerName: 'Associate', width: 200 },
  ],
};

// Ca-PTL Report
export const californiaPTLReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/californiaPTL/all`,
  // apiEndpoint: `http://localhost:8080/v1/engage/contactcenter/comm/reboot/log`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    { field: 'retailerId', headerName: 'Retailer ID', width: 200 },
    { field: 'retailerName', headerName: 'Retailer Name', width: 200 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
    { field: 'openCase', headerName: 'Open Case', width: 200 },
    { field: 'caseNumber', headerName: 'Case Number', width: 200 },
    { field: 'updateStoreHours', headerName: 'Update Store Hours', width: 200 },
    { field: 'sundayOpen', headerName: 'Sunday Open', width: 200 },
    { field: 'sundayClose', headerName: 'Sunday Close', width: 200 },
    { field: 'mondayOpen', headerName: 'Monday Open', width: 200 },
    { field: 'mondayClose', headerName: 'Monday Close', width: 200 },
    { field: 'tuesdayOpen', headerName: 'Tuesday Open', width: 200 },
    { field: 'tuesdayClose', headerName: 'Tuesday Close', width: 200 },
    { field: 'wednesdayOpen', headerName: 'Wednesday Open', width: 200 },
    { field: 'wednesdayClose', headerName: 'Wednesday Close', width: 200 },
    { field: 'thursdayOpen', headerName: 'Thursday Open', width: 200 },
    { field: 'thursdayClose', headerName: 'Thursday Close', width: 200 },
    { field: 'fridayOpen', headerName: 'Friday Open', width: 200 },
    { field: 'fridayClose', headerName: 'Friday Close', width: 200 },
    { field: 'saturdayOpen', headerName: 'Saturday Open', width: 200 },
    { field: 'saturdayClose', headerName: 'Saturday Close', width: 200 },
    { field: 'updatePhone', headerName: 'Update Phone', width: 200 },
    { field: 'newPhone', headerName: 'New Phone', width: 200 },
    { field: 'terminalType', headerName: 'Terminal Type', width: 200 },
    { field: 'keyRequest', headerName: 'Key Request', width: 200 },
    { field: 'messageToSite', headerName: 'Message to Site', width: 200 },
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const consumableSupplyReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/consply/all`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    { field: 'jurisdiction', headerName: 'Jurisdiction', width: 200 },
    { field: 'retailerId', headerName: 'Retailer ID', width: 200 },
    { field: 'retailerName', headerName: 'Retailer Name', width: 200 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
    { field: 'callerName', headerName: 'Caller Name', width: 200 },
    { field: 'adjustmentForms', headerName: 'Adjustment Forms', width: 200 },
    { field: 'allOrNothing', headerName: 'All Or Nothing', width: 200 },
    { field: 'badger5', headerName: 'Badger 5', width: 200 },
    { field: 'cash3', headerName: 'Cash 3', width: 200 },
    { field: 'cash4', headerName: 'Cash 4', width: 200 },
    { field: 'cash4Life', headerName: 'Cash 4 Life', width: 200 },
    { field: 'cash5', headerName: 'Cash 5', width: 200 },
    { field: 'cash5EzMatch', headerName: 'Cash 5 Ez Match', width: 200 },
    { field: 'cashPop', headerName: 'Cash Pop', width: 200 },
    { field: 'cashPopPlay', headerName: 'Cash Pop Play', width: 200 },
    { field: 'claimForms', headerName: 'Claim Forms', width: 200 },
    { field: 'classicLotto47', headerName: 'Classic Lotto 47', width: 200 },
    { field: 'clubKenoJack', headerName: 'Club Keno Jackpot', width: 200 },
    { field: 'daily3', headerName: 'Daily 3', width: 200 },
    { field: 'daily4', headerName: 'Daily 4', width: 200 },
    { field: 'dailyDerby', headerName: 'Daily Derby', width: 200 },
    { field: 'dailyKeno', headerName: 'Daily Keno', width: 200 },
    { field: 'envelopes', headerName: 'Envelopes', width: 200 },
    { field: 'fantasy5', headerName: 'Fantasy 5', width: 200 },
    { field: 'gaCashPop', headerName: 'GA Cash Pop', width: 200 },
    { field: 'georgia5', headerName: 'Georgia 5', width: 200 },
    { field: 'gopher5', headerName: 'Gopher 5', width: 200 },
    { field: 'hit5', headerName: 'Hit 5', width: 200 },
    { field: 'hotSpot', headerName: 'Hot Spot', width: 200 },
    {
      field: 'instantShowcaseRolls',
      headerName: 'Instant Showcase Rolls',
      width: 200,
    },
    {
      field: 'instantTicketTrackingForms',
      headerName: 'Instant Ticket Tracking Forms',
      width: 250,
    },
    { field: 'itvmRolls', headerName: 'ITVM Rolls', width: 200 },
    { field: 'jerseyCash5', headerName: 'Jersey Cash 5', width: 200 },
    { field: 'jumboBucks', headerName: 'Jumbo Bucks', width: 200 },
    { field: 'keno', headerName: 'Keno', width: 200 },
    { field: 'kenoPencils', headerName: 'Keno Pencils', width: 200 },
    { field: 'kenoToGo', headerName: 'Keno To Go', width: 200 },
    { field: 'kentucky5', headerName: 'Kentucky 5', width: 200 },
    { field: 'lotto', headerName: 'Lotto', width: 200 },
    { field: 'lottoAmerica', headerName: 'Lotto America', width: 200 },
    { field: 'lottoExtra', headerName: 'Lotto Extra', width: 200 },
    { field: 'luckyForLife', headerName: 'Lucky for Life', width: 200 },
    { field: 'match4', headerName: 'Match 4', width: 200 },
    { field: 'match5', headerName: 'Match 5', width: 200 },
    { field: 'megaBucks', headerName: 'Mega Bucks', width: 200 },
    { field: 'megaMillions', headerName: 'Mega Millions', width: 200 },
    { field: 'ncKenoMultiplier', headerName: 'NC Keno Multiplier', width: 200 },
    { field: 'newKenoRefresh', headerName: 'New Keno Refresh', width: 200 },
    { field: 'njCashPop4Board', headerName: 'NJ Cash Pop 4 Board', width: 200 },
    { field: 'northstar5', headerName: 'Northstar 5', width: 200 },
    { field: 'numbers', headerName: 'Numbers', width: 200 },
    { field: 'palmettoCash5', headerName: 'Palmetto Cash 5', width: 200 },
    { field: 'pencils', headerName: 'Pencils', width: 200 },
    { field: 'perforatedTape', headerName: 'Perforated Tape', width: 200 },
    { field: 'pick10', headerName: 'Pick 10', width: 200 },
    { field: 'pick2', headerName: 'Pick 2', width: 200 },
    { field: 'pick3', headerName: 'Pick 3', width: 200 },
    { field: 'pick4', headerName: 'Pick 4', width: 200 },
    { field: 'pick5', headerName: 'Pick 5', width: 200 },
    { field: 'pick6', headerName: 'Pick 6', width: 200 },
    { field: 'play3', headerName: 'Play 3', width: 200 },
    { field: 'play4', headerName: 'Play 4', width: 200 },
    { field: 'powerball', headerName: 'Powerball', width: 200 },
    { field: 'quickBucks', headerName: 'Quick Bucks', width: 200 },
    { field: 'quickDraw', headerName: 'Quick Draw', width: 200 },
    {
      field: 'quickDrawQuickDrawExtra',
      headerName: 'Quick Draw Quick Draw Extra',
      width: 250,
    },
    {
      field: 'quickDrawBullseye',
      headerName: 'Quick Draw Bullseye',
      width: 200,
    },
    { field: 'returnBags', headerName: 'Return Bags', width: 200 },
    { field: 'showMeCash', headerName: 'Show Me Cash', width: 200 },
    { field: 'superCash', headerName: 'Super Cash', width: 200 },
    { field: 'superLottoPlus', headerName: 'Super Lotto Plus', width: 200 },
    { field: 'take5', headerName: 'Take 5', width: 200 },
    {
      field: 'thermalTicketStock',
      headerName: 'Thermal Ticket Stock',
      width: 250,
    },
    { field: 'ticketStock', headerName: 'Ticket Stock', width: 200 },
    {
      field: 'ticketStockPrinterPaper',
      headerName: 'Ticket Stock (Printer Paper)',
      width: 250,
    },
    { field: 'tnCash', headerName: 'TN Cash', width: 200 },
    { field: 'tnDailyJackpot', headerName: 'TN Daily Jackpot', width: 200 },
    {
      field: 'tripleJackpotPlay',
      headerName: 'Triple Jackpot Play',
      width: 250,
    },
    { field: 'triplePlay', headerName: 'Triple Play', width: 200 },
    { field: 'ultraTicketStock', headerName: 'Ultra Ticket Stock', width: 300 },
    { field: 'win4', headerName: 'Win 4', width: 200 },
    {
      field: 'supplyChangeComments',
      headerName: 'Supply Change Comments',
      width: 250,
    },
    { field: 'origin', headerName: 'Origin', width: 200 },
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const dispatchStatusChangeReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/dispatchStatusChange/all`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    { field: 'jurisdiction', headerName: 'Jurisdiction', width: 200 }, // Added
    { field: 'retailerId', headerName: 'Retailer ID', width: 200 },
    { field: 'retailerName', headerName: 'Retailer Name', width: 200 }, // Added
    { field: 'address', headerName: 'Address', width: 200 }, // Added
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 }, // Added
    { field: 'contactName', headerName: 'Contact Name', width: 200 }, // Added
    { field: 'openCase', headerName: 'Open Case', width: 200 }, // Added
    { field: 'caseNumber', headerName: 'Case Number', width: 200 }, // Added
    { field: 'actionRequest', headerName: 'Action Request', width: 200 }, // Added
    { field: 'status', headerName: 'Status', width: 200 }, // Added
    { field: 'comments', headerName: 'Comments', width: 200 }, // Updated to match query
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const forwardToSiteReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/fts/all`,
  // apiEndpoint: `http://localhost:8080/v1/engage/contactcenter/comm/reboot/log`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    {
      field: 'jurisdiction',
      headerName: 'Jurisdiction',
      width: 200,
    },
    {
      field: 'retailerId',
      headerName: 'Retailer ID',
      width: 200,
    },
    {
      field: 'retailerName',
      headerName: 'Retailer Name',
      width: 200,
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 200,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 200,
    },
    { field: 'terminalType', headerName: 'Terminal Type', width: 200 },
    {
      field: 'retailerComplaint',
      headerName: 'Retailer Complaint',
      width: 200,
    },
    {
      field: 'thresholdExceeded',
      headerName: 'Threshold Exceeded',
      width: 200,
    },
    {
      field: 'supRequested',
      headerName: 'Supervisor Requested',
      width: 200,
    },
    {
      field: 'contactName',
      headerName: 'Contact Name',
      width: 200,
    },
    {
      field: 'callbackNumber',
      headerName: 'Callback Number',
      width: 200,
    },
    { field: 'openCase', headerName: 'Open Case', width: 200 },
    { field: 'caseNumber', headerName: 'Case Number', width: 200 },
    {
      field: 'communicationsType',
      headerName: 'Communications Type',
      width: 200,
    },
    {
      field: 'gnieArticleNumber',
      headerName: 'GNIE Article Number',
      width: 200,
    },
    {
      field: 'gnieArticleLink',
      headerName: 'GNIE Article Link',
      width: 200,
    },
    { field: 'messageToSite', headerName: 'Issue/Reason for Form', width: 200 },
    { field: 'fstAssigned', headerName: 'FST Assigned', width: 200 },
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const gnieUpdateRequestReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/gnieUpdate/all`,
  // apiEndpoint: `http://localhost:8080/v1/engage/contactcenter/comm/reboot/log`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    {
      field: 'jurisdiction',
      headerName: 'Jurisdiction',
      width: 200,
    },
    {
      field: 'gnieArticleNumber',
      headerName: 'GNIE Article Number',
      width: 200,
    },
    {
      field: 'gnieArticleLink',
      headerName: 'GNIE Article Link',
      width: 200,
    },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const leewardIslandsCreditLogReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/liCreditLog/all`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    { field: 'retailerId', headerName: 'Retailer ID', width: 200 },
    { field: 'retailerName', headerName: 'Retailer Name', width: 200 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
    { field: 'callerName', headerName: 'Caller Name', width: 200 },
    { field: 'logNumber', headerName: 'Log Number', width: 200 },
    { field: 'dateTkt', headerName: 'Date Ticket', width: 200 },
    { field: 'originalTkt', headerName: 'Original Ticket', width: 200 },
    {
      field: 'reprintSalesNumber',
      headerName: 'Reprint Sales Number',
      width: 200,
    },
    { field: 'gamePlayed', headerName: 'Game Played', width: 200 },
    { field: 'amount', headerName: 'Amount', width: 200 },
    { field: 'comments', headerName: 'Comments', width: 300 },

    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const miIncidentLogReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/miIncidentLog/all`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    { field: 'retailerId', headerName: 'Retailer ID', width: 200 },
    { field: 'retailerName', headerName: 'Retailer Name', width: 200 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
    { field: 'retailerServices', headerName: 'Retailer Services', width: 200 },
    {
      field: 'transferredToSiteOperations',
      headerName: 'Transferred to Site Operations',
      width: 200,
    },
    {
      field: 'alturaAdjustmentForms',
      headerName: 'Altura Adjustment Forms',
      width: 300,
    },
    { field: 'comments', headerName: 'Comments', width: 300 },

    { field: 'callerName', headerName: 'Caller Name', width: 200 },
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const nrcDowntimeReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/nrcDowntime/all`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    { field: 'retailerId', headerName: 'Retailer ID', width: 200 },
    { field: 'retailerName', headerName: 'Retailer Name', width: 200 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
    { field: 'dispatchStatus', headerName: 'Dispatch Status', width: 200 }, // Updated
    { field: 'disposition', headerName: 'Disposition', width: 200 }, // Updated
    { field: 'reason', headerName: 'Reason', width: 200 }, // Updated
    { field: 'issue', headerName: 'Issue', width: 200 }, // Updated
    { field: 'caseNotes', headerName: 'Case Notes', width: 200 }, // Updated
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const nyAmLogReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/nyAMLog/all`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    { field: 'retailerId', headerName: 'Retailer ID', width: 200 },
    { field: 'retailerName', headerName: 'Retailer Name', width: 200 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
    { field: 'callerName', headerName: 'Caller Name', width: 200 },
    { field: 'agentNumber', headerName: 'Agent Number', width: 200 },
    { field: 'amServiceRequest', headerName: 'AM Service Request', width: 200 },
    {
      field: 'reasonForDispatch',
      headerName: 'Reason for Dispatch',
      width: 300,
    },
    { field: 'issue', headerName: 'Issue', width: 300 },
    { field: 'notes', headerName: 'Notes', width: 300 },
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const nyKeyRequestReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/nyKeyRequest/all`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    { field: 'retailerId', headerName: 'Retailer ID', width: 200 },
    { field: 'retailerName', headerName: 'Retailer Name', width: 200 }, // Added
    { field: 'address', headerName: 'Address', width: 200 }, // Added
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 }, // Added
    { field: 'contactName', headerName: 'Contact Name', width: 200 }, // Added
    { field: 'terminalType', headerName: 'Terminal Type', width: 200 }, // Added
    { field: 'serialNumber', headerName: 'Serial Number', width: 200 }, // Added
    { field: 'keyRequested', headerName: 'Key Requested', width: 200 }, // Updated
    { field: 'comments', headerName: 'Comments', width: 200 },
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const retailerInfoChangeReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/retailerInfoChange/all`,
  // apiEndpoint: `http://localhost:8080/v1/engage/contactcenter/comm/reboot/log`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    {
      field: 'retailerId',
      headerName: 'Retailer ID',
      width: 200,
    },
    {
      field: 'jurisdiction',
      headerName: 'Jurisdiction',
      width: 200,
    },
    {
      field: 'retailerName',
      headerName: 'Retailer Name',
      width: 200,
    },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
    {
      field: 'terminal',
      headerName: 'Terminal',
      width: 200,
    },
    {
      field: 'associatedTerminals',
      headerName: 'Associated Terminals',
      width: 200,
    },
    { field: 'updatePhone', headerName: 'Updated Phone', width: 200 },
    { field: 'newPhone', headerName: 'New Phone Number', width: 200 },
    {
      field: 'requestedPasscode',
      headerName: 'Requested Passcode',
      width: 200,
    },
    { field: 'oldPasscode', headerName: 'Old Passcode', width: 200 },
    { field: 'newPasscode', headerName: 'New Passcode', width: 200 },
    { field: 'managerPasscode', headerName: 'Manager Passcode', width: 200 },
    { field: 'requestedBy', headerName: 'Requested By', width: 200 },
    { field: 'updateStoreHours', headerName: 'Update Store Hours', width: 200 },
    { field: 'sundayOpen', headerName: 'Sunday Open', width: 200 },
    { field: 'sundayClose', headerName: 'Sunday Close', width: 200 },
    { field: 'mondayOpen', headerName: 'Monday Open', width: 200 },
    { field: 'mondayClose', headerName: 'Monday Close', width: 200 },
    { field: 'tuesdayOpen', headerName: 'Tuesday Open', width: 200 },
    { field: 'tuesdayClose', headerName: 'Tuesday Close', width: 200 },
    { field: 'wednesdayOpen', headerName: 'Wednesday Open', width: 200 },
    { field: 'wednesdayClose', headerName: 'Wednesday Close', width: 200 },
    { field: 'thursdayOpen', headerName: 'Thursday Open', width: 200 },
    { field: 'thursdayClose', headerName: 'Thursday Close', width: 200 },
    { field: 'fridayOpen', headerName: 'Friday Open', width: 200 },
    { field: 'fridayClose', headerName: 'Friday Close', width: 200 },
    { field: 'saturdayOpen', headerName: 'Saturday Open', width: 200 },
    { field: 'saturdayClose', headerName: 'Saturday Close', width: 200 },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 200,
    },
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const riCreditLogReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/riCreditLog/all`,
  // apiEndpoint: `http://localhost:8080/v1/engage/contactcenter/comm/reboot/log`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    { field: 'retailerId', headerName: 'Retailer ID', width: 200 },
    { field: 'retailerName', headerName: 'Retailer Name', width: 200 }, // Added
    { field: 'address', headerName: 'Address', width: 200 }, // Added
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 }, // Added
    { field: 'logNumber', headerName: 'Log Number', width: 200 },
    {
      field: 'transactionNumber',
      headerName: 'Transaction Number',
      width: 200,
    },
    {
      field: 'reprintSalesNumber',
      headerName: 'Reprint Sales Number',
      width: 200,
    },
    { field: 'gamePlayed', headerName: 'Game Played', width: 200 },
    { field: 'amount', headerName: 'Amount', width: 200 },
    { field: 'reason', headerName: 'Reason', width: 300 },
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const washingtonRetailerTrainingRequestReport = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPORTING}/uscc/waRetailerTraining/all`,
  // apiEndpoint: `http://localhost:8080/v1/engage/contactcenter/comm/reboot/log`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    {
      field: 'retailerId',
      headerName: 'Retailer ID',
      width: 200,
    },
    {
      field: 'retailerName',
      headerName: 'Retailer Name',
      width: 200,
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 200,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 200,
    },
    {
      field: 'callersName',
      headerName: "Caller's Name",
      width: 200,
    },
    {
      field: 'trainingType',
      headerName: 'Training Type',
      width: 200,
    },
    {
      field: 'trainingDate',
      headerName: 'Desired Training Date',
      width: 200,
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 200,
    },
    { field: 'displayName', headerName: 'Associate', width: 200 },
  ],
};

export const ActionCell = ({ id, handleShow }) => (
  <GridActionsCellItem
    icon={<EditIcon sx={{ color: '#ffc72c' }} />}
    onClick={() => handleShow(id)}
  />
);

// Czech  CC Configs
export const czSurvey = translations => ({
  apiEndpoint:
    'https://genesyscloud-logic-01.azurewebsites.net:443/api/get-cz-cc-survey/triggers/When_a_HTTP_request_is_received/invoke?api-version=2022-05-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=NmLSX40arfikc36KAXzzW64m-w07PVoaMmSaHRpnmtI',
  columns: [
    {
      field: 'id',
      headerName: 'id',
      width: 100,
    },
    {
      field: 'date',
      headerName: translations.date || 'date',
      width: 100,
    },
    {
      field: 'conversationId',
      headerName: translations.conversationId || 'Conversation ID',
      width: 100,
    },
    {
      field: 'ani',
      headerName: 'Ani',
      width: 100,
    },
    {
      field: 'score1',
      headerName: translations.score1 || 'Score 1',
      width: 100,
    },
    {
      field: 'score2',
      headerName: translations.score2 || 'Score 2',
      width: 100,
    },
    {
      field: 'interactionType',
      headerName: translations.interactionType || 'Interaction Type',
      width: 100,
    },
  ],
});
