import React, {useContext} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { LanguageContext } from '../context/languageContext';

const UploadDialog = ({
  open,
  onClose,
  handleSubmit,
  articleID,
  setArticleID,
  userName,
  setUserName,
  selectedFile,
  setSelectedFile,
}) => {
  
  const translations = React.useContext(LanguageContext);
  return (

<Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ color: '#ff671f', fontSize: '25px', fontWeight: 'bold' }}>
        Upload File
      </DialogTitle>
      <DialogContent sx={{ height: '300px' }}>
        <TextField
          margin="dense"
          id="articleID"
          label={translations.articleID || 'Article ID' }
          type="text"
          fullWidth
          value={articleID}
          onChange={(e) => setArticleID(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="userName"
          label={translations.yourName || 'Your Name'}
          type="text"
          fullWidth
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          sx={{ marginTop: '10px' }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            accept="image/*, .pdf, .txt, .xlsx, .csv, .doc, .docx"
            style={{ display: 'none' }}
            id="file-upload"
            type="file"
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
          <label htmlFor="file-upload">
            <Button
              component="span"
              variant="contained"
              sx={{
                fontSize: '15px',
                backgroundColor: '#0c51a1',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#1b9ddb',
                },
              }}
            >
              {translations.chooseFile || 'Choose File'}
            </Button>
          </label>
          {selectedFile && <p>Selected File: {selectedFile.name}</p>}
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', width: '100%' }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              fontSize: '15px',
              backgroundColor: '#ff671f',
              color: 'white',
              marginRight: '15px',
              '&:hover': {
                backgroundColor: '#ffa300',
              },
            }}
          >
            {translations.upload || 'Upload'}
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            sx={{ background: 'grey', fontSize: '15px' }}
          >
            {translations.cancel || 'Cancel'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
