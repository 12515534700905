import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./authContext";

// ProtectedRoute component with path-based access control for authenticated users
const ProtectedRoute = ({ element, path }) => {
  // Get authentication state and user's accessible paths from the auth context
  const { isAuthenticated, accessiblePaths } = useAuth();
  // Get the current location
  const location = useLocation();

  // Check if the path is in the user's accessiblePaths
  const hasPermission = isAuthenticated && accessiblePaths.includes(path);

  // Redirect to login page if the user is not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (!hasPermission) { // Redirect to denied page if the user does not have permission
    return <Navigate to="/denied" state={{ from: location }} replace />;
  }

  return element;
};

export { ProtectedRoute };
