import { DialogContent, CircularProgress, Button, Alert } from "@mui/material";

export default function AuthModalFooter({
  isSubmitting,
  isValid,
  dirty,
  alert,
  buttonText,
}) {
  return (
    <DialogContent
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "stretch",
        marginLeft: 0,
        paddingTop: 0,
        gap: 1,
      }}
    >
      <Button
        type='submit'
        disabled={isSubmitting || !(isValid && dirty)}
        variant={"contained"}
        fullWidth
        sx={{ gap: 1 }}
      >
        {isSubmitting && <CircularProgress size={"1rem"} />}
        {buttonText}
      </Button>

      {alert && (
        <Alert
          severity={alert.type}
          sx={{ background: alert.type === "error" ? "#ffc8c8" : "#caffc8" }}
        >
          {alert.msg}
        </Alert>
      )}
    </DialogContent>
  );
}
