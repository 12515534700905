// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    padding: 4rem;
}

/* .cards-container {
    display: flex;
    flex-wrap: wrap;
} */

.card {
    margin: 2rem 2rem 2rem 0;
    padding: 0.5rem;
    box-shadow: 0 0 28px 4px rgba(0,0,0,0.1);
    /* transition: transform .3s ease; */
    /* cursor: pointer; */
    
}

/* .card:hover{
    transform: scale(1.1);
}

*/

.card__title{
    font-weight: 800;
    font-size: 1.5rem;
} 

.card__banner{
    display: flex;
    justify-content: space-between;
}

.card__image{
    display: flex;
    justify-content: flex-start;
}

.card__image img{
    border-radius: 50%;
    height: 50px;
}

.location{
    margin: 1rem 0;
}

.location p{
    margin: 0;
    font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/views/rss/rssstyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;;;GAGG;;AAEH;IACI,wBAAwB;IACxB,eAAe;IACf,wCAAwC;IACxC,oCAAoC;IACpC,qBAAqB;;AAEzB;;AAEA;;;;CAIC;;AAED;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,SAAS;IACT,iBAAiB;AACrB","sourcesContent":[".App {\n    padding: 4rem;\n}\n\n/* .cards-container {\n    display: flex;\n    flex-wrap: wrap;\n} */\n\n.card {\n    margin: 2rem 2rem 2rem 0;\n    padding: 0.5rem;\n    box-shadow: 0 0 28px 4px rgba(0,0,0,0.1);\n    /* transition: transform .3s ease; */\n    /* cursor: pointer; */\n    \n}\n\n/* .card:hover{\n    transform: scale(1.1);\n}\n\n*/\n\n.card__title{\n    font-weight: 800;\n    font-size: 1.5rem;\n} \n\n.card__banner{\n    display: flex;\n    justify-content: space-between;\n}\n\n.card__image{\n    display: flex;\n    justify-content: flex-start;\n}\n\n.card__image img{\n    border-radius: 50%;\n    height: 50px;\n}\n\n.location{\n    margin: 1rem 0;\n}\n\n.location p{\n    margin: 0;\n    font-size: 0.8rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
