import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Avatar } from '@mui/material';
import { useAuth } from '../context/authContext';
import Cookies from 'js-cookie';


function UserProfileContainer() {
	const { getToken } = useAuth(); // Get the getToken function from the auth context
	const [userDetails, setUserDetails] = useState(null); // State to store user details
	const [profilePhoto, setProfilePhoto] = useState(null); // State to store profile photo
	const [isLoading, setIsLoading] = useState(true); // State to track loading state

  // Endpoint for fetching user details from the backend
  const usersEndpoint = process.env.REACT_APP_ENDPOINT_USERS;

  // Fetch user details and profile photo on component mount using useEffect
  useEffect(() => {
    // Function to fetch user details from the backend using the access token
    const fetchUserDetails = async () => {
      const token = Cookies.get('token') || Cookies.get('localToken'); // Get the token from cookies
      setIsLoading(true);
      // Ensure token is available before making the request to the backend
      if (!token) {
        console.error('Access token is not available.');
        setIsLoading(false);
        return;
      }
      // Fetch user details from the backend using the access token
			try {
				const response = await axios.get(`${usersEndpoint}/user-details`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (response.data) {
					setUserDetails(response.data);
					fetchProfilePhoto(token); // Fetch profile photo after user details are fetched
				}
			} catch (error) {
				console.error('Failed to fetch user details:', error);
				setUserDetails({}); // Ensure userDetails is set to an empty object on error
			} finally {
				setIsLoading(false);
			}
		};

		fetchUserDetails();
	}, []);


	const fetchProfilePhoto = async (token) => {
		try {
			const graphResponse = await fetch(
				'https://graph.microsoft.com/v1.0/me/photo/$value',
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (graphResponse.ok) {
				const photoData = await graphResponse.blob(); // Convert response to Blob
				const photoUrl = URL.createObjectURL(photoData);
				setProfilePhoto(photoUrl); // Set profile photo URL
			} else {
				console.error(
					'Failed to fetch profile photo:',
					graphResponse.statusText
				);
			}
		} catch (error) {
			console.error('Error fetching profile photo:', error);
		}
	};

	// Render generic info if userDetails is null or an empty object
	if (!userDetails || Object.keys(userDetails).length === 0) {
		return (
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				bgcolor="#08315f"
				color="#fff"
				padding="20px"
				boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
				height="100vh"
				width="10%"
				position="fixed"
				sx={{
					'@media (max-width: 600px)': {
						width: '100%',
						borderRadius: 0,
						position: 'relative',
						height: 'auto',
						boxShadow: 'none',
					},
				}}
			>
				<Avatar
					sx={{
						width: 100,
						height: 100,
						marginBottom: 2,
						border: '2px solid #ffa600',
					}}
				/>
				<Typography
					variant="h6"
					gutterBottom
					sx={{ fontSize: '1rem', textAlign: 'center' }}
				>
					Unknown User
				</Typography>
				<Typography
					variant="body1"
					gutterBottom
					sx={{ fontSize: '0.9rem', textAlign: 'center' }}
				>
					Job title | Department
				</Typography>
				<Typography
					variant="body1"
					gutterBottom
					sx={{ fontSize: '0.9rem', textAlign: 'center' }}
				>
					Office Location
				</Typography>
			</Box>
		);
	}

	// Render user profile if userDetails is available
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			bgcolor="#08315f"
			color="#fff"
			padding="20px"
			boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
			height="100vh"
			width="10%"
			position="fixed"
			sx={{
				'@media (max-width: 600px)': {
					width: '100%',
					borderRadius: 0,
					position: 'relative',
					height: 'auto',
					boxShadow: 'none',
				},
			}}
		>
			{profilePhoto ? (
				<Avatar
					sx={{
						width: 100,
						height: 100,
						marginBottom: 2,
						border: '2px solid #ffa600',
					}}
					src={profilePhoto}
					alt="User Profile"
				/>
			) : (
				<Avatar
					sx={{
						width: 100,
						height: 100,
						marginBottom: 2,
						border: '2px solid #ffa600',
					}}
				>
					U
				</Avatar>
			)}
			<Typography
				variant="h6"
				gutterBottom
				sx={{ fontSize: '1rem', textAlign: 'center' }}
			>
				{userDetails.displayName || 'Unknown User'}
			</Typography>
			<Typography
				variant="body1"
				gutterBottom
				sx={{ fontSize: '0.9rem', textAlign: 'center' }}
			>
				{userDetails.jobTitle || ' job title'} |{' '}
				{userDetails.department || 'department'}
			</Typography>
			<Typography
				variant="body1"
				gutterBottom
				sx={{ fontSize: '0.9rem', textAlign: 'center' }}
			>
				{userDetails.officeLocation || 'office location'}
			</Typography>
		</Box>
	);
}

export default UserProfileContainer;
