import React from 'react';
import { TextField, Grid, Typography, Button, Box } from '@mui/material';

const GeneralInfo = ({ user }) => {
  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Basic Information</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="User Name" value={user.userName} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Display Name" value={user.displayName} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="First Name" value={user.firstName} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Last Name" value={user.lastName} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Email" value={user.email} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Job Title" value={user.jobTitle} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Department" value={user.department} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Office Location" value={user.officeLocation} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Country" value={user.country} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="User Type" value={user.userType} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Status" value={user.status} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="SSO Enabled" value={user.ssoEnabled === 1 ? 'Yes' : 'No'} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Active" value={user.active === 1 ? 'Yes' : 'No'} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Last Login Date" value={user.lastLoginDate} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Creation Date" value={user.creationDate} variant="outlined" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Last Modified Date" value={user.lastModified} variant="outlined" disabled />
        </Grid>

        <Grid item xs={12}>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralInfo;
