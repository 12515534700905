import React, { useContext } from 'react';
import {
  CloudDownloadIcon,
  DeleteForeverIcon,
  ContentCopyIcon,
} from '../assets/icons/icons';
import { Box, Tooltip, IconButton } from '@mui/material';
import { LanguageContext } from '../context/languageContext';

// FunctionsCell component with handlers passed as props
export const FunctionsCell = ({
  row,
  handleCopyFileLink,
  handleDownload,
  handleDelete,
}) => {
  const translations = useContext(LanguageContext);

  return (
    <Box display='flex' alignItems='center'>
      <Tooltip title={translations.copyFileLink || 'Copy File Link'}>
        <IconButton onClick={() => handleCopyFileLink(row)} size='small'>
          <ContentCopyIcon sx={{ color: '#ff671f', fontSize: '30px' }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={translations.downloadFile || 'Download File'}>
        <IconButton onClick={() => handleDownload(row)} size='small'>
          <CloudDownloadIcon sx={{ color: '#0c51a1', fontSize: '30px' }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={translations.deleteFile || 'Delete File'}>
        <IconButton
          onClick={() => handleDelete(row)}
          size='small'
          color='error'
        >
          <DeleteForeverIcon sx={{ fontSize: '30px' }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

// NRC retailer file system configuration
export const retailerFilesDataGrid = (
  translations,
  handleCopyFileLink,
  handleDownload,
  handleDelete
) => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FILES}/us/cc/retailer/files`,
  columns: [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'articleID',
      headerName: translations.articleID || 'Article ID',
      flex: 1,
    },
    {
      field: 'fileName',
      headerName: translations.fileName || 'File Name',
      flex: 2,
    },
    {
      field: 'fileType',
      headerName: translations.fileType || 'File Type',
      flex: 1,
    },
    {
      field: 'functions',
      headerName: translations.actions || 'Actions',
      flex: 1,
      renderCell: (params) => (
        <FunctionsCell
          row={params.row}
          handleCopyFileLink={handleCopyFileLink}
          handleDownload={handleDownload}
          handleDelete={handleDelete}
        />
      ),
    },
    {
      field: 'createdBy',
      headerName: translations.createdBy || 'Created By',
      flex: 1,
    },
  ],
});

// GCS file system configuration
export const gcsFilesDataGrid = (
  translations,
  handleCopyFileLink,
  handleDownload,
  handleDelete
) => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_FILES}/us/gcs/files`,
  columns: [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'articleID',
      headerName: translations.articleID || 'Article ID',
      flex: 1,
    },
    {
      field: 'fileName',
      headerName: translations.fileName || 'File Name',
      flex: 2,
    },
    {
      field: 'fileType',
      headerName: translations.fileType || 'File Type',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: translations.actions || 'Actions',
      flex: 1,
      renderCell: (params) => (
        <FunctionsCell
          row={params.row}
          handleCopyFileLink={handleCopyFileLink}
          handleDownload={handleDownload}
          handleDelete={handleDelete}
        />
      ),
    },
    {
      field: 'createdBy',
      headerName: translations.createdBy || 'Created By',
      flex: 1,
    },
  ],
});
