import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Typography,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  Radio,
  Snackbar,
  Box,
  Divider,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { commDevice, status as statusOptions } from '../utils/formDataConfigs';
import {
  fstLotteryProducts,
  solutions,
  yesNoOptions,
  lotteryFailureModes,
  billAcceptorNumberItems,
  quadBursterNumberItems,
  displayMonitor,
  pcbItemNumbers,
  peripherals,
  powerSupplyItemNumbers,
  printers,
  readerItemNumbers,
  terminals,
} from '../utils/formDataConfigs';

// Define the renderField function
const renderField = (field, value, handleChange, handleTimeChange) => {
  const readOnlyFields = [
    'userId',
    'email',
    'displayName',
    'emailAddress',
    'dateCurrent',
  ]; // Define read-only field names

  // Function to format the date as MM/DD/YYYY
  const getCurrentDate = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    // setCurrentDate(`${month}/${day}/${year}`);
    // console.log(`${month}/${day}/${year}`);
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  };

  switch (field.type) {
    case 'header': // New case for header text
      return (
        <Typography
          key={field.name}
          variant='h5'
          align='center' // Center the header text
          gutterBottom
          style={{ marginTop: '20px', marginBottom: '20px' }}
        >
          {field.label}
        </Typography>
      );
    case 'text':
      return (
        <TextField
          fullWidth
          key={field.name}
          name={field.name}
          label={field.label}
          value={value}
          onChange={handleChange}
          required={field.required}
          multiline={field.multiline}
          rows={field.rows}
          helperText={field.helperText}
          disabled={readOnlyFields.includes(field.name)}
        />
      );
    case 'textDefault': // New case for fields with default value
      return (
        <TextField
          fullWidth
          key={field.name}
          name={field.name}
          label={field.label}
          value={value}
          defaultValue={field.defaultValue}
          disabled={true}
        />
      );
    case 'number':
      return (
        <TextField
          fullWidth
          key={field.name}
          name={field.name}
          placeholder={field.label}
          type='number'
          value={value}
          onChange={handleChange}
          required={field.required}
          inputProps={{ step: 5 }}
        />
      );
    case 'amount': // New case for amount with USD unit
      return (
        <TextField
          fullWidth
          key={field.name}
          name={field.name}
          label={field.label}
          type='number'
          value={value}
          onChange={handleChange}
          required={field.required}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>, // Prefix with $ for USD
          }}
          helperText={field.helperText}
        />
      );
    case 'date':
      return (
        <TextField
          fullWidth
          key={field.name}
          name={field.name}
          label={field.label}
          type='date'
          value={value}
          onChange={handleChange}
          required={field.required}
          helperText={field.helperText}
          InputLabelProps={{ shrink: true }}
        />
      );
    case 'dateCurrent':
      return (
        <TextField
          fullWidth
          key={field.name}
          name={field.name}
          label={field.label}
          value={getCurrentDate()}
          onChange={handleChange}
          required={field.required}
          multiline={field.multiline}
          rows={field.rows}
          helperText={field.helperText}
          disabled={readOnlyFields.includes(field.name)}
        />
      );
    case 'dateTime':
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs} key={field.name}>
          <DateTimePicker
            label={field.label}
            value={value}
            onChange={newValue =>
              handleChange({ target: { name: field.name, value: newValue } })
            }
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                key={field.name}
                name={field.name}
                label={field.label}
                required={field.required}
                helperText={field.helperText}
              />
            )}
          />
        </LocalizationProvider>
      );
    case 'select':
      return (
        <FormControl fullWidth key={field.name}>
          <InputLabel id={`${field.label}-label`}>{field.label}</InputLabel>
          <Select
            labelId={`${field.label}-label`}
            label={field.label}
            name={field.name}
            value={value}
            onChange={handleChange}
            required={field.required}
          >
            {Array.isArray(field.options) &&
              field.options.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{field.helperText}</FormHelperText>
        </FormControl>
      );
    case 'selectTime':
      return (
        <FormControl fullWidth key={field.name}>
          <InputLabel>{field.label}</InputLabel>
          <Select
            name={field.name}
            value={value}
            onChange={handleChange}
            required={field.required}
          >
            {Array.isArray(field.options) &&
              field.options.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    case 'multiSelect':
      return (
        <FormControl fullWidth key={field.name}>
          <InputLabel>{field.label}</InputLabel>
          <Select
            name={field.name}
            value={Array.isArray(value) ? value : []}
            onChange={handleChange}
            required={field.required}
            multiple
            renderValue={selected => selected.join(', ')} // Display selected values as comma-separated string
          >
            {Array.isArray(field.options) &&
              field.options.map(option => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={value.indexOf(option) > -1} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    case 'radio':
      return (
        <FormControl component='fieldset' key={field.name}>
          <Typography variant='body1'>{field.label}</Typography>
          <RadioGroup
            row
            name={field.name}
            value={value}
            onChange={handleChange}
            required={field.required}
          >
            {Array.isArray(field.options) &&
              field.options.map(option => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
          </RadioGroup>
          <FormHelperText>{field.helperText}</FormHelperText>
        </FormControl>
      );
    default:
      return null;
  }
};

// FormComponent definition
const FormComponent = ({
  formConfig,
  userDetails,
  onChange,
  onSubmit,
  translations,
  setShowForm,
}) => {
  // Initialize form data state
  const [formData, setFormData] = useState(
    formConfig.fields.reduce((acc, field) => {
      acc[field.name] = field.type === 'multiSelect' ? [] : field.value || ''; // Set initial value or default to empty string
      return acc;
    }, {})
  );

  useEffect(() => {
    // If formData already has jurisdiction, retain that value
    const initialFormData = formConfig.fields.reduce((acc, field) => {
      acc[field.name] = formData[field.name] || field.defaultValue || ''; // Keep existing value, or use default
      return acc;
    }, {});

    setFormData(initialFormData); // Reset form data to initial state
  }, [formConfig]);

  // Add new state variables for storing the retailer details
  const [retailerName, setRetailerName] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [zoneId, setZoneId] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [duplicatedFields, setDuplicatedFields] = useState([]);
  const [nextId, setNextId] = useState(2); // Start from 1

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLookup, setIsLookup] = useState(false);
  const [isBenchRepair, setIsBenchRepair] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Track the values of 'isSecondTimeReturn', 'isTech', and 'openCase'
  const isSecondTimeReturn = formData.isSecondTimeReturn;
  const isTech = formData.isTech;
  const openCase = formData.openCase;
  const ftsJid = formData.jurisdiction;

  useEffect(() => {
    if (userDetails) {
      setFormData(prevData => ({
        ...prevData,
        email: userDetails.email,
        emailAddress: userDetails.email,
        userId: userDetails.userId,
        displayName: userDetails.displayName,
      }));
    }
  }, [userDetails]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    onChange({ ...formData, [name]: value }); // Notify parent component of change
    // console.log(`Field ${name} changed to ${value}`);
  };

  // Define the initial state based on formConfig.fields
  const initialFormData = formConfig.fields.reduce((acc, field) => {
    acc[field.name] = field.type === 'multiSelect' ? [] : ''; // Clear multiSelect as an empty array, others as empty strings
    return acc;
  }, {});

  // const navigate = useNavigate();

  // Handle form field changes
  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);

    // Base form data
    let submissionData = {
      ...formData, // Main form fields
    };

    // Only include additionalEntries if duplicates exist
    if (duplicatedFields.length > 0) {
      submissionData.additionalEntries = duplicatedFields;
    }

    try {
      const response = await axios({
        method: formConfig.method,
        url: formConfig.apiEndpoint,
        data: submissionData,
      });

      console.log('Form submitted successfully:', response.data);
      onSubmit(response.data);

      // Show success message
      setSnackbarConfig({
        open: true,
        message: 'Form successfully submitted.',
        severity: 'success',
      });

      // Check if the "team" query parameter is "NRC" and refresh the page. If not NRC, the handleSubmit will proceed as usual
      // Get the "team" parameter from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const team = urlParams.get('team');

      // Set different snackbar messages based on the value of "team"
      if (team === 'NRC') {
        setSnackbarConfig({
          open: true,
          message:
            'Form successfully submitted. Page will refresh in 5 seconds.',
          severity: 'success',
        });

        // Refresh the page after 5 seconds if team is NRC
        setTimeout(() => {
          window.location.reload();
        }, 5000); // 5000 milliseconds = 5 seconds
      } else {
        setSnackbarConfig({
          open: true,
          message: 'Form successfully submitted. Thank you.',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbarConfig({
        open: true,
        message: 'Error submitting form. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsSubmitting(false); // Stop showing the loading indicator after submission
    }
  };

  // Check if 'retailerId' exists in formConfig.fields
  const hasRetailerIdField = formConfig.fields.some(
    field => field.name === 'retailerId'
  );

  const handleRetailerLookup = async e => {
    e.preventDefault();
    const jurisdiction = formData.jurisdiction;
    const retailerId = formData.retailerId;
    const userId = formData.userId;

    if (!retailerId) {
      setSnackbarConfig({
        open: true,
        message: 'Retailer ID is required!',
        severity: 'error',
      });
      return;
    }

    setIsLookup(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_FORMS}/uscc/retailerLookup`,
        {
          retailerId,
          jurisdiction,
          userId,
        }
      );
      console.log('Retailer Lookup successful:', response.data);

      // Update the formData state with the response data
      setFormData(prevData => ({
        ...prevData,
        retailerName: response.data.retailerName, // Populate storeName field
        address: response.data.address, // Populate address field
        phoneNumber: response.data.phoneNumber, // Populate phoneNumber field
        zoneId: response.data.zoneId || null, // Populate zoneId field
        latitude: response.data.latitude || null, // Populate latitude field
        longitude: response.data.longitude || null, // Populate longitude field
      }));

      setSnackbarConfig({
        open: true,
        message: 'Retailer Lookup successful!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error searching Retailer:', error);
      setSnackbarConfig({
        open: true,
        message: 'Error searching Retailer. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsLookup(false);
    }
  };

  // Check if 'rrfRepair' input field exists in formConfig.fields
  const hasRRFRepair = formConfig.fields.some(
    field => field.name === 'rrfRepair'
  );

  // const handleAddItem = async e => {
  //   e.preventDefault();
  //   console.log('Add item pressed');
  // };

  const handleAddItem = () => {
    if (duplicatedFields.length < 10) {
      setDuplicatedFields(prevFields => {
        const updatedFields = [
          ...prevFields,
          {
            id: nextId,
            product: '',
            billAcceptor: '',
            burster: '',
            commDevice: '',
            displayMonitors: '',
            pcb: '',
            peripherals: '',
            powerSupply: '',
            printer: '',
            reader: '',
            terminal: '',
            rmaNumber: '',
            assetTag: '',
            serialNumber: '',
            resolution: '',
            failureMode: '',
            solutionOne: '',
            solutionTwo: '',
            partsUsed: '',
            isSecondTimeReturn: '',
            status: '',
          },
        ];

        // Update the formData with the count of additional entries
        setFormData(prevData => ({
          ...prevData,
          additionalEntriesCount: updatedFields.length.toString(), // Store count in formData
        }));

        return updatedFields;
      });

      setNextId(prevId => prevId + 1);
    }
  };

  const handleDuplicatedChange = (index, event) => {
    const { name, value } = event.target;

    setDuplicatedFields(prevFields => {
      const updatedFields = [...prevFields];
      updatedFields[index] = { ...updatedFields[index], [name]: value };
      return updatedFields;
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarConfig(prev => ({ ...prev, open: false }));
  };

  // Filter fields to conditionally render 'returnDate', 'fstNumber', and 'caseNumber'
  const filteredFields = formConfig.fields.filter(field => {
    // If field name is 'returnDate' and 'isSecondTimeReturn' is not 'Yes', return false
    // This will prevent the 'returnDate' field from being rendered in the form component if 'isSecondTimeReturn' is not 'Yes'
    if (field.name === 'returnDate' && isSecondTimeReturn !== 'Yes') {
      return false;
    }
    // If field name is 'fstNumber' and 'isTech' is not 'Yes', return false to prevent rendering the field
    if (field.name === 'fstNumber' && isTech !== 'yes') {
      return false;
    }
    if (field.name === 'caseNumber' && openCase !== 'yes') {
      return false;
    }
    if (field.name === 'jurisdiction' && field.value === 'Indiana') {
      console.log(field.value);
    }
    return true;
  });

  const handleRemoveItem = indexToRemove => {
    setDuplicatedFields(prevFields =>
      prevFields.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <>
      {hasRetailerIdField && ( // Render button only if 'retailerId' exists
        <Grid container justifyContent='flex-end' item xs={12}>
          {isLookup ? (
            <CircularProgress />
          ) : (
            <Button
              variant='contained'
              color='warning'
              onClick={handleRetailerLookup} // Call the lookup function on click
            >
              Retailer ID Lookup
            </Button>
          )}
        </Grid>
      )}
      {hasRRFRepair && ( // Render button only if 'rrfRepair' field exists
        <Grid container justifyContent='flex-end' item xs={12}>
          {isBenchRepair ? (
            <CircularProgress />
          ) : (
            <Button
              variant='contained'
              color='warning'
              onClick={handleAddItem}
              disabled={duplicatedFields.length >= 9} // Disable after 9 clicks
            >
              + Additional Item
            </Button>
          )}
        </Grid>
      )}
      <br />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {filteredFields.map(field => (
            <Grid
              item
              xs={12}
              sm={field.type === 'radio' ? 12 : 6}
              key={field.name}
            >
              {renderField(field, formData[field.name], handleChange)}
            </Grid>
          ))}
          {duplicatedFields.length > 0 && (
            <Grid container spacing={2} justifyContent='flex-start'>
              {duplicatedFields.map((fields, index) => (
                <Grid item xs={12} sm={8} md={6} key={index}>
                  <Box
                    sx={{
                      padding: 2,
                      border: '1px solid #ccc',
                      borderRadius: 2,
                      position: 'relative',
                    }}
                  >
                    {/* Title with Remove Button */}
                    <Grid
                      container
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Typography variant='h6'>Item: {index + 2}</Typography>
                      <Button
                        color='error'
                        size='small'
                        onClick={() => handleRemoveItem(index)}
                      >
                        Remove
                      </Button>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel>Product</InputLabel>
                          <Select
                            name='product'
                            value={fields.product}
                            onChange={e => handleDuplicatedChange(index, e)}
                            required
                          >
                            {fstLotteryProducts &&
                              fstLotteryProducts.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* Bill Acceptor Item Number (Show only if product is "Bill Acceptors") */}
                      {fields.product === 'Bill Acceptors' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Bill Acceptor Item Number</InputLabel>
                            <Select
                              name='billAcceptor'
                              value={fields.billAcceptor}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {billAcceptorNumberItems.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {/* Burster Item Number (Show only if product is "Burster") */}
                      {fields.product === 'MINI/Quad Bursters' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>
                              Mini/Quad Burster Item Number
                            </InputLabel>
                            <Select
                              name='burster'
                              value={fields.burster}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {quadBursterNumberItems.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {/* Comm Device Item Number (Show only if product is "Comm Device") */}
                      {fields.product === 'Comm Device' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Comm Device Item Number</InputLabel>
                            <Select
                              name='commDevice'
                              value={fields.commDevice}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {commDevice.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {/* Display/Monitors Item Number (Show only if product is "Display Monitors") */}
                      {fields.product === 'Display/Monitors' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>
                              Display/Monitors Item Number
                            </InputLabel>
                            <Select
                              name='displayMonitors'
                              value={fields.displayMonitors}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {displayMonitor.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {/* PCB Item Number (Show only if product is "PCB") */}
                      {fields.product === 'Printer Circuit Boards' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>PCB Item Number</InputLabel>
                            <Select
                              name='pcb'
                              value={fields.pcb}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {pcbItemNumbers.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {/* Peripherals Item Number (Show only if product is "Peripherals") */}
                      {fields.product === 'Peripherals' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Peripherals Item Number</InputLabel>
                            <Select
                              name='peripherals'
                              value={fields.peripherals}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {peripherals.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {/* Power Supply Item Number (Show only if product is "Power Supply") */}
                      {fields.product === 'Power Supplies' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Power Supply Item Number</InputLabel>
                            <Select
                              name='powerSupply'
                              value={fields.powerSupply}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {powerSupplyItemNumbers.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {/* Printer Item Number (Show only if product is "Printer") */}
                      {fields.product === 'Printers' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Printer Item Number</InputLabel>
                            <Select
                              name='printer'
                              value={fields.printer}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {printers.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {/* Reader Item Number (Show only if product is "Reader") */}
                      {fields.product === 'Readers' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Reader Item Number</InputLabel>
                            <Select
                              name='reader'
                              value={fields.reader}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {readerItemNumbers.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {/* Terminal Item Number (Show only if product is "Terminal") */}
                      {fields.product === 'Terminal' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Terminal Item Number</InputLabel>
                            <Select
                              name='terminal'
                              value={fields.terminal}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {terminals.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name='rmaNumber'
                          label='RMA Number'
                          value={fields.rmaNumber}
                          onChange={e => handleDuplicatedChange(index, e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name='assetTag'
                          label='Asset Tag'
                          value={fields.assetTag}
                          onChange={e => handleDuplicatedChange(index, e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name='serialNumber'
                          label='Serial Number'
                          value={fields.serialNumber}
                          onChange={e => handleDuplicatedChange(index, e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel>Resolution</InputLabel>
                          <Select
                            name='resolution'
                            value={fields.resolution}
                            onChange={e => handleDuplicatedChange(index, e)}
                          >
                            {yesNoOptions &&
                              yesNoOptions.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* Failure Mode (Show only if resolution is "No") */}
                      {fields.resolution === 'No' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Failure Mode</InputLabel>
                            <Select
                              name='failureMode'
                              value={fields.failureMode}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {lotteryFailureModes &&
                                lotteryFailureModes.map(option => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {/* Failure Mode (Show only if resolution is "No") */}
                      {fields.resolution === 'Yes' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Solution #1</InputLabel>
                            <Select
                              name='solutionOne'
                              value={fields.solutionOne}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {solutions &&
                                solutions.map(option => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {/* Failure Mode (Show only if resolution is "No") */}
                      {fields.resolution === 'Yes' && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Solution #2</InputLabel>
                            <Select
                              name='solutionTwo'
                              value={fields.solutionTwo}
                              onChange={e => handleDuplicatedChange(index, e)}
                            >
                              {solutions &&
                                solutions.map(option => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name='partsUsed'
                          label='Parts Used'
                          value={fields.partsUsed}
                          onChange={e => handleDuplicatedChange(index, e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select
                            name='status'
                            value={fields.status}
                            onChange={e => handleDuplicatedChange(index, e)}
                          >
                            {statusOptions.map(option => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel>Is Second Time Return</InputLabel>
                          <Select
                            name='isSecondTimeReturn'
                            value={fields.isSecondTimeReturn}
                            onChange={e => handleDuplicatedChange(index, e)}
                          >
                            {yesNoOptions &&
                              yesNoOptions.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
          <Grid item xs={12}>
            {isSubmitting ? (
              <CircularProgress />
            ) : (
              <Button type='submit' variant='outlined' color='success'>
                {translations.submit || 'Submit'}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={snackbarConfig.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarConfig.message}
        ContentProps={{
          style: {
            backgroundColor:
              snackbarConfig.severity === 'success' ? '#4CAF50' : '#f44336',
          },
        }}
        action={
          <Button color='inherit' size='small'>
            Close
          </Button>
        }
      />
    </>
  );
};

export default FormComponent;
