export default function AuthModalForm({
  onSubmit,
  children,
  preventDefault = true,
}) {
  return (
    <form
      style={{ width: 500, maxWidth: "100%" }}
      onSubmit={(e) => {
        if (preventDefault) {
          e.preventDefault();
        }
        onSubmit(e);
      }}
    >
      {children}
    </form>
  );
}
