import React from 'react';
import { Box, Typography, Breadcrumbs, Link } from '@mui/material';
import useCurrentPath from '../hooks/currentPath';

const BreadcrumbToolbar = ({ translations }) => {
  const currentPath = useCurrentPath();
  
  // Split the path into individual segments
  const breadcrumbParts = currentPath.split(" / ");

  return (
    <Box
      sx={{
        backgroundColor: '#4a7ec3',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        paddingLeft: '16px',
      }}
    >
      <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#FFFFFF' }}>
        {/* Render each breadcrumb part */}
        {breadcrumbParts.map((part, index) => (
          index < breadcrumbParts.length - 1 ? (
            <Link
              key={index}
              underline="hover"
              color="inherit"
              href={`/${breadcrumbParts.slice(0, index + 1).join('/')}`} // Create href for each part
            >
              {part}
            </Link>
          ) : (
            <Typography
              key={index}
              sx={{ fontWeight: 'bold', color: '#FFFFFF' }}
            >
              {part}
            </Typography>
          )
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbToolbar;
