import React, { useState, useContext } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { utilities as utilitiesFunction } from '../../utils/contactCenterCommDevices';
import { LanguageContext } from '../../context/languageContext';

const CommTabs = ({translations}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { language, changeLanguage } = useContext(LanguageContext);
  const utilities = utilitiesFunction(translations);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      {Array.isArray(utilities) && (
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Contact Center Tabs"
          indicatorColor="primary"
          textColor="primary"
          sx={{ marginBottom: 1 }}
        >
          {utilities.map((utility, index) => (
            <Tab key={index} label={utility.tab} />
          ))}
        </Tabs>
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {utilities[selectedTab]?.items.map((utility, index) => (
          <Box key={index} sx={{ flexGrow: 1 }}>
            {utility.component}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default CommTabs;
