import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  SearchIcon,
  NoAccountsIcon,
  CheckIcon,
  EmailIcon,
} from "../../assets/icons/icons";
import NavigationBar from "../../components/navigationBar";
import {
  getUserInvitesColumns,
  userInvitesEndpoint,
  enableInviteEndpoint,
  disableInviteEndpoint,
  resendInviteEndpoint,
} from "../../utils/adminPanelDataConfigs";
import InviteUserModal from "../../components/inviteUserModal";

const CustomToolbar = ({
  selectedInvites,
  handleDisableInvites,
  handleEnableInvites,
  handleResendInvite,
  setIsDialogOpen,
}) => {
  const allUnclaimed = selectedInvites.every(
    (inv) => inv.status === "unclaimed"
  );
  // const allClaimed = selectedInvites.every((inv) => inv.status === "claimed");
  const allDisabled = selectedInvites.every((inv) => inv.status === "disabled");
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        startIcon={<NoAccountsIcon />}
        onClick={handleDisableInvites}
        variant='outlined'
        sx={{
          mr: "10px",
          color: allUnclaimed ? "#ffa600" : "gray",
          borderColor: allUnclaimed ? "#ffa600" : "gray",
        }}
        disabled={selectedInvites.length === 0 || !allUnclaimed}
      >
        Disable Invite
      </Button>
      <Button
        startIcon={<CheckIcon />}
        onClick={handleEnableInvites}
        variant='outlined'
        sx={{
          mr: "10px",
          color: allDisabled ? "#4caf50" : "gray",
          borderColor: allDisabled ? "#4caf50" : "gray",
        }}
        disabled={selectedInvites.length === 0 || !allDisabled}
      >
        Enable Invite
      </Button>
      <Button
        startIcon={<EmailIcon />}
        onClick={handleResendInvite}
        variant='outlined'
        sx={{
          mr: "10px",
          color: allUnclaimed ? "gray" : "#4caf50",
          borderColor: allUnclaimed ? "gray" : "#4caf50",
        }}
        disabled={selectedInvites.length === 0 || !allUnclaimed}
      >
        Resend Email
      </Button>
      <Button
        startIcon={<EmailIcon />}
        onClick={() => setIsDialogOpen(true)}
        variant='outlined'
        sx={{
          mr: "10px",
          color: selectedInvites.length !== 0 ? "gray" : "#4caf50",
          borderColor: selectedInvites.length !== 0 ? "gray" : "#4caf50",
        }}
        disabled={selectedInvites.length !== 0}
      >
        Invite User
      </Button>
    </Box>
  );
};

export default function UserInvitesManagement() {
  const [invites, setInvites] = useState([]);
  const [selectedInvites, setSelectedInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [activeFilter, setActiveFilter] = useState("all");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  // Function to get status tag based on active and usedDate fields
  function getStatusTag(inv) {
    if (inv.active === 1) {
      if (inv.usedDate == null) return "unclaimed";
    } else {
      if (inv.usedDate == null) return "disabled";
      return "claimed";
    }
  }

  // Function to fetch users from the API
  async function fetchInvites() {
    try {
      const response = await axios.get(userInvitesEndpoint);
      const fetchedInvites = response.data;
      return fetchedInvites.map((inv) => ({
        ...inv,
        status: getStatusTag(inv), // Convert active flag to status
      }));
    } catch (error) {
      console.error("Error fetching invites:", error);
      return [];
    }
  }

  // Function to handle active filter change
  const handleActiveFilterChange = (event) => {
    setActiveFilter(event.target.value);
  };
  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };
  // Function to disable selected users
  const handleDisableInvites = async () => {
    try {
      const userIds = selectedInvites.map((inv) => inv.userId); // Extract userIds
      for (const userId of userIds) {
        console.log("Disabling Invite with User ID:", userId); // Log each user ID before sending the request
        await axios.post(disableInviteEndpoint, {
          userId,
        });
      }
      const updatedInvites = invites.map((inv) =>
        userIds.includes(inv.userId) ? { ...inv, status: "disabled" } : inv
      );
      setInvites(updatedInvites);
      setSnackbar({
        open: true,
        message: "Invites disabled successfully.",
        severity: "success",
      });
    } catch (error) {
      console.error("Error disabling invites:", error);
      setSnackbar({
        open: true,
        message: `Error disabling users: ${error.response.data.error}`,
        severity: "error",
      });
    }
  };

  // Function to enable selected invites
  const handleEnableInvites = async () => {
    try {
      const userIds = selectedInvites.map((inv) => inv.userId); // Extract userIds
      for (const userId of userIds) {
        console.log("Enabling Invite with User ID:", userId); // Log each user ID before sending the request
        await axios.post(enableInviteEndpoint, {
          userId,
        });
      }
      const updatedInvites = invites.map((inv) =>
        userIds.includes(inv.userId) ? { ...inv, status: "unclaimed" } : inv
      );
      setInvites(updatedInvites);
      setSnackbar({
        open: true,
        message: "Invites enabled successfully.",
        severity: "success",
      });
    } catch (error) {
      console.error("Error enabling invites:", error);
      setSnackbar({
        open: true,
        message: `Error disabling users: ${error.response.data.error}`,
        severity: "error",
      });
    }
  };

  // TODO: Resend invite for all selected
  const handleResendInvite = async () => {
    try {
      if (selectedInvites.length > 0) {
        const invite = selectedInvites[0];
        await axios.post(resendInviteEndpoint, {
          email: invite.email,
          firstName: invite.firstName,
          lastName: invite.lastName,
          inviteCode: invite.inviteCode,
        });
        setSnackbar({
          open: true,
          message: "Invite resent successfully.",
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Error resending invite:", error);
      setSnackbar({
        open: true,
        message: `Error resending invite: ${error.response.data.error}`,
        severity: "error",
      });
    }
  };

  const handleUserInvited = async () => {
    setSnackbar({
      open: true,
      message: "Local User Invited!",
      severity: "success",
    });
    const newInvites = await fetchInvites();
    setInvites(newInvites);
    setSelectedInvites(invites.filter((inv) => inv.id === selectedInvites.id));
  };

  const filteredInvites = useMemo(
    () =>
      invites.filter((inv) => {
        const fullName =
          inv.firstName && inv.lastName
            ? inv.firstName.toLowerCase() + " " + inv.lastName.toLowerCase()
            : "";
        const isFilterMatch =
          activeFilter === "all" ||
          (activeFilter === "local" && !inv.email.includes("@igt.com")) ||
          (activeFilter === "igt" && inv.email.includes("@igt.com"));
        return (
          isFilterMatch &&
          (fullName.includes(searchTerm) ||
            (inv.email && inv.email.toLowerCase().includes(searchTerm)) ||
            (inv.inviteCode &&
              inv.inviteCode.toLowerCase().includes(searchTerm)))
        );
      }),
    [invites, activeFilter, searchTerm]
  );

  // Fetch invites on component mount
  useEffect(() => {
    const loadInvites = async () => {
      const fetchedinvites = await fetchInvites();
      setInvites(fetchedinvites);

      setLoading(false);
    };

    loadInvites();
  }, []);

  useEffect(() => {
    console.log("Selected Invites:", selectedInvites);
  }, [selectedInvites]);

  return (
    <>
      <NavigationBar />
      <Box sx={{ display: "flex", p: 2, alignItems: "flex-start" }}>
        <Box sx={{ flexGrow: 1, ml: 2 }}>
          <Box mb={2}>
            <Box
              mb={2}
              sx={{ display: "flex", alignItems: "center", mt: "10px" }}
            >
              <TextField
                select
                size='small'
                value={activeFilter}
                onChange={handleActiveFilterChange}
                sx={{ width: "50%", mr: 2 }}
              >
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='local'>Local Users</MenuItem>
                <MenuItem value='igt'>IGT Users</MenuItem>
              </TextField>
              <TextField
                size='small'
                label='Search'
                variant='outlined'
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  width: "50%",
                }}
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <div style={{ height: 900, width: "100%" }}>
              <DataGrid
                rows={filteredInvites}
                columns={getUserInvitesColumns()} // Pass navigate
                pageSize={rowsPerPage}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                pagination
                checkboxSelection
                onRowSelectionModelChange={(rowIds) => {
                  setSelectedInvites(
                    invites.filter((inv) => rowIds.includes(inv.id))
                  );
                }}
                selectionModel={selectedInvites.map((inv) => inv.id)}
                rowsPerPageOptions={[25, 50, 75, 100]}
                components={{
                  Toolbar: () => (
                    <CustomToolbar
                      selectedInvites={selectedInvites}
                      handleDisableInvites={handleDisableInvites}
                      handleEnableInvites={handleEnableInvites}
                      handleResendInvite={handleResendInvite}
                      setIsDialogOpen={setIsDialogOpen}
                    />
                  ),
                }}
              />
            </div>
          )}
        </Box>
      </Box>
      <InviteUserModal
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onUserInvited={handleUserInvited}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
