// Forms Menu Data
import { count } from 'd3';
import { LanguageContext } from '../context/languageContext';
import { californiaPTLReport } from './reportConfigs';

export const formMenuData = translations => [
  {
    country: translations.unitedStates || 'United States',
    teams: [
      {
        name: 'Depot',
        forms: [
          {
            name: 'Bench Repair',
            componentKey: 'BenchRepairForm',
          },
        ],
      },
      {
        name: 'FST',
        forms: [
          {
            name: translations.incorrectCase || 'Incorrect Case',
            componentKey: 'IncorrectCaseForm',
          },
          {
            name: translations.overtime || 'Overtime',
            componentKey: 'FSTOvertimeForm',
          },
        ],
      },
      {
        name: 'NRC',
        forms: [
          {
            name: 'California PTL',
            componentKey: 'CaliforniaPTLForm',
          },
          {
            name: 'Consumable Supply',
            componentKey: 'ConsumableSupplyForm',
          },
          {
            name: 'Dispatch Status Change Request',
            componentKey: 'DispatchStatusChangeRequestForm',
          },
          {
            name: 'Forward-To-Site',
            componentKey: 'ForwardToSiteForm',
          },
          {
            name: 'GNIE Update Request',
            componentKey: 'GNIEUpdateRequestForm',
          },
          {
            name: 'Leeward Islands Credit Log',
            componentKey: 'LeewardIslandCreditLogForm',
          },
          {
            name: 'Michigan Incident Log',
            componentKey: 'MichiganIncidentLogForm',
          },
          {
            name: 'New York AM Log',
            componentKey: 'NYAMLogForm',
          },
          {
            name: 'New York Key Request',
            componentKey: 'NYKeyRequestForm',
          },
          {
            name: 'NRC Downtime',
            componentKey: 'NRCDowntimeForm',
          },
          {
            name: 'Retailer Information Change',
            componentKey: 'RetailerInfoChangeForm',
          },
          // {
          //   name: 'Rhode Island Credit Log',
          //   componentKey: 'RICreditLogForm',
          // },
          {
            name: 'Stolen & Missing Instant Ticket Report',
            componentKey: 'StolenAndMissingInstantTicketForm',
          },
          {
            name: 'Washington Retailer Training Request',
            componentKey: 'WARetailerTrainingRequestForm',
          },
        ],
      },
      {
        name: 'RRF',
        forms: [
          {
            name: translations.lotteryRepairWorklog || 'Lottery Repair Worklog',
            componentKey: 'LotteryRepairWorklogForm',
          },
          {
            name: translations.gamingRepairWorklog || 'Gaming Repair Worklog',
            componentKey: 'GamingRepairWorklogForm',
          },
        ],
      },
    ],
  },
];

// Reporting Menu Data
export const reportMenuData = translations => [
  {
    country: translations.unitedStates || 'United States',
    teams: [
      {
        name: 'Depot',
        reports: [
          {
            name: 'Bench Repair',
            componentKey: 'BenchRepairReport',
          },
        ],
      },
      {
        name: 'FST',
        reports: [
          {
            name: translations.incorrectCase || 'Incorrect Case',
            componentKey: 'IncorrectCaseReport',
          },
          {
            name: translations.overtime || 'Overtime',
            componentKey: 'FSTOvertimeReport',
          },
        ],
      },
      {
        name: 'NRC',
        reports: [
          {
            name: 'California PTL',
            componentKey: 'CaliforniaPTLReport',
          },
          {
            name: 'Consumable Supply',
            componentKey: 'ConsumableSupplyReport',
          },
          {
            name: 'Dispatch Status Change Request',
            componentKey: 'DispatchStatusChangeRequestReport',
          },
          {
            name: 'Forward-To-Site',
            componentKey: 'ForwardToSiteReport',
          },
          {
            name: 'GNIE Update Request',
            componentKey: 'GNIEUpdateRequestReport',
          },
          {
            name: 'Leeward Islands Credit Log',
            componentKey: 'LeewardIslandCreditLogReport',
          },
          {
            name: 'Michigan Incident Log',
            componentKey: 'MichiganIncidentLogReport',
          },
          {
            name: 'New York AM Log',
            componentKey: 'NYAMLogReport',
          },
          {
            name: 'New York Key Request',
            componentKey: 'NYKeyRequestReport',
          },
          {
            name: 'NRC Downtime',
            componentKey: 'NRCDowntimeReport',
          },
          {
            name: 'Retailer Information Change',
            componentKey: 'RetailerInfoChangeReport',
          },
          // {
          //   name: 'Rhode Island Credit Log',
          //   componentKey: 'RICreditLogReport',
          // },
          {
            name: 'Stolen & Missing Instant Ticket Report',
            componentKey: 'StolenAndMissingInstantTicketReport',
          },
          {
            name: 'Washington Retailer Training Request',
            componentKey: 'WARetailerTrainingRequestReport',
          },
        ],
      },
      {
        name: 'RRF',
        reports: [
          {
            name: translations.lotteryRepairWorklog || 'Lottery Repair Worklog',
            componentKey: 'LotteryRepairWorklogReport',
          },
          {
            name: translations.gamingRepairWorklog || 'Gaming Repair Worklog',
            componentKey: 'GamingRepairWorklogReport',
          },
        ],
      },
    ],
  },
  {
    country: 'Chile',
    teams: [
      {
        name: translations.contactCenter,
        reports: [
          {
            name:
              translations.genesysCloudInteractionSurvey ||
              'Genesys Cloud Interaction Survey',
            componentKey: 'CLGenesysCloudSurvey',
          },
        ],
      },
    ],
  },
  {
    country: translations.czechRepublic || 'Czech Republic',
    teams: [
      {
        name: translations.contactCenter,
        reports: [
          {
            name:
              translations.genesysCloudInteractionSurvey ||
              'Genesys Cloud Interaction Survey',
            componentKey: 'CZGenesysCloudSurvey',
          },
        ],
      },
    ],
  },
];

// Files Menu Data
export const filesMenuData = translations => [
  {
    country: translations.unitedStates || 'United States',
    teams: [
      {
        name: translations.contactCenter || 'NRC Files',
        files: [
          {
            name: translations.retailerFiles || 'Retailer Files',
            componentKey: 'RetailerFilesDataGrid',
          },
        ],
      },
      {
        name: 'GCS',
        files: [
          {
            name: translations.gcsFiles || 'GCS Files',
            componentKey: 'GCSFilesDataGrid',
          },
        ],
      },
    ],
  },
];
