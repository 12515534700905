import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Box,
  Divider,
  Button,
  CircularProgress,
} from '@mui/material';
import {
  Close as CloseIcon,
  EmojiEvents as CelebrationIcon,
  Email as EmailIcon,
  NewReleases as FeatureIcon,
  BugReport as BugFixIcon,
  Build as ImprovementIcon,
} from '@mui/icons-material';
import Cookies from 'js-cookie';

const ReleaseNotes = () => {
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const lastReadUpdate = localStorage.getItem('lastReadUpdate'); // Get the last read update timestamp from local storage

    axios
      .get(`${process.env.REACT_APP_ENDPOINT_SYSTEM}/release-notes`, {
        params: { lastReadUpdate },
      })
      .then((response) => {
        const notes = response.data;
        if (notes.length > 0) {
          const latestUpdate = new Date(
            Math.max(...notes.map((note) => new Date(note.date)))
          ); // Find the latest update date
          localStorage.setItem('lastReadUpdate', latestUpdate.toISOString()); // Update the last read update timestamp in local storage
          setIsDrawerOpen(true); // Open the drawer if there are new updates
        }
        setReleaseNotes(notes);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching release notes!', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const markAllAsRead = async () => {
    try {
      const releaseNoteIds = releaseNotes.map((note) => note.id);
      const token = Cookies.get('token'); // Get the token from cookies

      await Promise.all(
        releaseNoteIds.map((noteId) =>
          axios.post(
            `${process.env.REACT_APP_ENDPOINT_SYSTEM}/release-notes/read/${noteId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
        )
      );
      localStorage.setItem('lastReadUpdate', new Date().toISOString());
      setReleaseNotes([]);
      setIsDrawerOpen(false);
    } catch (error) {
      console.error('Error marking notes as read:', error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const flattenDescription = (description) => {
    if (Array.isArray(description[0])) {
      return description.flat();
    }
    return description;
  };

  const groupedReleaseNotes = releaseNotes.reduce((acc, note) => {
    const releaseDate = formatDate(note.date);
    if (!acc[releaseDate]) acc[releaseDate] = [];
    acc[releaseDate].push(note);
    return acc;
  }, {});

  const getCategoryDetails = (category) => {
    switch (category) {
      case 'Feature':
        return {
          icon: <FeatureIcon sx={{ color: 'green', marginRight: 1 }} />,
          color: 'green',
        };
      case 'Bug Fix':
        return {
          icon: <BugFixIcon sx={{ color: 'red', marginRight: 1 }} />,
          color: 'red',
        };
      case 'Improvement':
        return {
          icon: <ImprovementIcon sx={{ color: '#E1C900', marginRight: 1 }} />,
          color: '#E1C900',
        };
      default:
        return { icon: null, color: 'black' };
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color='error'>Error fetching release notes!</Typography>;
  }

  return (
    <>
      <Drawer anchor='right' open={isDrawerOpen} onClose={handleDrawerClose}>
        <Box
          sx={{
            width: 350,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='h6'>
              What's New
              <CelebrationIcon sx={{ marginLeft: 1, color: '#ffa600' }} />
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon sx={{ color: 'red' }} />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ flex: 1, overflowY: 'auto', paddingRight: 2 }}>
            <Typography variant='h6' sx={{ color: '#050874', marginBottom: 1 }}>
              Release Notes
            </Typography>
            <List>
              {Object.keys(groupedReleaseNotes).map((date) => (
                <Box key={date} sx={{ marginBottom: 2 }}>
                  <Typography
                    variant='subtitle2'
                    sx={{
                      color: '#0C51A1',
                      marginBottom: 1,
                      fontWeight: 'bold',
                    }}
                  >
                    {date}
                  </Typography>
                  {groupedReleaseNotes[date].map((note) => (
                    <ListItem key={note.id} alignItems='flex-start'>
                      <ListItemText
                        primary={
                          <Typography
                            variant='subtitle1'
                            component='span'
                            sx={{ color: '#0C51A1' }}
                          >
                            {note.title} (v{note.version})
                          </Typography>
                        }
                        secondary={
                          <>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: getCategoryDetails(note.category).color,
                              }}
                            >
                              {getCategoryDetails(note.category).icon}
                              <Typography variant='body2' component='span'>
                                {note.category}
                              </Typography>
                            </Box>
                            <ul style={{ paddingLeft: '20px', margin: 0 }}>
                              {flattenDescription(note.description).map(
                                (desc, index) => (
                                  <li key={index}>
                                    <Typography
                                      variant='body2'
                                      component='span'
                                    >
                                      {desc}
                                    </Typography>
                                  </li>
                                )
                              )}
                            </ul>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </Box>
              ))}
            </List>
          </Box>
          <Divider />
          <Box sx={{ padding: 2 }}>
            <Button
              variant='contained'
              fullWidth
              onClick={markAllAsRead}
              sx={{ backgroundColor: '#0C51A1', color: 'white' }}
            >
              Mark as Read
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default ReleaseNotes;
