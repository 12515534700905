import { useState, useEffect } from 'react';
import { fetchProfilePhoto } from '../services/azureServices';

const useFetchProfilePhoto = () => {
  const [ssoProfilePhotoUrl, setSsoProfilePhotoUrl] = useState(null);
  const [standardProfilePhotoUrl, setStandardProfilePhotoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPhoto = async () => {
      const result = await fetchProfilePhoto();
      if (result.error) {
        setError(result.error);
        if (result.type === 'sso') setSsoProfilePhotoUrl(null);
        if (result.type === 'standard') setStandardProfilePhotoUrl(null);
      } else {
        if (result.type === 'sso') setSsoProfilePhotoUrl(result.photoUrl);
        if (result.type === 'standard') setStandardProfilePhotoUrl(result.photoUrl);
      }
      setLoading(false);
    };

    fetchPhoto();
  }, []);

  return { ssoProfilePhotoUrl, standardProfilePhotoUrl, loading, error };
};

export default useFetchProfilePhoto;
