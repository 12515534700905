import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  SearchIcon,
  NoAccountsIcon,
  CheckIcon,
} from "../../assets/icons/icons";
import NavigationBar from "../../components/navigationBar";
import AddNewUser from "../../components/addUserModal";
import {
  getUserColumns,
  usersApiEndpoint,
  userStateApiEndpoint,
} from "../../utils/adminPanelDataConfigs";
import BreadcrumbToolbar from "../../components/breadcrumbToolbar";

const CustomToolbar = ({
  selectedUsers,
  handleDisableUsers,
  handleEnableUsers,
  setIsDialogOpen,
}) => {
  const allActive = selectedUsers.every((user) => user.status === "active");
  const allInactive = selectedUsers.every((user) => user.status === "inactive");

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        startIcon={<NoAccountsIcon />}
        onClick={handleDisableUsers}
        variant='outlined'
        sx={{
          mr: "10px",
          color: allInactive ? "gray" : "#ffa600",
          borderColor: allInactive ? "gray" : "#ffa600",
        }}
        disabled={selectedUsers.length === 0 || allInactive}
      >
        Disable User
      </Button>
      <Button
        startIcon={<CheckIcon />}
        onClick={handleEnableUsers}
        variant='outlined'
        sx={{
          mr: "10px",
          color: allActive ? "gray" : "#4caf50",
          borderColor: allActive ? "gray" : "#4caf50",
        }}
        disabled={selectedUsers.length === 0 || allActive}
      >
        Enable User
      </Button>
    </Box>
  );
};

export default function UserManagement() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [activeFilter, setActiveFilter] = useState("all");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userLoginStatus, setUserLoginStatus] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const userLoadingCache = {}; // Cache object to store user loading status

  // Function to fetch users from the API
  async function fetchUsers() {
    try {
      const response = await axios.get(usersApiEndpoint);
      const fetchedUsers = response.data;
      return fetchedUsers.map((user) => ({
        ...user,
        status: user.active === 1 ? "active" : "inactive", // Convert active flag to status
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
      return [];
    }
  }

  // Fetch users and their login status on component mount
  useEffect(() => {
    const loadUsers = async () => {
      const fetchedUsers = await fetchUsers();
      setUsers(fetchedUsers);

      const statusPromises = fetchedUsers.map(async (user) => {
        userLoadingCache[user.userId] = true;
        try {
          const response = await axios.get(userStateApiEndpoint, {
            params: { userId: user.userId },
          });
          const { status } = response.data;

          setUserLoginStatus((prevStatus) => ({
            ...prevStatus,
            [user.userId]: status,
          }));
        } catch (error) {
          console.error("Error fetching user state:", error);
        } finally {
          userLoadingCache[user.userId] = false;
        }
      });

      await Promise.all(statusPromises);

      setLoading(false);
    };

    loadUsers();
  }, []);

  // Function to handle active filter change
  const handleActiveFilterChange = (event) => {
    setActiveFilter(event.target.value);
  };
  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };
  // Function to disable selected users
  const handleDisableUsers = async () => {
    console.log("Selected Users for Disabling:", selectedUsers); // Log the selected users here
    try {
      const userIds = selectedUsers.map((user) => user.userId); // Extract userIds
      for (const userId of userIds) {
        console.log("Disabling User ID:", userId); // Log each user ID before sending the request
        await axios.post("http://localhost:3004/v1/engage/users/disable-user", {
          userId,
        });
      }
      const updatedUsers = users.map((user) =>
        userIds.includes(user.userId) ? { ...user, status: "inactive" } : user
      );
      setUsers(updatedUsers);
      setSelectedUsers([]);
      setSelectAll(false);
      setSnackbar({
        open: true,
        message: "Users disabled successfully.",
        severity: "success",
      });
    } catch (error) {
      console.error("Error disabling users:", error);
      setSnackbar({
        open: true,
        message: "Error disabling users.",
        severity: "error",
      });
    }
  };

  // Function to enable selected users
  const handleEnableUsers = async () => {
    console.log("Selected Users for Enabling:", selectedUsers); // Log the selected users here
    try {
      const userIds = selectedUsers.map((user) => user.userId); // Extract userIds
      for (const userId of userIds) {
        console.log("Enabling User ID:", userId); // Log each user ID before sending the request
        await axios.post(
          `${process.env.REACT_APP_ENDPOINT_USERS}/enable-user`,
          {
            userId,
          }
        );
      }
      const updatedUsers = users.map((user) =>
        userIds.includes(user.userId) ? { ...user, status: "active" } : user
      );
      setUsers(updatedUsers);
      setSelectedUsers([]);
      setSelectAll(false);
      setSnackbar({
        open: true,
        message: "Users enabled successfully.",
        severity: "success",
      });
    } catch (error) {
      console.error("Error enabling users:", error);
      setSnackbar({
        open: true,
        message: "Error enabling users.",
        severity: "error",
      });
    }
  };

  // TODO - Function to force logout selected users

  // const handleForceLogoutUsers = async () => {
  // 	console.log('Selected Users for Force Logout:', selectedUsers); // Log the selected users here
  // 	try {
  // 		const userIds = selectedUsers.map((user) => user.userId); // Extract userIds
  // 		for (const userId of userIds) {
  // 			console.log('Force Logging Out User ID:', userId); // Log each user ID before sending the request
  // 			await axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/logout`, {
  // 				userId,
  // 			});

  // 			// Update the userLoginStatus state for the logged-out users
  // 			setUserLoginStatus((prevStatus) => ({
  // 				...prevStatus,
  // 				[userId]: 'Logged Out',
  // 			}));
  // 		}
  // 		setSelectedUsers([]);
  // 		setSelectAll(false);
  // 		setSnackbar({
  // 			open: true,
  // 			message: 'Users force logged out successfully.',
  // 			severity: 'success',
  // 		});
  // 	} catch (error) {
  // 		console.error('Error force logging out users:', error);
  // 		setSnackbar({
  // 			open: true,
  // 			message: 'Error force logging out users.',
  // 			severity: 'error',
  // 		});
  // 	}
  // };

  const filteredUsers = users.filter((user) => {
    const fullName =
      user.firstName && user.lastName
        ? user.firstName.toLowerCase() + " " + user.lastName.toLowerCase()
        : "";
    const isActiveMatch =
      activeFilter === "all" ||
      (activeFilter === "active" && user.status === "active") ||
      (activeFilter === "inactive" && user.status !== "active");
    return (
      isActiveMatch &&
      (fullName.includes(searchTerm) ||
        (user.email && user.email.toLowerCase().includes(searchTerm)) ||
        (user.role && user.role.toLowerCase().includes(searchTerm)) ||
        (user.userType && user.userType.toLowerCase().includes(searchTerm)))
    );
  });

  useEffect(() => {
    console.log("Selected Users:", selectedUsers);
  }, [selectedUsers]);

  return (
    <>
      <NavigationBar />
      <BreadcrumbToolbar  />
      <Box sx={{ display: "flex", p: 2, alignItems: "flex-start" }}>
        <Box sx={{ flexGrow: 1, ml: 2 }}>
          <Box mb={2}>
            <Box
              mb={2}
              sx={{ display: "flex", alignItems: "center", mt: "10px" }}
            >
              <TextField
                select
                size='small'
                value={activeFilter}
                onChange={handleActiveFilterChange}
                sx={{ width: "50%", mr: 2 }}
              >
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='active'>Active</MenuItem>
                <MenuItem value='inactive'>Inactive</MenuItem>
              </TextField>
              <TextField
                size='small'
                label='Search'
                variant='outlined'
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  width: "50%",
                }}
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <div style={{ height: 900, width: "100%" }}>
              <DataGrid
                rows={filteredUsers}
                columns={getUserColumns(
                  userLoginStatus,
                  userLoadingCache,
                  navigate
                )} // Pass navigate
                pageSize={rowsPerPage}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                pagination
                checkboxSelection
                onCellClick={(params, event) => {
                  if (params.field === "__check__") {
                    const selectedUser = params.row;
                    const isSelected = selectedUsers.some(
                      (user) => user.id === selectedUser.id
                    );

                    if (isSelected) {
                      setSelectedUsers(
                        selectedUsers.filter(
                          (user) => user.id !== selectedUser.id
                        )
                      );
                    } else {
                      setSelectedUsers([...selectedUsers, selectedUser]);
                    }

                    // Update the "Select All" checkbox state
                    if (selectedUsers.length === filteredUsers.length) {
                      setSelectAll(true);
                    } else {
                      setSelectAll(false);
                    }
                  }
                }}
                selectionModel={selectedUsers.map((user) => user.id)}
                rowsPerPageOptions={[25, 50, 75, 100]}
                components={{
                  Toolbar: () => (
                    <CustomToolbar
                      selectedUsers={selectedUsers}
                      handleDisableUsers={handleDisableUsers}
                      handleEnableUsers={handleEnableUsers}
                      setIsDialogOpen={setIsDialogOpen}
                    />
                  ),
                }}
              />
            </div>
          )}
        </Box>
      </Box>
      <AddNewUser
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
