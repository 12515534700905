import { useLocation } from "react-router-dom";

const useCurrentPath = () => {
  const location = useLocation();

  const formatPath = () => {
    // Remove the leading slash and format as needed
    let formattedPath = location.pathname.slice(1);

    // Replace additional slashes with a space or other separator if needed
    formattedPath = formattedPath.replace(/\//g, " / ");

    return formattedPath;
  };

  return formatPath();
};

export default useCurrentPath;
